import React from "react";
import { Route, Redirect } from "react-router-dom";

import usePrepareLink from "../../../hooks/router/usePrepareLink";

const SubscriptionRoute = ({ children, isSubscribed, ...rest }) => {
  // alert("Is Subscribed here: "+isSubscribed);
  const subscribeLink = usePrepareLink({
    to: "/membership",
    isRelativePath: true
  });

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isSubscribed ? (
          children
        ) : (
          <Redirect
            to={{
              ...subscribeLink,
              state: { from: location }
            }}
          />
        )
      }
    />
  );
};

export default SubscriptionRoute;