import React from 'react';

import Notification from "./Notification";

// import usePrepareLink from "../../hooks/router/usePrepareLink";
// import { GET_PARAMS, GET_ENUMS } from "../const/router";

import "./index.css";



function Notifications(props) {
    document.title = 'Montywood | Notifications';

    var pullNotifications = {};
    return (
        <div className="notifications" >
            <h1>Notifications</h1>
            <br></br>
            <br></br>
            <br></br>
            <div className="upperCard">
                {
                    pullNotifications.length ?
                    pullNotifications.map(elem => {
                        return <Notification key={elem.id}
                        type={elem.type} profilePic={elem.profilePic} names={elem.names}
                        seen={elem.seen} viewed={elem.viewed} description={elem.description}
                        />;
                    }) : <p className="noContentP">No notifications yet.</p>
                }
            </div>
            
            <br></br>
            <br></br>
            <br></br>
        </div>
    );
  }
  
  export default Notifications;