import React, {useEffect, useState, useCallback} from 'react';
import { Route, Redirect, Switch, NavLink, useHistory, useLocation } from 'react-router-dom';

import axios from 'axios';
import Swal from "sweetalert2";  
import $ from 'jquery';


import usePrepareLink from "../../hooks/router/usePrepareLink";
import { GET_PARAMS, GET_ENUMS } from "../../components/const/router";

import './index.css';

import Main from './Main';
import Users from './Users';
import Content from './Content';
import Vouchers from './Vouchers';
import Subscriptions from './Subscriptions';
import Communications from './Communications';

import whiteIconFull from '../../icons/logo.png';
import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded';
import PeopleAltRoundedIcon from '@material-ui/icons/PeopleAltRounded';
import MovieFilterRoundedIcon from '@material-ui/icons/MovieFilterRounded';
import PaymentRoundedIcon from '@material-ui/icons/PaymentRounded';
import ReceiptRoundedIcon from '@material-ui/icons/ReceiptRounded';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';


function Dashboard(props) {
    var userProfilePic = localStorage.getItem("profilePic");
    var userRoleName = localStorage.getItem("roleName");
    var userRole = localStorage.getItem("role");
    var userNames = localStorage.getItem("firstname")+" "+localStorage.getItem("lastname");

    var rolePages = {
        superAdmin: ['users', 'content', 'vouchers', 'subscriptions', 'communications'],
        mainAdmin: ['users', 'content', 'vouchers', 'subscriptions', 'communications'],
        contentAdmin: ['content'],
        marketingAdmin: ['users', 'vouchers', 'communications'],
    };
    const history = useHistory();
    const location = useLocation();
    const [defaultAction, setDefaultAction] = useState(false); 
    const [fecthedRole, setFecthedRole] = useState(userRole); 


    const genVoucherLink = usePrepareLink({ query: { [GET_PARAMS.popup]: GET_ENUMS.popup.generateVoucher}});
    const veriVoucherLink = usePrepareLink({query: { [GET_PARAMS.popup]: GET_ENUMS.popup.verifyVoucher }});
    const currentVoucherLink = usePrepareLink({ query: {[GET_PARAMS.popup]: GET_ENUMS.popup.currentVoucher }});
    const showPrintVouchersLink = usePrepareLink({query: {[GET_PARAMS.popup]: GET_ENUMS.popup.printVouchers}});
    const createMovieLink = usePrepareLink({ query: { [GET_PARAMS.popup]: GET_ENUMS.popup.createMovie}});
    const addCategoryLink = usePrepareLink({ query: { [GET_PARAMS.popup]: GET_ENUMS.popup.addCategory}});
    const updateVideoLink = usePrepareLink({ query: { [GET_PARAMS.popup]: GET_ENUMS.popup.updateVideo}});
    const usersLink = usePrepareLink({ query: { [GET_PARAMS.popup]: GET_ENUMS.popup.showUsers}});
    const sendMessageLink = usePrepareLink({ query: { [GET_PARAMS.popup]: GET_ENUMS.popup.sendMessage}});

    const showGenVoucher = useCallback(() => { history.push(genVoucherLink);}, [history, genVoucherLink]);
    const showVeriVoucher = useCallback(() => {history.push(veriVoucherLink); }, [history, veriVoucherLink]);
    const showCurrentVouchers = useCallback(() => { history.push(currentVoucherLink);}, [history, currentVoucherLink]);
    const showPrintVouchers = useCallback(() => { history.push(showPrintVouchersLink); }, [history, showPrintVouchersLink]);
    const showCreateMovieLink = useCallback(() => { history.push(createMovieLink);}, [history, createMovieLink]);
    const showAddCategoryLink = useCallback(() => { history.push(addCategoryLink);}, [history, addCategoryLink]);
    const showUpdateVideoLink = useCallback(() => { history.push(updateVideoLink);}, [history, updateVideoLink]);
    const showUsersLink = useCallback(() => { history.push(usersLink);}, [history, usersLink]);
    const showSendMessageLink = useCallback(() => { history.push(sendMessageLink);}, [history, sendMessageLink]);

    const generateVoucher = ()=>{ showGenVoucher(); }
    const verifyVoucher = ()=>{ showVeriVoucher();}
    const getCurrentVouchers = ()=>{ showCurrentVouchers(); }
    const printCurrentVouchers = ()=>{ showPrintVouchers(); };
    const createMovie = ()=>{ showCreateMovieLink(); }
    const addCategory = ()=>{ showAddCategoryLink(); }
    const sendMessage = ()=>{ showSendMessageLink(); }
    const updateVideo = (currentVidData)=>{ 
        props.setParamPopupData(currentVidData);
        showUpdateVideoLink();
    }
    const showUsers = (userByDates)=>{ 
        userByDates.userToken = props.userToken;
        props.setParamPopupData(userByDates);
        showUsersLink();
    }


    const onlineVerification = ()=>{
        var preURL = "";
        var currentURL = location.pathname;
        var countSubs = (currentURL.split('/')).length - 1;
        if (countSubs > 1) {
            if (countSubs > 2) {
                preURL = "../../";
            } else {
                preURL = "../";
            }
        }

        var headers = { Authorization: "Bearer "+props.userToken };
        return axios.get(preURL+"api/user?profile=true", { headers }).then(response=>{
            console.log(response)
            if (response.data) {
                var respInfo = response.data;
                if (respInfo.success) {
                    if (respInfo.data.roleName !== "User" ) {
                        return {success: true, data: respInfo.data};
                    } else {
                        return {success: false, msg: "Not allowed", msg2:  "Unauthorized role"};
                    };
                } else {
                    return {success: false, msg: respInfo.msg, msg2:  respInfo.msg2};
                };
            } else {
                return {success: false, msg: "Request error", msg2: "Dashboard verification."};
            };
        }).catch(err=>{
            return {success: false, msg: "Press OK to reconfigure", msg2:  ""};
        })
    }

    const veryifyFunc = ()=>{
        if (!defaultAction) {
            $('#root').addClass('hideDashboard');
            if (!props.userToken) {
                // window.history.back();
            } else {
                onlineVerification().then(res=>{
                    if (res.success) {
                        $('#root').removeClass('hideDashboard');
                        localStorage.setItem("role", res.data.role);
                        setFecthedRole(res.data.role);
                        setDefaultAction(true);
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: res.msg,
                            text: res.msg2,
                            showCancelButton: true,
                        }).then(res2=>{
                            // window.history.back();
                            setTimeout(() => {
                                $('#root').removeClass('hideDashboard');
                            }, 200);
                        });
                    }
                })
            }
        } else {
            onlineVerification().then(res=>{
                if (res.success) {
                    $('#root').removeClass('hideDashboard');
                    localStorage.setItem("role", res.data.role);
                    setFecthedRole(res.data.role);
                    setDefaultAction(true);
                } else {
                    Swal.fire({
                        icon: "error",
                        title: res.msg,
                        text: res.msg2
                    }).then(res2=>{
                        // window.history.back();
                        setTimeout(() => {
                            $('#root').removeClass('hideDashboard');
                        }, 200);
                    });
                }
            })
        }
    }

    useEffect(()=>{
        const header = document.getElementById("header");
        const logoImg = document.getElementById("appLogo").src;
        var headerClass = header.classList;
        if ( !headerClass.contains("onDashboard") ) {
            header.classList.add("onDashboard");
            document.getElementById("appLogo").src = whiteIconFull;
        };
        return ()=> { // ComponentWillUnmount
            if ( headerClass.contains("onDashboard") ) {
                if (document.getElementById("appLogo")) {
                    document.getElementById("appLogo").src = logoImg;
                };
                header.classList.remove("onDashboard");
            };
          }
    }, []);

    return (
            <div className="dashboardBody" >
                <div className="navigations">
                    <div className="currentUser">
                        <div className="profilePic">
                            <img src={userProfilePic} alt="ProfilePic"/>
                        </div>
                        <p className="roleName">{userRoleName}</p>
                        <p className="userNames">{userNames}</p>
                    </div>
                    <NavLink exact to={"/dashboard"} className="tab" activeClassName={"active"}>
                        <div >
                            <DashboardRoundedIcon className="icon" />
                        </div>
                        <p>Dashboard</p>
                    </NavLink>
                    {
                        (rolePages[fecthedRole].includes("users")) ? (
                            <NavLink to={"/dashboard/users"} className="tab" activeClassName={"active"}>
                                <div >
                                    <PeopleAltRoundedIcon className="icon"/>
                                </div>
                                <p>Users</p>
                            </NavLink>
                        ) : ""
                    }
                    
                    {
                        (rolePages[fecthedRole].includes("content")) ? (
                            <NavLink to={"/dashboard/content"} className="tab" activeClassName={"active"}>
                                <div >
                                    <MovieFilterRoundedIcon className="icon" />
                                </div>
                                <p>Content</p>
                            </NavLink>
                        ) : ""
                    }
                    
                    {
                        (rolePages[fecthedRole].includes("vouchers")) ? (
                            <NavLink to={"/dashboard/vouchers"} className="tab" activeClassName={"active"}>
                                <div >
                                    <ReceiptRoundedIcon className="icon" />
                                </div>
                                <p>Vouchers</p>
                            </NavLink>
                        ) : ""
                    }
                    
                    {
                        (rolePages[fecthedRole].includes("subscriptions")) ? (
                            <NavLink to={"/dashboard/subscriptions"} className="tab" activeClassName={"active"}>
                                <div >
                                    <PaymentRoundedIcon className="icon" />
                                </div>
                                <p>Subscriptions</p>
                            </NavLink>
                        ) : ""
                    }
                    
                    {
                        (rolePages[fecthedRole].includes("communications")) ? (
                            <NavLink to={"/dashboard/communications"} className="tab" activeClassName={"active"}>
                                <div >
                                    <QuestionAnswerIcon className="icon"/>
                                </div>
                                <p>Communications</p>
                            </NavLink>
                        ) : ""
                    }
                </div>
                <div className="mainView">
                    { (defaultAction) ? (
                        <Switch>
                            <Route exact path="/dashboard" component={() => <Main userToken={props.userToken} />} />
                            <Route exact path="/dashboard/users" component={() => <Users userToken={props.userToken} showUsers={showUsers}/>} />
                            <Route exact path="/dashboard/vouchers" component={() => <Vouchers userToken={props.userToken} generateVoucher={generateVoucher} verifyVoucher={verifyVoucher} getCurrentVouchers={getCurrentVouchers} printCurrentVouchers={printCurrentVouchers} />} />
                            <Route exact path="/dashboard/content" component={() => <Content userToken={props.userToken} addCategory={addCategory} createMovie={createMovie} updateVideo={updateVideo} showPreview={props.showPreview} />} />
                            <Route exact path="/dashboard/subscriptions" component={() => <Subscriptions userToken={props.userToken} />} />
                            <Route exact path="/dashboard/communications" component={() => <Communications userToken={props.userToken} sendMessage={sendMessage} />} />
                            <Redirect to="/dashboard" />
                        </Switch>
                    ) : veryifyFunc() }
                </div>
            </div>
    );
  }
  
  export default Dashboard;