import React from 'react';


function OTP(props) {
    return (
        <>
            <h3>One Time PIN (OTP)</h3>
            <form id="otp" className="loginform" method="POST" onSubmit={props.onsubmit}>
                <input type="hidden" name="type" value="otp" />
                <div className="inputCont">
                    <input type="hidden" name="username" value={props.username} />
                    <input className="otp" type="number" name="otp" placeholder="0000" minLength="4" maxLength="4" required />
                </div>
                <button>Submit</button>
                <p><p>Resend OTP.</p></p>
            </form>
        </>
    );
}
  
export default OTP;