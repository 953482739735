import React, {Component} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';


import Backdrop from '../Backdrop';
import VideoPlayer from './VideoPlayer';



import './index.css';
import { Snackbar, Slide } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// import { SnackbarProvider, useSnackbar } from 'notistack';

import appDatabase from '../../js/appDatabase.js';
import genWebShare from '../../js/webShare.js';





class Watch extends Component {
    constructor(props){
        super(props);

        this.state = {
            videoData: {},
            toastState: {open: false, Transition: Slide},
            // enqueueSnackbar: useSnackbar()
        }
    }

    componentDidMount(){
        var vidData = this.props.video;
        var paramsString = window.location.search;
        const queryParams = new URLSearchParams(paramsString);
        const vid = queryParams.get("vid");
        var toastTimeout = null;

        const logActions = (action, vid, message)=>{
            clearTimeout(toastTimeout);
            if ((action === "offline")||(action === "later")||(action === "share")) {
                if (this.state.toastState.open) {
                    this.setState({
                        ...this.state,
                        toastState: {...this.state.toastState, open: false},
                    });
                };
                this.setState({
                    ...this.state,
                    toastState: {...this.state.toastState, open: true, message: message},
                });
            };
            var requestObject = {vid: vid}
            requestObject.section = this.props.pageFrom;
            var headers = { Authorization: "Bearer "+this.props.userToken };
            axios.post("api/content?action="+action, requestObject, {headers}).then(response=>{
                // console.log(response);
                if (response.data) {
                    var respInfo = response.data;
                    if (respInfo.success) {
                        if ((action === "offline")||(action === "later")) {
                            this.setState({
                                ...this.state,
                                toastState: {...this.state.toastState, open: true, message: respInfo.msg},
                            });
                            toastTimeout = setTimeout(()=>{
                                this.setState({
                                    ...this.state,
                                    toastState: {...this.state.toastState, open: false},
                                });
                            }, 5000);
                        };                        
                    } else {
                        this.setState({
                            ...this.state,
                            toastState: {...this.state.toastState, message: respInfo.msg},
                        });
                        toastTimeout = setTimeout(()=>{
                            this.setState({
                                ...this.state,
                                toastState: {...this.state.toastState, open: false},
                            });
                        }, 5000);
                    }
                } else {
                    this.setState({
                        ...this.state,
                        toastState: {...this.state.toastState, message: "Request failed"},
                    });
                    toastTimeout = setTimeout(()=>{
                        this.setState({
                            ...this.state,
                            toastState: {...this.state.toastState, open: false},
                        });
                    }, 5000);
                };
            }).catch(err=>{
                toastTimeout = setTimeout(() => {
                    this.setState({
                        ...this.state,
                        toastState: {...this.state.toastState, open: true, message: err.message},
                    });
                    toastTimeout = setTimeout(()=>{
                        this.setState({
                            ...this.state,
                            toastState: {...this.state.toastState, open: false},
                        });
                    }, 5000);
                }, 5000);
            });
        }

        const logMyView = ()=>{ logActions("views", vid); };
        const handleShare = ()=>{
            logActions("share", vid, "Openning share Request...");
            var shTitle = "Montywood | "+this.state.videoData.title;
            var shText = "Watch | "+this.state.videoData.title;
            var shUrl = window.location.href;
            genWebShare(shTitle, shText, shUrl);
        }
        const handleWatchLater = ()=>{ logActions("later", vid, "Saving Request..."); }
        const handleSaving = ()=>{
            if (this.state.videoData.offline) {
                var currentOfflineID = this.state.videoData.currentOfflineID;
                logActions("offline", vid, "Deleting Offline video...");
                this.deleteOffline(currentOfflineID).then(res=>{
                    this.setState({
                        ...this.state,
                        toastState: {...this.state.toastState, open: true, message: "Video deleted from offline library."},
                    });
                });
            } else {
                logActions("offline", vid, "Sending Download Request...");
                Promise.all([
                    fetch(this.state.videoData.thumbnail).then((response)=>{
                        return response.blob();
                    }), fetch(this.state.videoData.video).then((response)=>{
                        return response.blob();
                    })
                ]).then(([imageBlob, videoBlob])=>{
                    var vidStoreData = {vid: this.state.videoData.vid, title: this.state.videoData.title, description: this.state.videoData.description,
                        type: this.state.videoData.type, category: this.state.videoData.category, isMultiple: this.state.videoData.isMultiple,
                        video: videoBlob, thumbnail: imageBlob};
                    return this.saveOffline(vidStoreData);
                }).then(res=>{
                    // console.log(res);
                    // var offlineContent = localStorage.getItem('offlineVids') ? JSON.parse(localStorage.getItem('offlineVids')) : [];
                    // offlineContent.push({id: res.id, vid: this.state.videoData.vid});
                    // localStorage.setItem("offlineVids", offlineContent);
                    this.setState({
                        ...this.state,
                        toastState: {...this.state.toastState, open: true, message: "Video saved for offline use."},
                    });
                });
            }
        }

        const fetchVid = ()=>{
            var headers = {
                Authorization: "Bearer "+this.props.userToken
            };
            axios.get("api/content?vid="+vid, { headers }).then(response=>{
                // console.log(response);
                if (response.data) {
                    var respInfo = response.data;
                    if (respInfo.success) {
                        var theVideoData = respInfo.data;
                        var getOne = theVideoData[0];
                        this.setState({
                            ...this.state,
                            videoData: getOne,
                            handleSaving: handleSaving,
                            handleWatchLater: handleWatchLater,
                            handleShare: handleShare,
                            toastState: this.state.toastState,
                        });
                        logMyView();
                    } else {
                        Swal.fire({
                            icon: "info",
                            title: respInfo.msg,
                            text: respInfo.msg2,
                            showCancelButton: true,
                            cancelButtonText: "Leave",
                            confirmButtonText: "Retry"
                        }).then(res=>{
                            if (res.isConfirmed) {
                                fetchVid();
                            } else {
                                window.history.back();
                            };
                        });
                    };
                } else {
                    Swal.fire({
                        icon: "info",
                        title: "Retry",
                        text: "something happened.",
                        showCancelButton: true,
                        cancelButtonText: "Leave.",
                        confirmButtonText: "Retry"
                    }).then(res=>{
                        if (res.isConfirmed) {
                            fetchVid(vid);
                        } else {
                            window.history.back();
                        };
                    });
                };
            }).catch(error=>{
                Swal.fire({
                    icon: "info",
                    title: "Retry",
                    text: error.message,
                    showCancelButton: true,
                    cancelButtonText: "Leave",
                    confirmButtonText: "Retry",
                    allowEscapeKey: true,
                }).then(res=>{
                    if (res.isConfirmed) {
                        fetchVid(vid);
                    } else {
                        window.history.back();
                        // this.setState({
                        //     ...this.state,
                        //     handleSaving: handleSaving,
                        //     handleWatchLater: handleWatchLater,
                        //     handleShare: handleShare,
                        //     toastState: this.state.toastState,
                        // });
                    };
                });
            });            
        };



        if (Object.keys(vidData).length > 1) {
            if (vidData.video) {
                this.setState({
                    videoData: this.props.video,
                    handleSaving: handleSaving,
                    handleWatchLater: handleWatchLater,
                    handleShare: handleShare,
                    toastState: this.state.toastState,
                });
                logMyView();
            } else {
                fetchVid(vid);
            }
        } else {
            fetchVid(vid);
        };
    }

    saveOffline = (vidStoreData)=>{
        return appDatabase("videos", 'offline').then(databaseObject=>{
            return databaseObject.appDbMethod('readwrite').then((vidStore)=>{
                return vidStore.put(vidStoreData);
            }).then(res=>{
                databaseObject.db.close();
                return res;
            });
        })
    }

    deleteOffline = (id)=>{
        return appDatabase("videos", 'offline').then(databaseObject=>{
            return databaseObject.appDbMethod('readwrite').then((vidStore)=>{
                return vidStore.delete(id);
            }).then(res=>{
                databaseObject.db.close();
                return res;
            });
        })
    }

    render () {
        const {videoData, handleSaving, handleShare, handleWatchLater, toastState} = this.state;

        var videoProps = {};
        if (videoData) {
            if (videoData.title) {
                document.title = 'Montywood | '+videoData.title;
            } else {
                document.title = 'Montywood | Watching...';
            };
            videoProps = {
                title: videoData.title,
                videoTitle: videoData.episodeTitle,
                video: videoData.video,
                videoDescription: videoData.description,
                startTime: videoData.startTime,
                partNumber: videoData.partNumber,
                episode: videoData.episode,
                hasOffline: videoData.offline,
            };
        } else {
            document.title = 'Montywood | Watching...';
        };

        const snackClose = () => {
            this.setState({
                ...this.state,
                toastState: {...this.state.toastState, message: null, open: false},
            });
        };

        return (
            <>
                <Snackbar
                    zIndex="1000"
                    className="mySnackBar"
                    open={toastState.open}
                    // onClose={snackClose}
                    TransitionComponent={toastState.Transition}
                    message={toastState.message}
                    key={toastState.Transition.name}
                    action={
                        <React.Fragment>
                          <IconButton size="small" aria-label="close" color="inherit" onClick={snackClose}>
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </React.Fragment>
                      }
                /> 
                <Backdrop close={this.props.close} className="playBackground" />
                <VideoPlayer
                    videoSRC={videoProps.video}
                    title={videoProps.title} episodeTitle={videoProps.videoTitle} 
                    description={videoProps.videoDescription}
                    startTime={videoProps.startTime}
                    partNumber={videoProps.partNumber}
                    episode={videoProps.episode}
                    shareOption={handleShare}
                    watchLaterOption={handleWatchLater}
                    hasOffline={videoProps.hasOffline}
                    saveOption={handleSaving}
                />    
                          
            </>
        );
    }
}
  
export default Watch;