import React from 'react';


function WatchLaterBackground(props) {
    
    return (
        <>
            <div className="homeBackground watchLaterBackground" >
                <div className="recentMovie watchLaterHead">
                    <h1>
                        Marked & Offline content
                    </h1>
                    <br></br>
                    <br></br>
                </div>
            </div>
        </>
    );
  }
  
  export default WatchLaterBackground;