import React from 'react';


function TvShowLoader(props) {
    
    return (
        <div key={props} className="loaderMovie loaderTvShow" >
            <i className="shineElemLoader"></i>
            <p>.</p>
            <p>.</p>
        </div>
    );
  }
  
  export default TvShowLoader;