import React, { useRef, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';

import Checkout from './Checkout';

import './index.css';


function Subscription({isSubscribed, setIsSubscribed}) {
    document.title = 'Montywood | Membership';

    var voucherRef = useRef();
    const location = useLocation();
    const history = useHistory();
    const [stateObj, setStateObj] = useState(location); 

    const onSubscription = () => { //send this 'onSignIn' functoin to the loginForm for update upon sign in
        // console.log(stateObj);
        const link = (stateObj && stateObj.state.from) || "/";
        setIsSubscribed(true);
        localStorage.setItem("isSubscribed", true);
        
        console.log(link);
        history.replace(link);
    };

    var paySubscription = (e)=>{
        var thisBtn = e.target;
        var voucherValue = voucherRef.current.value;
        var endpoint = "";
        var subscriptionOpt = JSON.parse(thisBtn.getAttribute("data-choice"));


        var requestObject = {};
        requestObject.membershipPackage = subscriptionOpt;
        if (thisBtn.id === "payVoucher") {
            endpoint = "api/subscription?method=voucher&action=redeem";
            if ((voucherValue === "")||((voucherValue.replace(/\s/g, '')).length < 1) ) {
                document.getElementById("voucherField").focus();
                return;
            };
            thisBtn.innerHTML = "Paying...";
            thisBtn.classList.add("voucherPaying");
            thisBtn.disabled = true;

            requestObject.voucher = voucherValue;
        } else if (thisBtn.id === "payOther") {
            endpoint = "api/subscription?method=general&action=pay";
            Swal.fire({
                icon: "info",
                title: "Connecting...",
                allowOutsideClick: false,
                showConfirmButton: false,
            });
        };
        
        var getToken = localStorage.getItem("userToken");
        var headers = {
            Authorization: "Bearer "+getToken
        };
        axios.post(endpoint, requestObject, {headers}).then(response=>{
            console.log(response);
            if (response.data) {
                var respInfo = response.data;
                if (respInfo.success) {
                    if (thisBtn.id === "payVoucher") {
                        thisBtn.innerHTML = "Voucher Paid";
                        thisBtn.classList.remove("voucherPaying");
                        thisBtn.classList.add("voucherPaid");
                        thisBtn.disabled = false;
                        setTimeout(()=>{
                            onSubscription();
                        }, 2000);
                    } else { //Here we handle the payment with other methods otehr than voucher
                        var afterConn = respInfo.afterConn;
                        (function tryCarryon(afterConn){
                            Swal.fire({
                                icon: "info",
                                title: "Fetching supported payment methods...",
                                allowOutsideClick: false,
                            });
                            window.payfast_do_onsite_payment({"uuid": afterConn.uuid}, (result)=>{
                                if (result === true) { // Payment Completed
                                    Swal.fire({
                                        icon: "success",
                                        title: afterConn.msg,
                                        text: afterConn.msg2,
                                        allowOutsideClick: false,
                                    }).then(res=>{
                                        onSubscription();
                                    });
                                    (function donePayment() {
                                        history.goBack();
                                        setTimeout(()=>{
                                            if (history.location.hash === "#payfast") {
                                                donePayment();
                                            };
                                        }, 10);
                                    })();
                                } else { // Payment Window Closed.
                                    if (history.location.hash === "#payfast") history.goBack();
                                    Swal.fire({
                                        icon: "question",
                                        title: "Retry.",
                                        text: "Do you want to retry.",
                                        showCancelButton: true,
                                        allowOutsideClick: false,
                                        confirmButtonColor: "var(--main-gold-color)",
                                        cancelButtonColor: "var(--main-dark-color)",
                                        confirmButtonText: "Retry",
                                        cancelButtonText: "cancel"
                                    }).then(confirmRetry=>{
                                        console.log(confirmRetry);
                                        if ((confirmRetry.dismiss==='cancel')||confirmRetry.isConfirmed) {
                                            if (confirmRetry.isConfirmed) {
                                                tryCarryon(afterConn);
                                            }
                                        }
                                    });
                                };
                            })
                        })(afterConn);
                    }
                } else {
                    if (thisBtn.id === "payVoucher") {
                        Swal.fire({
                            icon: "error",
                            title: respInfo.msg,
                            text: respInfo.msg2,
                            allowOutsideClick: false,
                            confirmButtonColor: "var(--main-gold-color)",
                            confirmButtonText: "Double-check",
                        });
                        thisBtn.innerHTML = "Redeem Voucher";
                        thisBtn.classList.remove("voucherPaid");
                        thisBtn.classList.remove("voucherPaying");
                        thisBtn.disabled = false;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: respInfo.msg,
                            text: respInfo.msg2,
                            html: respInfo.msg3,
                            allowOutsideClick: false,
                            showCancelButton: true,
                            confirmButtonColor: "var(--main-gold-color)",
                            cancelButtonColor: "black",
                            confirmButtonText: "Try again",
                            cancelButtonText: "Cancel"
                        }).then(attempt=>{
                            if (attempt.isConfirmed) {
                                paySubscription(e);
                            };
                        });
                    };
                };
            } else {
                if (thisBtn.id === "payVoucher") {
                    thisBtn.innerHTML = "Redeem Voucher";
                    thisBtn.classList.remove("voucherPaid");
                    thisBtn.classList.remove("voucherPaying");
                    thisBtn.disabled = false;
                    console.log("Eish");
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Something went wrong",
                        allowOutsideClick: false,
                        showCancelButton: true,
                        confirmButtonColor: "var(--main-gold-color)",
                        cancelButtonColor: "black",
                        confirmButtonText: "Try again",
                        cancelButtonText: "Cancel"
                    }).then(attempt=>{
                        if (attempt.isConfirmed) {
                            paySubscription(e);
                        };
                    });
                }
            };
        }).catch(error=>{
            console.log(error);
            
            Swal.fire({
                icon: "error",
                title: "Request error",
                allowOutsideClick: false,
                showCancelButton: true,
                confirmButtonColor: "var(--main-gold-color)",
                cancelButtonColor: "black",
                confirmButtonText: "Try again",
                cancelButtonText: "Cancel"
            }).then(attempt=>{
                if (!attempt.isConfirmed) {
                    if (thisBtn.id === "payVoucher") {
                        thisBtn.innerHTML = "Redeem Voucher";
                        thisBtn.classList.remove("voucherPaid");
                        thisBtn.classList.remove("voucherPaying");
                        thisBtn.disabled = false;     
                    };
                } else {
                    paySubscription(e);
                };
            });
        });
    };

    const keepHistory = (hist)=> {
        setStateObj(hist);
    };

    return (
        <>
            <div className="subscriptionBackground" onClick={()=>{ history.goBack() }}></div>
            <div className="subscriptionContatiner">
                <button className="closeSubscription" onClick={()=>{ history.goBack() }}>&#10140;</button>
                <h3>Get Membership</h3>
                <div className="membershipOptions">
                    <Checkout keepHistory={keepHistory} stateObj={stateObj} voucherRef={voucherRef} onsubmit={paySubscription} />
                </div>
            </div>
        </>
    );
}

export default Subscription;