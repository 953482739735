import React, {useState, useEffect, useMemo, useCallback} from 'react';
import axios from 'axios';
import Swal from "sweetalert2";  

import Backdrop from '../../../../Backdrop';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
// import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";


import './index.css';


function CreateMovie(props) {
    var dynamicProps = props.dynamicProps;
    
    var initialCategoryState = useMemo(() => {
        return [{label: "Choose category", value: ""}];
    }, []);
    const [categories, setCategories] = useState(initialCategoryState);

    

    const getCategories = useCallback(()=>{
        var headers = {Authorization: "Bearer "+dynamicProps.userToken};
        axios.get("../api/dashboard/content?action=getCategories", {headers}).then(response=>{
            console.log(response);
            if (response.data) {
                var respInfo = response.data;
                if (respInfo.success) {
                    if (respInfo.data) {
                        var newCategories = [];
                        newCategories.push(initialCategoryState[0]);
                        respInfo.data.forEach(element => {
                            newCategories.push(element);
                        });
                        setCategories(newCategories);
                    }
                }
            }
        }).catch(err=>{
            console.log(err.message);
        });
    }, [dynamicProps.userToken, initialCategoryState])
        
    const sendRequest = (e)=>{
        e.preventDefault();
        e.stopPropagation();

        const thisForm = e.target;
        var formData = new FormData(thisForm);
        var requestObject = Object.fromEntries(formData.entries());

        Swal.fire({
            icon: "info",
            title: "Submitting...",
            // text: respInfo.msg2,
            allowOutsideClick: false,
            showConfirmButton: false,
        });
        var headers = {
            Authorization: "Bearer "+dynamicProps.userToken
        };
        axios.post("../api/dashboard/content?action=create", requestObject, {headers}).then(response=>{
            console.log(response)
            if (response.data) {
                var respInfo = response.data;
                if (respInfo.success) {
                    Swal.fire({
                        icon: "success",
                        title: respInfo.msg,
                        text: respInfo.msg2,
                        allowOutsideClick: false,
                    });
                } else {
                    console.log(respInfo.msg, respInfo.msg2);
                    Swal.fire({
                        icon: "error",
                        title: respInfo.msg,
                        text: respInfo.msg2,
                        allowOutsideClick: false,
                    });
                };
            } else {
                console.log("Eish yah");
                Swal.fire({
                    icon: "error",
                    title: "Reuest failed",
                    text: "Reuest failed",
                    allowOutsideClick: false,
                });
            };
        }).catch(error=>{
            Swal.close();
            console.log(error);
            console.log("Catch: Something went wrong");
        });
    }

    const productionType = [
        {label: "Choose the Type", value: ""},
        {label: "Movie", value: "movie"},
        {label: "Series", value: "series"},
        {label: "TV Show", value: "tvshow"},
    ]

    useEffect(() => {
        getCategories();
    }, [getCategories])

    return (
        <>
            <Backdrop close={props.close} className="creactProductionBackdrop" />
            <div className="creactProduction">
                <h1>Create a Movie/Series/TV Show</h1>
                <form onSubmit={sendRequest}>
                    <div>
                        <TextField className="materialField" name="type" label="Production Type" variant="outlined" select required >
                            {productionType.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div>
                        <TextField className="materialField" name="category" label="Category" variant="outlined" select required >
                            {categories.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div>
                        <TextField className="materialField" name="title" label="Title of the production" variant="outlined"
                        required />
                    </div>
                    <div className="descriptionPart">
                        <TextField className="materialField" style={{width: "100%"}} name="description" label="Description" variant="outlined" multiline maxRows="4" required/>
                    </div>
                    <br/><br/>
                    <br/><br/>
                    <Button className="submitBtn" variant="contained" type="submit">Generate</Button>
                </form>
            </div>
        </>
    );
  }
  
  export default CreateMovie;