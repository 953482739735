import React, {Component} from 'react';
import axios from 'axios';
import Swal from "sweetalert2";  

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
// import { ThumbUpTwoTone } from '@material-ui/icons';

// import {DropzoneArea} from 'material-ui-dropzone';


import './index.css';

class Upload extends Component {
    constructor(props){
        super(props);

        this.state = {
            categories: [{value: "", label: "No categories loaded"}],
            chooseSeries: {},
            seriesNames: [],
            capturedSeries: "",
            partLabel: {label: "Part/Season Number", label2: "Part/Season"}
        };
    }

    componentDidMount(){
        var seriesLabel = "Corresponding Production Title";
        var partLabel = this.state.partLabel;

        var partNumber = document.getElementById("partNumber");
        var episodeNumber = document.getElementById("episodeNumber");
        var isPartOf = document.getElementById("isPartOf");
        var partNumberField = document.getElementById("partNumberField");
        var episodeNumberField = document.getElementById("episodeNumberField");
        var isPartOfField = document.getElementById("isPartOfField");

        const handleTypeChange = (e) => {
            var theValue = e.target.value;
            if ((theValue === "series")||(theValue === "tvshow")) {
                partNumber.style.display = "inline-block";
                isPartOf.style.display = "none";
                if (theValue === "series") {
                    episodeNumber.style.display = "inline-block";
                    episodeNumberField.setAttribute("required", true);
                    seriesLabel = "Corresponding Series";
                } else if (theValue === "tvshow"){
                    episodeNumber.style.display = "inline-block";
                    seriesLabel = "Corresponding TV Show";
                } else {
                    episodeNumber.style.display = "none";
                    seriesLabel = "Corresponding Movie Title";
                };
                partLabel = {label: "Season Number", label2: "Season"};

                partNumberField.setAttribute("required", true);
                isPartOfField.setAttribute("required", true);
            } else {
                isPartOf.style.display = "inline-block";
                partNumber.style.display = "none";
                episodeNumber.style.display = "none";

                partNumberField.removeAttribute("required");
                episodeNumberField.removeAttribute("required");
                isPartOfField.removeAttribute("required");

                seriesLabel = "Corresponding Movie Title";
            };
            this.setState({...this.state, partLabel: partLabel, seriesLabel: seriesLabel});
        };
        const handleHasParts = (e) => {
            var theValue = e.target.value;
            if (theValue === "yes") {
                seriesLabel = "Corresponding Movie Title";
                partLabel = {label: "Part Number", label2: "Part"};
                partNumber.style.display = "inline-block";
                
                partNumberField.setAttribute("required", true);
            } else {
                partNumberField.removeAttribute("required");
                partNumber.style.display = "none";
            };
            this.setState({...this.state, partLabel: partLabel, seriesLabel: seriesLabel});
        };

        this.setState({...this.state, seriesLabel: seriesLabel, partLabel: partLabel, handleTypeChange: handleTypeChange, handleHasParts: handleHasParts});


        var headers = {Authorization: "Bearer "+this.props.userToken};
        axios.get("../../api/dashboard/content?action=getCategories", {headers}).then(response=>{
            console.log(response);
            if (response.data) {
                var respInfo = response.data;
                if (respInfo.success) {
                    if (respInfo.data) {
                        var newCategories = [];
                        newCategories.push(this.state.categories[0]);
                        respInfo.data.forEach(element => {
                            newCategories.push(element);
                        });
                        this.setState({...this.state, categories: newCategories});
                    }
                }
            }
        }).catch(err=>{
            console.log(err);
        });
    }

    handleChange(files){
        this.setState({
            files: files
        });
    }

    searchSeries = (e)=>{
        var theValue = e.target.value;
        // var fecthedTitles =[{title: "XXX", value: "XXX", label: "XXX"}];
        var fecthedTitles =[];
        var headers = {Authorization: "Bearer "+this.props.userToken};
        axios.get("../../api/dashboard/content?action=getTitles&q="+theValue, {headers}).then(response=>{
            console.log(response);
            if (response.data) {
                var respInfo = response.data;
                if (respInfo.success) {
                    if (respInfo.data) {
                        this.setState({...this.state, seriesNames: respInfo.data});
                    } else {
                        this.setState({...this.state, seriesNames: fecthedTitles});
                    }
                } else {
                    this.setState({...this.state, seriesNames: fecthedTitles});
                }
            } else {
                this.setState({...this.state, seriesNames: fecthedTitles});
            };
        }).catch(err=>{
            console.log(err);
            this.setState({...this.state, seriesNames: fecthedTitles});
        });
    }
    

    captureSeries = (event, values) => {
        if (values) {
            this.setState({...this.state, capturedSeries: values.value});
        } else {
            this.setState({...this.state, capturedSeries: ""});
        }
    }

    handleUploadProgress = (elem, e)=>{
        // console.log(e);
        var perecentUploadedObj  = {label: "Preparing upload", value: 0};
        if (e.lengthComputable) {
            var perecentUploaded = Math.floor((e.loaded/e.total)*100);
            perecentUploadedObj.value = perecentUploaded;
            if (perecentUploaded < 100) {
                perecentUploadedObj.label = perecentUploaded+"% Uploaded...";
            } else {
                perecentUploadedObj.label = perecentUploaded+"% Complete, awaiting result.";
            };
        };
        document.getElementById("uploadProgressText").innerHTML = perecentUploadedObj.label;
        elem.value = perecentUploadedObj.value;
    }

    makeUpload = (e)=>{
        e.preventDefault();
        e.stopPropagation();
        const thisForm = e.target;

        var theHTML = '<div class="uploadProgressContainer"><span id="uploadProgressText"></span><progress id="uploadProgressBar" class="uploadProgressBar" value="0" max="100"> 0% </progress></div>';
        Swal.fire({
            icon: "info",
            title: "Submitting...",
            html: theHTML,
            allowOutsideClick: false,
            showConfirmButton: false,
        });
        const uploadProgressBar = document.getElementById("uploadProgressBar");
        var formData = new FormData(thisForm);
        formData.append("name", this.state.capturedSeries);
        var headers = { Authorization: "Bearer "+this.props.userToken, "Content-Type": "multipart/form-data"};
        const config = {
            onUploadProgress: (progressEvent) => { 
                this.handleUploadProgress(uploadProgressBar, progressEvent);
            },
            headers: headers
        };
        axios.post("../../api/dashboard/content?action=upload", formData, config).then(response=>{
            console.log(response)
            if (response.data) {
               var respInfo = response.data;
                if (respInfo.success) {
                    Swal.fire({
                        icon: "success",
                        title: respInfo.msg,
                        text: respInfo.msg2,
                        allowOutsideClick: false,
                        confirmButtonColor: "var(--main-gold-color)"
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: respInfo.msg,
                        text: respInfo.msg2,
                        allowOutsideClick: false,
                        confirmButtonColor: "var(--main-dark-color)"
                    });
                    console.log(respInfo.msg, respInfo.msg2);
                };
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Request failed",
                    text: "Reuest failed",
                    allowOutsideClick: false,
                    confirmButtonColor: "var(--main-dark-color)"
                });
            };
        }).catch(error=>{
            Swal.fire({
                icon: "error",
                title: "Upload failed",
                text: error.message,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "var(--main-dark-color)"
            }).then(res=>{
                if (res.isConfirmed) {
                    this.makeUpload(e);
                }
            });
        });
    }

    render () {
        var {
            seriesLabel, handleTypeChange, handleHasParts, seriesNames, partLabel, categories
        } = this.state;
        
        const types = [
            { label: "Choose type", value: "" },
            { label: "Movie", value: "movie" },
            { label: "Series", value: "series" },
            { label: "Tv Show", value: "tvshow" }
        ];
        var isPartOfOps = [{label: "Is is part of movie series", value: ""}, {label: "No", value: "noways"}, {label: "Yes", value: "yes"}];
        var partNumbers = [{label: "Choose below", value: ""},
            {label: "1", value: 1}, {label: "2", value: 2}, {label: "3", value: 3},{label: "4", value: 4},{label: "5", value: 5},
            {label: "6", value: 6},{label: "7", value: 7},{label: "8", value: 8},{label: "9", value: 9},{label: "10", value: 10}
        ];

        return (
            <>
            <div className="uploadFormHolder" >
                <form onSubmit={this.makeUpload}>
                    <div className="uploadInput theThumbnail">
                        {/* <label for="dropThumbnail">Thumbnail</label>
                        <DropzoneArea
                            id="dropThumbnail"
                            acceptedFiles={['image/jpeg', 'image/png', 'image/bmp', 'image/webp']}
                            // showPreviews={false}
                            maxFileSize={5000000}
                            onChange={this.handleChange.bind(this)}
                            required
                        >
                            <p>Drag 'n' drop some files here, or click to select files</p>
                        </DropzoneArea> */}
                       <Button className="materialField" variant="text" component="label">Thumbnail 
                            <input name="thumbnail" type="file" accept="image/*"  required />
                        </Button>
                        <div className="uploadPreviewSpace">

                        </div>
                    </div>
                    <div className="uploadInput theVideo">
                        {/* <label for="dropVideo">The Video</label>
                        <DropzoneArea
                            id="dropVideo"
                            acceptedFiles={['video/mp4', 'video/m4v']}
                            maxFileSize={5000000}
                            onChange={this.handleChange.bind(this)}
                            required
                        /> */}
                        <Button className="materialField" variant="text" component="label">Video 
                            <input name="video" type="file" accept="video/*"  required />
                        </Button>
                        <div className="uploadPreviewSpace">

                        </div>
                    </div>
                    <div className="uploadInput">
                        <TextField className="materialField" name="type" 
                        label="Type" 
                        variant="outlined" 
                        onChange={handleTypeChange} 
                        select required>
                            {types.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div className="uploadInput">
                        <TextField className="materialField" name="category" label="Category" 
                        variant="outlined" select required>
                            {categories.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div id="isPartOf" className="uploadInput">
                        <TextField id="isPartOfField" className="materialField" name="isPartOf" 
                        label="Is this movies part of many?" variant="outlined" 
                        onChange={handleHasParts}
                        select>
                            {isPartOfOps.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <br/>
                    <div id="chooseSeries" className="uploadInput">
                        <Autocomplete
                        id="chooseSeriesField"
                        options={seriesNames}
                        getOptionLabel={(option) => option.title}
                        // style={{ width: 300 }}
                        onChange={this.captureSeries}
                        renderInput={(params) => <TextField {...params} name="name" label={seriesLabel} onChange={this.searchSeries}
                        variant="outlined" required/>}
                        required
                        />
                    </div>
                    <div id="partNumber" className="uploadInput">
                        <TextField id="partNumberField" className="materialField" name="season" 
                        label={partLabel.label} variant="outlined" select >
                            {partNumbers.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                {/* {"P"+option.value+" - "+option.label} */}
                                {(option.value)?(partLabel.label2+": "+option.value): option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div id="episodeNumber" className="uploadInput">
                        <TextField id="episodeNumberField" className="materialField" name="episode" label="Episode number" type="number" variant="outlined"/>
                    </div>
                    {/* <br/> */}
                    <div className="uploadInput">
                        <TextField className="materialField" name="title" label="Title for this upload." variant="outlined" required/>
                    </div>
                    <div className="uploadInput uploadDescription">
                        <TextField className="materialField" name="description" label="Description" variant="outlined" multiline maxRows="5" required/>
                    </div>
                    <div className="uploadInput uploadSubmit">
                        <Button className="materialButton" variant="contained" type="submit" 
                        startIcon={<CloudUploadIcon />}
                        >Upload</Button>
                    </div>
                </form>
            </div>
        </>
        )
    }
}

export default Upload;