import React, {Component} from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Swal from "sweetalert2";  

import IconButton from '@material-ui/core/IconButton';
// import PhotoCamera from '@material-ui/icons/PhotoCamera';
import EditIcon from '@material-ui/icons/Edit';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import "./index.css";

class User extends Component {
    constructor(props){
        super(props);

        this.state = {
            pullProfile: []
        }
    }

    componentDidMount(){
        var localPieces = {
            firstname: localStorage.getItem("firstname"),
            lastname: localStorage.getItem("lastname"),
            profilePic: localStorage.getItem("profilePic"),
            roleName: localStorage.getItem("roleName")
        }

        const getFreshProfile = ()=>{
            var headers = {
                Authorization: "Bearer "+this.props.userToken
            };
            axios.get("api/user?profile=true", { headers }).then(response=>{
                console.log(response)
                if (response.data) {
                    var respInfo = response.data;
                    if (respInfo.success) {
                        this.setState({...this.state, pullProfile: respInfo.data});
                        localStorage.setItem("firstname", respInfo.data.firstname);
                        localStorage.setItem("lastname", respInfo.data.lastname);
                        localStorage.setItem("profilePic", respInfo.data.profilePic);
                        localStorage.setItem("roleName", respInfo.data.roleName);
                        localStorage.setItem("role", respInfo.data.role);
                    } else {
                        window.history.back();
                        console.log(respInfo.msg, respInfo.msg2);
                    };
                } else {
                    console.log("Eish")
                };
            }).catch(error=>{
                console.log(error);
            });
        }
        const updateProfile = (e)=>{

            e.preventDefault();
            e.stopPropagation();
            const thisForm = e.target;

            var theHTML = '<div class="uploadProgressContainer"><span id="uploadProgressText"></span><progress id="uploadProgressBar" class="uploadProgressBar" value="0" max="100"> 0% </progress></div>';
            Swal.fire({
                icon: "info",
                title: "Submitting...",
                html: theHTML,
                allowOutsideClick: false,
                showConfirmButton: false,
            });
            const uploadProgressBar = document.getElementById("uploadProgressBar");
            var formData = new FormData(thisForm);
            var headers = { Authorization: "Bearer "+this.props.userToken, "Content-Type": "multipart/form-data"};
            const config = {
                onUploadProgress: (progressEvent) => { 
                    this.handleUploadProgress(uploadProgressBar, progressEvent);
                },
                headers: headers
            };
            axios.post("api/user?profile=true&action=update", formData, config).then(response=>{
                console.log(response)
                if (response.data) {
                    var respInfo = response.data;
                    if (respInfo.success) {
                        Swal.fire({
                            icon: "success",
                            title: respInfo.msg,
                            text: respInfo.msg2,
                            allowOutsideClick: false,
                            confirmButtonColor: "var(--main-gold-color)"
                        });
                        getFreshProfile();
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: respInfo.msg,
                            text: respInfo.msg2,
                            allowOutsideClick: false,
                            confirmButtonColor: "var(--main-dark-color)"
                        });
                    };
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Request failed",
                        text: "Reuest failed",
                        allowOutsideClick: false,
                        confirmButtonColor: "var(--main-dark-color)"
                    });
                };
            }).catch(error=>{
                console.log(error);
                Swal.fire({
                    icon: "error",
                    title: "Request failed",
                    text: error.message,
                    showCancelButton: true,
                    allowOutsideClick: false,
                    confirmButtonColor: "var(--main-dark-color)"
                }).then(res=>{
                    if (res.isConfirmed) {
                        updateProfile(e);
                    }
                });
            });
        }

        this.setState({pullProfile: localPieces, updateProfile: updateProfile});
        getFreshProfile();        
    }

    componentWillUnmount(){
        
    }

    render () {
        document.title = 'Montywood | My Profile';

        const {pullProfile, updateProfile} = this.state;
        var goToDashborad;
        var showRole, labelPart = <label>{" . " }</label>;
        var pulledSubscriptions = [];
        var pulledPaymentMethods = [];
        console.log(pullProfile);
        var userNames = pullProfile.firstname+" "+pullProfile.lastname;
        var profilePic = pullProfile.profilePic;
        if (pullProfile.roleName) {
            if ((pullProfile.roleName !== "User")&&(pullProfile.roleName !== "")) {
                labelPart = <label>{"Role:" }</label>;
                showRole = <span>{pullProfile.roleName}</span>;
                goToDashborad = <Link to="/dashboard">Go to Dashboard &#10140;</Link>;
            };
        };
        if (pullProfile.subscriptions) {
            pulledSubscriptions = pullProfile.subscriptions;
        }
        if (pullProfile.paymentMethods) {
            pulledPaymentMethods = pullProfile.paymentMethods;
        }

        const handleLogOut = ()=>{
            Swal.fire({
                icon: "question",
                title: "Continue?",
                text: "Download/Offline videos will also be cleared/deleted.",
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No, stay loggen in.",
                confirmButtonColor: "var(--main-gold-color)"
            }).then(res=>{
                if (res.isConfirmed) {
                    this.props.setIsAuthorized(false);
                    this.props.setToken(false);
                    this.props.setIsSubscribed(false);

                    localStorage.clear();
                }
            });
        }
        const triggerUpdate = ()=>{
            var infoToPass = pullProfile;
            infoToPass.updateProfile = updateProfile;
            this.props.showUpdateProfile(infoToPass);
        }

        return (
            <div className="userProfile" >
                <div className="upperCard">
                    <label htmlFor="updatePicBtn" className="updateProfile" onClick={triggerUpdate}>
                        <IconButton color="primary" aria-label="upload picture" className="updateProfileBtn" component="span">
                            <EditIcon className="updateProfileBtnIcon" />
                            Edit
                        </IconButton>
                    </label>
                    <button className="handleLogOut" onClick={handleLogOut}>Logout</button>
                    <div className="profile-image">
                        {
                           pullProfile.profilePic ? <img src={profilePic} alt="Profile Pic" /> : <AccountCircleIcon className="profilePicIcon" />
                        }
                    </div>
                    <div className="namesPart">
                        <h1>
                            {
                                userNames
                            }
                        </h1>
                        <div className="rolePart">
                            {
                                labelPart
                            }
                            {
                                showRole
                            }
                            {
                                goToDashborad
                            }
                        </div>
                    </div>
                </div>
                <div className="lowerCard">
                    <div className="subscriptions">
                        <h1>Subscriptions</h1>
                        <div>
                            {
                                pulledSubscriptions.length ? (
                                    <table>
                                        <thead>
                                            <tr>
                                                <td>Date</td><td>Duration</td><td>Amount</td><td>State</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                pulledSubscriptions.map(elem => {
                                                    return <tr>
                                                        <td>{elem.dateAdded}</td>
                                                        <td>{elem.duration}</td>
                                                        <td>{elem.price}</td>
                                                        <td>{elem.state}</td>
                                                        </tr>;
                                                })
                                            }
                                        </tbody>
                                    </table>
                                ) : <p className="noContentP">No subscriptions yet.</p>
                            }                                    
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    <div className="paymentMethods">
                        <h1>Payment Methods</h1>
                        <div>
                            {
                                pulledPaymentMethods.length ? (
                                    pulledPaymentMethods.map(elem => {
                                        return <div className="theMethod">
                                            <p>{elem.name}</p>
                                            <p>{elem.price}</p>
                                            <p>{elem.state}</p>
                                            </div>;
                                    })
                                ) : <p className="noContentP">No payment methods yet.</p>
                            } 
                        </div>
                    </div>
                </div>
                <br></br>
                <br></br>
                <br></br>
            </div>
        );
    }
}
  
export default User;