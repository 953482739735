
// // import Chart from 'chart.js/auto';
// // import { Line, Bar, Doughnut } from 'react-chartjs-2';
// import { Bar, Doughnut } from 'react-chartjs-2';
// // // import { ReactChart } from 'react-chartjs-2';
// // import { ReactChart } from 'chartjs-react';


// const CustomCharts = (props)=>{
//     console.log(props)
//     // console.log(Chart)
//     var type = 'bar';
//     var usefullData = {
//         labels: [],
//         datasets: [
//             // {

//             // }
//         ]
//     };
//     if (props) {
//         if (props.data) {
//             if (Object.keys(props.data).length > 0) {
//                 usefullData = props.data;
//             }
//         };
//         if (props.type) {
//             type = props.type;
//         }
//     }
//     console.log(type, usefullData);
//     return (
//         <>
//         {
//             (
//                 props.type === 'doughut'
//             )?(
//                 <Doughnut data={usefullData} />
//             ):(
//                 <Bar data={usefullData} />
//             )
//         }
//         </>
//         // <ReactChart type={type} data={usefullData} />
//     )
// }

// export default CustomCharts;



// import React from 'react';
import { Chart as ChartJS, registerables} from 'chart.js';
import { 
    // Bar, 
    Doughnut, 
    Line
} from 'react-chartjs-2';
  
ChartJS.register(...registerables);
  
const CustomCharts = (props)=>{
    var type = 'bar';
    var myOptions = {
      maintainAspectRatio: true,
      responsive: true,
      plugins: {
        title: "",
        legend: {
          position: 'top',
        },
      },
    };
    
    var usefullData = {
        title: "",
        labels: [],
        datasets: []
    };
    if (props) {
        if (props.data) {
            if (Object.keys(props.data).length > 0) {
                usefullData = props.data;
                myOptions.plugins.title = props.data.title;
            }
        };
        if (props.type) {
            type = props.type;
        }
    }

    console.log(type, usefullData);
  
    return (
        <>
         {
            (
                props.type === 'doughut'
            )?(
                <Doughnut options={myOptions} data={usefullData} />
            ):(
                // <Bar data={usefullData} />
                <Line options={myOptions} data={usefullData} />
            )
        }
        </>
    );
  }
  export default CustomCharts;