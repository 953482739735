import React from "react";
import { useHistory } from 'react-router-dom';

import { GET_ENUMS } from "../../const/router";
import useGetPopupState from "./hooks/useGetPopupState";

// import Notifications from "./Popups/Notifications";
import WatchPreview from "./Popups/WatchPreview";
import Generate from "./Popups/Generate";
import Verify from "./Popups/Verify";
import Current from "./Popups/Current";
import PrintVouchers from "./Popups/PrintVouchers";
import UpdateVideo from "./Popups/UpdateVideo";
import CreateMovie from "./Popups/CreateMovie";
import AddCategory from "./Popups/AddCategory";
import UpdateProfile from "./Popups/UpdateProfile";
import ShowUsers from "./Popups/ShowUsers";
import SendMessage from "./Popups/SendMessage";


const popups = {
  // [GET_ENUMS.popup.notification]: Notifications,
  [GET_ENUMS.popup.watchPreview]: WatchPreview,
  [GET_ENUMS.popup.generateVoucher]: Generate,
  [GET_ENUMS.popup.verifyVoucher]: Verify,
  [GET_ENUMS.popup.currentVoucher]: Current,
  [GET_ENUMS.popup.printVouchers]: PrintVouchers,
  [GET_ENUMS.popup.updateVideo]: UpdateVideo,
  [GET_ENUMS.popup.createMovie]: CreateMovie,
  [GET_ENUMS.popup.addCategory]: AddCategory,
  [GET_ENUMS.popup.updateProfile]: UpdateProfile,
  [GET_ENUMS.popup.showUsers]: ShowUsers,
  [GET_ENUMS.popup.sendMessage]: SendMessage,
};

const GetParameterPopups = (props) => {
  const history = useHistory();
  const { mountedPopup, isOpened } = useGetPopupState();
  const Component = popups[mountedPopup];

  const backPopup = ()=>{
    history.goBack();
  }

  if (!Component) {
    return null;
  }

  return(
    <Component isOpened={isOpened} close={backPopup} isAuthed={props.isAuthed} dynamicProps={props.dynamicProps} />
  );
};

export default GetParameterPopups;
