import React, {Component} from 'react';
import axios from 'axios';


import WatchLaterBackground from './WatchLater/WatchLaterBackground';
import MovieListing from './Movies/MovieHolder/MovieListing';
import SeriesListing from './Series/SeriesHolder/SeriesListing';
import TvShowListing from './TvShows/TvShowHolder/TvShowListing';

import appDatabase from '../js/appDatabase.js';

class WatchLater extends Component {

    constructor(props){
        super(props);

        this.state = {
            categories: [],
            pulledContent: [],
            offlineContent: [],
        }
    }

    componentDidMount(){
        console.log(this.props);
        var offlineContent = [];
        var pastContent = sessionStorage.getItem('watchLaterData') ? JSON.parse(sessionStorage.getItem('watchLaterData')) : [];

        this.getOffline().then(res=>{
            return res;
        }).then(res2=>{
            this.setState({...this.state, offlineContent: res2});
            return res2;
        });

        var doRequest = ()=>{
            var headers = {Authorization: "Bearer "+this.props.userToken};
            axios.get("api/content?section=watch-later&category=all", { headers }).then(response=>{
                console.log(response)
                if (response.data) {
                    var respInfo = response.data;
                    if (respInfo.success) {
                        if (respInfo.data) {
                            sessionStorage.setItem('watchLaterData', JSON.stringify(respInfo.data));
                            this.setState({...this.state, pulledContent: respInfo.data.content}); 
                        };
                    } else {
                        console.log(respInfo.msg, respInfo.msg2);
                    };
                } else {
                    console.log("Eish");
                };
            }).catch(error=>{
                console.log(error);
            });
        };
        if (!(pastContent.length > 0)) {
            doRequest();
        } else {
            var fullContent = pastContent.content;
            offlineContent.forEach(element => {
                element.location = "offline";
                fullContent.push(element);
            });
            this.setState({
                ...this.state,
                pulledContent: fullContent,
            });
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        console.log(nextProps);
        console.log(nextState);
        if (this.props.location.search === nextProps.location.search) {
        // if (this.props.location.location.search === nextProps.location.location.search) {
            console.log(this.state);
            console.log(nextState);
            if (this.state === nextState) {
                return false;
            }
            return true;
        };
        return false;
    }

    getOffline = ()=>{
        return appDatabase("videos", 'offline').then(databaseObject=>{
            return databaseObject.appDbMethod('readonly').then((vidStore)=>{
                return vidStore.getAll();
            }).then(res=>{
                databaseObject.db.close();
                return res;
            });
        })
    }

    render () {
        document.title = 'Montywood | Watch Later';
        var pulledOffline = [];
        var pulledHotMovies = [];
        var pulledHotSeries = [];
        var pulledHotTvShows = [];
        const { pulledContent, offlineContent} = this.state;
        console.log(offlineContent);

        offlineContent.forEach(element => {
            element.location = "offline";
            pulledContent.push(element);
        });
        pulledContent.forEach(elem=>{
            var formatedObj = {};
            if (elem.location === 'offline') {
                if (elem.thumbnail && elem.video) {
                    var thumbnailSRC = URL.createObjectURL(elem.thumbnail);
                    var videoSRC = URL.createObjectURL(elem.video);
                    formatedObj = {...elem, video: videoSRC, thumbnail: thumbnailSRC, offline: true, currentOfflineID: elem.id};
                    pulledOffline.push(formatedObj);
                    // // setTimeout(() => {
                    //     URL.revokeObjectURL(videoSRC);
                    //     URL.revokeObjectURL(thumbnailSRC);
                    // // }, 1000);
                } else {
                    formatedObj = {...elem, video: elem.video, thumbnail: elem.thumbnail};
                    pulledOffline.push(formatedObj);
                }
            } else if (elem.type === 'movie') {
                pulledHotMovies.push(elem);
            } else if (elem.type === 'series') {
                pulledHotSeries.push(elem);
            } else if (elem.type === 'tvshow') {
                pulledHotTvShows.push(elem);
            };
        });
        console.log(pulledOffline)

        return (
            <>
            <WatchLaterBackground />
            <div className="moviesContainer watchLater" >
                <h3 id="newReleases">Offline Content</h3>
                <div className="centerNewMovies">
                    {
                        pulledOffline.length ?
                        pulledOffline.map(elem => {
                            if ((elem.type === "movie")||(elem.type === "movies")) {
                                return <MovieListing key={elem.id}
                                vid={elem.id} thumbnail={elem.thumbnail} video={elem.video} 
                                title={elem.title} description={elem.description} category={elem.category}
                                isAuthed={this.props.isAuthed} justPlay={this.props.justPlay}
                                isMultiple={elem.isMultiple}
                                offline={elem.offline}
                                currentOfflineID={elem.currentOfflineID}
                                />;
                            } else if (elem.type === "series") {
                                return <SeriesListing key={elem.id} 
                                vid={elem.id} thumbnail={elem.thumbnail} video={elem.video} 
                                title={elem.title} description={elem.description} category={elem.category}
                                isAuthed={this.props.isAuthed} justPlay={this.props.justPlay}
                                isMultiple={elem.isMultiple}
                                offline={elem.offline}
                                currentOfflineID={elem.currentOfflineID}
                                />;
                            } else if ((elem.type === "tvshow")||(elem.type === "tvshows")) {
                                return <TvShowListing key={elem.id}
                                vid={elem.id} thumbnail={elem.thumbnail} video={elem.video} 
                                title={elem.title} description={elem.description} category={elem.category}
                                isAuthed={this.props.isAuthed} justPlay={this.props.justPlay}
                                isMultiple={elem.isMultiple}
                                offline={elem.offline}
                                currentOfflineID={elem.currentOfflineID}
                                />;
                            } else {
                                return <MovieListing key={elem.id}
                                vid={elem.id} thumbnail={elem.thumbnail} video={elem.video} 
                                title={elem.title} description={elem.description} category={elem.category}
                                isAuthed={this.props.isAuthed} justPlay={this.props.justPlay}
                                isMultiple={elem.isMultiple}
                                offline={elem.offline}
                                currentOfflineID={elem.currentOfflineID}
                                />;
                            };
                        }) : <p className="noContentP">No offline content.</p>
                    }
                </div>
                <h3 id="newReleases">My Movies</h3>
                <div className="centerNewMovies">
                    {
                        pulledHotMovies.length ?
                        pulledHotMovies.map(elem => {
                            return <MovieListing key={elem.id}
                            vid={elem.id} thumbnail={elem.thumbnail} video={elem.video} 
                            title={elem.title} description={elem.description} category={elem.category}
                            isAuthed={this.props.isAuthed} justPlay={this.props.justPlay}
                            isMultiple={elem.isMultiple}
                            />;
                        }) : <p className="noContentP">No marked movies.</p>
                    }
                </div>
                <h3 id="newReleases">My Series</h3>
                <div className="centerNewMovies">
                    {
                        pulledHotSeries.length ?
                        pulledHotSeries.map(elem => {
                            return <SeriesListing key={elem.id} 
                            vid={elem.id} thumbnail={elem.thumbnail} video={elem.video} 
                            title={elem.title} description={elem.description} category={elem.category}
                            isAuthed={this.props.isAuthed} justPlay={this.props.justPlay}
                            isMultiple={elem.isMultiple}
                            />;
                        }) : <p className="noContentP">No Marked series yet.</p>
                    }
                </div>
                <h3 id="newReleases">My TV Shows</h3>
                <div className="centerNewMovies">
                    {
                        pulledHotTvShows.length ?
                        pulledHotTvShows.map(elem => {
                            return <TvShowListing key={elem.id}
                            vid={elem.id} thumbnail={elem.thumbnail} video={elem.video} 
                            title={elem.title} description={elem.description} category={elem.category}
                            isAuthed={this.props.isAuthed} justPlay={this.props.justPlay}
                            isMultiple={elem.isMultiple}
                            />;
                        }) : <p className="noContentP">No marked shows yet.</p>
                    }
                </div>
                <br></br>
                <br></br>
                <br></br>
            </div>
        </>
        )
    }
}
  
  export default WatchLater;