import React, {Component} from 'react';
import axios from 'axios';
import $ from 'jquery';
import { v4 as uuidv4 } from 'uuid';

import SeriesBackground from './Series/SeriesBackground';
import SeriesListing from './Series/SeriesHolder/SeriesListing';
import SeriesLoader from './Series/SeriesHolder/SeriesLoader';


class Series extends Component {

    constructor(props){
        super(props);

        this.state = {
            cover: "",
            categories: [],
            pulledContent: [
                {type: "series", ranID: uuidv4(), section: "new"}, {type: "series", ranID: uuidv4(), section: "new"}, {type: "series", ranID: uuidv4(), section: "new"}, {type: "series", ranID: uuidv4(), section: "new"},{type: "series", ranID: uuidv4(), section: "new"}, {type: "series", ranID: uuidv4(), section: "new"}, 
                {type: "series", ranID: uuidv4()}, {type: "series", ranID: uuidv4()}, {type: "series", ranID: uuidv4()}, {type: "series", ranID: uuidv4()},{type: "series", ranID: uuidv4()}, {type: "series", ranID: uuidv4()}, 
            ]
        };
    }

    componentDidMount(){
        var pastContent = sessionStorage.getItem('seriesContent') ? JSON.parse(sessionStorage.getItem('seriesContent')) : [];

        var doRequest = ()=>{
            var headers = {
                Authorization: "Bearer "+this.props.userToken
            };
            axios.get("api/content?section=series&category=all", { headers }).then(response=>{
                console.log(response)
                if (response.data) {
                    var respInfo = response.data;
                    if (respInfo.success) {
                        if (respInfo.data) {
                            setTimeout(()=>{
                                // sessionStorage.setItem('seriesData', JSON.stringify(respInfo.data));
                                this.setState({categories: respInfo.data.categories, cover: respInfo.data.cover, pulledContent: respInfo.data.content});
                            }, 1000) 
                        }
                    } else {
                        console.log(respInfo.msg, respInfo.msg2);
                        setTimeout(()=>{
                            this.setState({...this.state, cover: "", pulledContent: []}); 
                        }, 1000)
                    };
                } else {
                    console.log("Eish");
                    setTimeout(()=>{
                        this.setState({...this.state, cover: "", pulledContent: []}); 
                    }, 1000)
                };
            }).catch(error=>{
                console.log(error);
                setTimeout(()=>{
                    this.setState({...this.state, cover: "", pulledContent: []}); 
                }, 1000)
            })
        };
        if (window.location.search ===   "") {
            if (!(pastContent.length > 0)) {
                doRequest();
            } else {
                this.setState({
                    ...this.state,
                    cover: [],
                    pulledContent: pastContent,
                }); 
            }
        } else {
            this.setState({
                ...this.state,
                cover: [],
                pulledContent: pastContent,
            }); 
        }
    }

    componentWillUnmount() {
        // alert("xxx");
        console.log("Component unmounting")
    }

    shouldComponentUpdate(nextProps, nextState){
        console.log(nextProps);
        console.log(nextState);
        if (this.props.location.search === nextProps.location.search) {
        // if (this.props.location.location.search === nextProps.location.location.search) {
            console.log(this.state);
            if (this.state === nextState) {
                return false;
            }
            return true;
        };
        return false;
    }
    contentByCategory (category){
        axios.get("api/content?section=series&category="+category, {headers: {Authorization: "Bearer "+this.props.userToken}}).then(response=>{
            console.log(response)
            if (response.data) {
                var respInfo = response.data;
                if (respInfo.success) {
                    this.setState({category: respInfo.data.categories, cover: respInfo.data.cover, pulledContent: respInfo.data.content}); 
                } else {
                    console.log(respInfo.msg, respInfo.msg2);
                };
            } else {
                console.log("Eish");
            };
        }).catch(error=>{
            console.log(error);
        })
    }

    render () {
        document.title = 'Montywood | Series';
        $('meta[name=description]').attr('content', 'Latest Original Series from your daily convenience.');

        var {categories, cover, pulledContent} = this.state;

        var recommended = {};
        var pulledMoviesLatest = [];
        var pulledContetntOthers = [];

        pulledContent.forEach(elem=>{
            if (elem.section === 'cover') { recommended = elem;
            } else if (elem.section === 'new') { pulledMoviesLatest.push(elem);
            } else { pulledContetntOthers.push(elem); };
        });
        recommended.cover = cover;
        
        return (
            <>
            <SeriesBackground 
            vid={recommended.id}
            cover={recommended.cover} title={recommended.title} episodeTitle={recommended.episodeTitle}
            description={recommended.description}
            thumbnail={recommended.thumbnail} video={recommended.video}  
            isAuthed={this.props.isAuthed}
            isSubscribed={this.props.isSubscribed}
            categories={categories}  contentByCategory={this.contentByCategory} 
            justPlay={this.props.justPlay}
            promptLogin={this.props.promptLogin}
            />
            <div className="moviesContainer" >
                <h3 id="newReleases">New Releases</h3>
                <div className="centerNewMovies">
                    {
                        pulledMoviesLatest.length ?
                        pulledMoviesLatest.map(elem => {
                            if (elem.id) {
                                return <SeriesListing key={elem.id}
                                vid={elem.id} thumbnail={elem.thumbnail} video={elem.video} 
                                title={elem.title} episodeTitle={elem.episodeTitle}
                                description={elem.description} category={elem.category}
                                isAuthed={this.props.isAuthed} showPreview={this.props.showPreview}
                                isMultiple={elem.isMultiple}
                                />;
                            } else {
                                return <SeriesLoader key={elem.ranID}/>;
                            }
                        }) : <p className="noContentP">No new realeases yet.</p>
                    }
                </div>
                <h3 id="moreSeries">Current Series</h3>
                <div className="moreSeries">
                    {
                        pulledContetntOthers.length ?
                        pulledContetntOthers.map(elem => {
                            if (elem.id) {
                                return <SeriesListing key={elem.id}
                                vid={elem.id} thumbnail={elem.thumbnail} video={elem.video} 
                                title={elem.title} episodeTitle={elem.episodeTitle}
                                description={elem.description} category={elem.category}
                                isAuthed={this.props.isAuthed} showPreview={this.props.showPreview}
                                isMultiple={elem.isMultiple}
                                />;
                            } else {
                                return <SeriesLoader key={elem.ranID}/>;
                            }
                        }) : <p className="noContentP">No current shows yet.</p>
                    }
                </div>
                <br></br>
                <br></br>
                <br></br>
            </div>
        </>
        )
    }
}

export default Series;