import React, {Component} from 'react';
import $ from 'jquery';
import { v4 as uuidv4 } from 'uuid';


import Backdrop from '../../../../Backdrop';
import EpisodeLoader from "./EpisodeLoader";
import EpisodeLoaded from "./EpisodeLoaded";


import './index.css';
import axios from 'axios';
import Swal from 'sweetalert2';



class WatchPreview extends Component {
    constructor(props){
        super(props);

        this.state = {
            cid: "",
            dynamicProps: this.props.dynamicProps,
            videoInfo: {},
            seasons: [{label: "Loading...", value: "", ranID: uuidv4()}],
            episodes: [{ranID: uuidv4()}, {ranID: uuidv4()}, {ranID: uuidv4()}, {ranID: uuidv4()}, {ranID: uuidv4()}],
            selectedSeason: 1,
            selectedEpisode: 1,
        }
    }

    componentDidMount(){
        var dynamicProps = this.props.dynamicProps;
        var paramsString = window.location.search;
        const queryParams = new URLSearchParams(paramsString);
        const cid = queryParams.get("cid");

        setTimeout(() => {
            $('#videoPreview').addClass('showVideoPreview');
        }, 100);
        $('#videoPreview').css('animation', 'videoPreviewSlideInBottom 1000ms');
        $('#videoPreview').css('-webkit-animation', 'videoPreviewSlideInBottom 1000ms');
        $('#videoPreview').css('-ms-animation', 'videoPreviewSlideInBottom 1000ms');
        $('#videoPreview').css('-moz-animation', 'videoPreviewSlideInBottom 1000ms');
        $('#videoPreview').css('-o-animation', 'videoPreviewSlideInBottom 1000ms');


        const pullMoreInfo = (vid, seasonNum)=>{
            var headers = {
                Authorization: "Bearer "+this.props.dynamicProps.userToken
            };
            axios.get("api/content?vid="+vid+"&more="+seasonNum, { headers }).then(response=>{
                console.log(response)
                if (response.data) {
                    var respInfo = response.data;
                    if (respInfo.success) {
                        var respInfoData = respInfo.data;
                        if (respInfo.data) {
                            setTimeout(()=>{
                                var stateUpdate = {...this.state};
                                if (respInfoData.seasons) {
                                    var seasonsArray = [];
                                    for (let counter = 0; counter < respInfoData.seasons; counter++) {
                                        seasonsArray.push({label: counter+1, value: counter+1, ranID: uuidv4()});
                                    };
                                    stateUpdate.seasons = seasonsArray;
                                };
                                if (respInfoData.episodes) {
                                    stateUpdate.episodes = respInfoData.episodes;
                                    stateUpdate.selectedEpisode = respInfoData.episodes[0].id;
                                } else {
                                    stateUpdate.episodes = []
                                }
                                if (respInfoData.currentSeason) {
                                    stateUpdate.selectedSeason = respInfoData.currentSeason;
                                } else {
                                    stateUpdate.selectedSeason = []
                                };
                                this.setState(stateUpdate);
                            }, 1000)
                        }
                    } else {
                        console.log(respInfo.msg, respInfo.msg2);
                        setTimeout(()=>{
                            this.setState({...this.state, seasons: [], episodes: []});
                        }, 1000)
                    };
                } else {
                    console.log("Eish");
                    setTimeout(()=>{
                        this.setState({...this.state, seasons: [], episodes: []});
                    }, 1000)
                };
            }).catch(error=>{
                console.log(error);
                setTimeout(()=>{
                    this.setState({...this.state, seasons: [], episodes: []});
                }, 2500)
            });
        }
        
        const pullInfo = (cid)=>{
            var headers = {
                Authorization: "Bearer "+this.props.dynamicProps.userToken
            };
            axios.get("api/content?cid="+cid, { headers }).then(response=>{
                console.log(response)
                if (response.data) {
                    var respInfo = response.data;
                    if (respInfo.success) {
                        var respInfoData = respInfo.data;
                        if (respInfo.data) {
                            setTimeout(()=>{
                                this.setState({...this.state, dynamicProps: respInfoData});
                            }, 1000)
                        }
                    } else {
                        console.log(respInfo.msg, respInfo.msg2);
                    };
                }
            }).catch(error=>{
                Swal.fire({
                    icon: "question",
                    title: "Somethig went wrong",
                    text: error.message,
                    showCancelButton: true,
                    cancelButtonText: "Leave",
                    confirmButtonText: "Retry"
                }).then(res=>{
                    if (res.isConfirmed) {
                        pullInfo(cid);
                    } else {
                        window.history.back();
                    };
                });
            });
        }
        const chooseSeason = (e)=>{
            var chosen = e.target.value;
            this.setState({...this.state, selectedSeason: chosen});
            pullMoreInfo(dynamicProps.vid, chosen);
        };
        const chooseEpisode = (chosen)=>{
            this.setState({...this.state, selectedEpisode: chosen.episode});
    
            var videoInfoForward = {vid: chosen.id, title: chosen.title, episodeTitle: chosen.episodeTitle, 
                description: chosen.description, 
                thumbnail: chosen.thumbnail, video: chosen.video,
                startTime: chosen.startTime, partNumber: chosen.season, episode: chosen.episode, type: chosen.type
            };
            setTimeout(() => {
                dynamicProps.justPlay(videoInfoForward);
            }, 100);
        };

        if (dynamicProps.isMultiple) {
            pullMoreInfo(dynamicProps.vid, this.state.selectedSeason);
        }
        if (Object.keys(dynamicProps).length > 1) {
            if (!dynamicProps.vid) {
                pullInfo(cid);
            }
        } else {
            pullInfo(cid);
        };

        this.setState({...this.state, cid: cid,
            videoInfo: {...this.state, vid: dynamicProps.vid, title: dynamicProps.title, episodeTitle: dynamicProps.episodeTitle, description: dynamicProps.description, thumbnail: dynamicProps.thumbnail, video: dynamicProps.video, startTime: dynamicProps.startTime},
            chooseSeason: chooseSeason, chooseEpisode: chooseEpisode
        });
    }

    componentWillUnmount () {
        // clearTimeout(timeoutRef);
        var splitTime = 1000;
        $('#root').css('animation', 'afterPreview '+splitTime+'ms');
        
    }


    render () {
        document.title = 'Montywood | '+this.props.dynamicProps.title;
        $('meta[name=description]').attr('content', this.props.dynamicProps.description);

        var {videoInfo, seasons, episodes, selectedSeason, selectedEpisode, chooseSeason, chooseEpisode} = this.state;

        return (
            <>
            <Backdrop close={this.props.close} className="previewBackground" />
             <div id="videoPreview" className="videoPreview">
                 <div className="coverSection" >
                     <div className="thumbnail" onClick={()=>{this.props.dynamicProps.justPlay(videoInfo)}}>
                         <img src={this.props.dynamicProps.thumbnail} alt={this.props.dynamicProps.title} />
                     </div>
                     <h1>{this.props.dynamicProps.title}</h1>
                     <p>{this.props.dynamicProps.description}</p>
                 </div>
                 {
                     (this.props.dynamicProps.isMultiple) ? (
                        <div className="videoListPart">
                            <div className="chooseSeason">
                                <label htmlFor="chooseSeason">{this.props.dynamicProps.isMultiple}: </label>
                                <select id="chooseSeason" onChange={chooseSeason}>
                                    {
                                        seasons.map(element => {
                                            if (parseInt(element.value) === parseInt(selectedSeason)) { 
                                                return <option className="selectOptions" value={element.value} key={element.ranID} selected>{element.label}</option>
                                            } else { 
                                                return <option className="selectOptions" value={element.value} key={element.ranID}>{element.label}</option>
                                            };
                                        })
                                    }
                                </select>
                             </div>
                             <div className="episodes">
                                {
                                    episodes.map(element => {
                                        if (element.id) {
                                            if (element.id === parseInt(selectedEpisode)) {
                                                return <EpisodeLoaded
                                                className="theEpisode active"
                                                id={element.id}
                                                title={element.title}
                                                episodeTitle={element.episodeTitle}
                                                description={element.description}
                                                thumbnail={element.thumbnail}
                                                duration={element.duration}
                                                size={element.size}
                                                episode={element.episode}
                                                partNumber={element.season}
                                                type={element.type}

                                                onClick={()=>{chooseEpisode(element)}}
                                                />;
                                            } else {
                                                return <EpisodeLoaded
                                                className="theEpisode"
                                                id={element.id}
                                                title={element.title}
                                                episodeTitle={element.episodeTitle}
                                                description={element.description}
                                                thumbnail={element.thumbnail}
                                                duration={element.duration}
                                                size={element.size}
                                                episode={element.episode}
                                                partNumber={element.season}
                                                type={element.type}

                                                onClick={()=>{chooseEpisode(element)}}
                                                />;
                                            };
                                        } else {
                                            return <EpisodeLoader key={element.ranID}/>;
                                        }
                                    })
                                }
                            </div>
                        </div>
                    ) : ""
                }
            </div>
            </>
        )
    }
}

export default WatchPreview;