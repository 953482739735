import React, {Component} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

import Backdrop from '../../../../Backdrop';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import User from './User';
import Button from '@material-ui/core/Button';


import './index.css';


class ShowUsers extends Component {

    constructor(props){
        super(props);

        this.state = {
            pulledUsers: [],
            lastNumberOfUsers: 0,
            fetchCycle: 0,
            dateInWords: "",
            loadingText: "Loading users...",
            dateRangeObject: {0: "For all time", 1: "From Today", 7: "From the Last 7 days", 30: "Form the Last 30 days", 365: "From the last 365 days"},
            audienceLabel: "Search users", 
            people: [],

        };
    }

    componentDidMount(){
        this.getUsers(this.props.dynamicProps.dateRange, 0);
        
    }

    getUsers (dateRange, lastSet) {
        var filter = "";
        // if (lastSet && dateRange) {
            filter = "&lastId="+lastSet;
            filter += "&dateRange="+dateRange;
        // } else {
        //     filter = "&lastId=0";
        //     filter += "&dateRange=0";
        // };
        var headers = { Authorization: "Bearer "+this.props.dynamicProps.userToken }
        axios.get("../api/dashboard/users?action=getAllUsers"+filter, {headers}).then(response=>{
            console.log(response)
            if (response.data) {
                var respInfo = response.data;
                if (respInfo.success) {
                    if (respInfo.data) {
                        var getFetchCycle = this.state.fetchCycle+1;
                        var lastSize = getFetchCycle*10 + 1;
                        console.log(getFetchCycle);
                        console.log(lastSize);
                        console.log(this.state.pulledUsers);
                        if (respInfo.data.length > 0) {
                            var lastUsers = this.state.pulledUsers;
                            var newUsers = [];
                            lastUsers.forEach(user=>{
                                if (user.id) {
                                    newUsers.push(user);
                                }                                 
                            });
                            (respInfo.data).forEach(user => {
                                newUsers.push(user);
                            });
                            if (newUsers.length >= lastSize-1) {
                                newUsers.push({id: null, firstname: "Nothing"});
                            };
                            
                            this.setState({...this.state, pulledUsers: newUsers, fetchCycle: getFetchCycle, lastNumberOfUsers: lastSize}); 
                        } else {
                            this.setState({...this.state, pulledUsers: respInfo.data, lastNumberOfUsers: lastSize}); 
                        }
                    } else {
                        this.setState({...this.state, pulledUsers: [], loadingText: "No users found."}); 
                    }
                } else {
                    this.setState({...this.state, pulledUsers: [], loadingText: respInfo.msg+"; <br>"+respInfo.msg2}); 
                };
            } else {
                this.setState({...this.state, pulledUsers: [], loadingText: "Something happened, please try again."}); 
            };
        }).catch(error=>{
            this.setState({...this.state, pulledUsers: [], loadingText: "Request error, please try again <br>"+error.message}); 
        })
    }

    searchPeople = (e)=>{
        var theValue = e.target.value;
        var fecthedPeople =[];
        var headers = {Authorization: "Bearer "+this.props.dynamicProps.userToken};
        axios.get("../../api/users?type=full&keyWords="+theValue, {headers}).then(response=>{
            console.log(response);
            if (response.data) {
                var respInfo = response.data;
                if (respInfo.success) {
                    if (respInfo.data) {
                        this.setState({...this.state, people: respInfo.data});
                    } else {
                        this.setState({...this.state, people: fecthedPeople});
                    }
                } else {
                    this.setState({...this.state, people: fecthedPeople});
                }
            } else {
                this.setState({...this.state, people: fecthedPeople});
            };
        }).catch(err=>{
            console.log(err.message);
            this.setState({...this.state, people: fecthedPeople});
        });
    }

    updateUser () {
        var newRole = this.state.newRole;
        var theUser = this.state.newRoleUser;
        var icon = "";
        var title = "";
        var text = "";
        var showCancelButton = false;
        var cancelButtonText = false;
        var confirmButtonText = "";
        Swal.fire({
            icon: "info",
            title: "Submitting...",
            allowOutsideClick: false,
            showConfirmButton: false,
        });
        var headers = { Authorization: "Bearer "+this.props.dynamicProps.userToken }
        axios.get("../api/dashboard/users?action=updateRole&userId="+theUser+"&role="+newRole, {headers}).then(result=>{
            console.log(result);
            return result.data;
        }).then((response)=>{
            console.log(response);
            if (response.success) {
                icon = "success";
                title = response.msg;
                text = response.msg2;
                confirmButtonText = "Done";
            } else {
                icon = "error";
                title = response.msg;
                text = response.msg2;
                showCancelButton = true;
                cancelButtonText = "Back";
                confirmButtonText = "Retry";
            };
            Swal.fire({
                icon: icon,
                title: title,
                text: text,
                allowEscapeKey: true,
                showCloseButton: true,
                showCancelButton: showCancelButton,
                confirmButtonText: confirmButtonText,
                cancelButtonText: cancelButtonText,
            }).then((res)=>{
                if (res.isConfirmed) {
                    if (showCancelButton) {
                        this.updateUser();
                    }
                }
            });
        }).catch(err=>{
            Swal.fire({
                icon: "error",
                title: "error.",
                text: err.message,
                allowEscapeKey: true,
                showCloseButton: true,
                showCancelButton: true,
                confirmButtonText: "Retry",
                cancelButtonText: "Cancel",
            }).then((res)=>{
                if (res.isConfirmed) {
                    this.updateUser();
                };
            });
        })
    }

    render () {
        var pulledUsersArray = [];
        var lastID = 0;
        var count = 0;
        var { pulledUsers, lastNumberOfUsers, dateInWords, loadingText, dateRangeObject, people, audienceLabel} = this.state;
        
        if (people.length > 0) {
            people.forEach(elem=>{
                pulledUsersArray.push(elem);
                if (elem.id) {
                    lastID = elem.id;
                };
            });
        } else {
            pulledUsers.forEach(elem=>{
                pulledUsersArray.push(elem);
                if (elem.id) {
                    lastID = elem.id;
                };
            });
        }
        if (pulledUsersArray.length < 1) {
            loadingText = "No users found.";
        };
        dateInWords = dateRangeObject[this.props.dynamicProps.dateRange];

        const actOnUser = (opts)=>{
            console.log(opts);
            Swal.fire({
                icon: "info",
                title: "User Details",
                html: '<div class="moreUserInfo"><h3>'+opts.firstname+' '+opts.lastname+'</h3><br><h3>Role: '+opts.details.roleName+'</h3></div>',
                allowOutsideClick: false,
                allowEscapeKey: true,
                showCloseButton: true,
                showCancelButton: true,
                // showDenyButton: true,
                confirmButtonText: "Change Role",
                // denyButtonText: "Role",
                cancelButtonText: "Close",
            }).then(res=>{
                if (res.isConfirmed) {
                    Swal.fire({
                        icon: "info",
                        title: "User role",
                        html: '<div class="moreUserInfo"><h3>'+opts.firstname+' '+opts.lastname+'</h3><br><label>Role</label><select id="updateRole"><option value="user">User</option><option value="mainAdmin">Main Admin</option><option value="contentAdmin">Content Manager</option><option value="marketingAdmin">Marketing & Communications</option></select></div>',
                        allowOutsideClick: false,
                        allowEscapeKey: true,
                        showCloseButton: true,
                        showCancelButton: true,
                        confirmButtonText: "Confirm update",
                        cancelButtonText: "Cancel",
                    }).then(res=>{
                        if (res.isConfirmed) {
                            this.updateUser();
                        } else {
                            actOnUser(opts);
                        }
                    });
                    const updateRole = document.getElementById("updateRole");
                    updateRole.addEventListener('change', (e)=>{
                        console.log(e);
                        console.log(e.target);
                        var roleVal = e.target.value;
                        console.log(roleVal);
                        this.setState({...this.state, newRole: roleVal, newRoleUser: opts.details.id});
        
                    })
                };
            });
        };
        const fetchMore = ()=>{
            this.getUsers(this.props.dynamicProps.dateRange, lastID);
        };

        return (
            <>
                <Backdrop close={this.props.close} className="backdropVouchers" />
                <div className="popupCurrent">
                    <h1>Users: {dateInWords}</h1>
                    <div className="vouchersList">
                        <div className="searchUsesInputDiv" >
                            <TextField className='searchUsesInput' label={audienceLabel} 
                            onChange={this.searchPeople} variant="outlined" />
                        </div>
                        {
                            (pulledUsersArray.length) ? (
                                pulledUsersArray.map(elem => {
                                    count++;
                                    if (count < lastNumberOfUsers) {
                                        return <User uid={elem.id} firstname={elem.firstname}
                                        lastname={elem.lastname} email={elem.email} phone={elem.phone}
                                        subscription={elem.subscription} details={elem} actOnUser={actOnUser}
                                        />
                                    } else {
                                        return <Button className="materialButton" variant="contained" onClick={fetchMore}>Fetch More</Button>
                                    }
                                })
                            ): (<p className="noUsers">{loadingText}</p>)
                        }
                    </div>
                </div>
            </>
        );
    }
}
  
export default ShowUsers;