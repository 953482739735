import React, {Component} from 'react';
import axios from 'axios';
import Swal from "sweetalert2";  

import Backdrop from '../../../../Backdrop';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
// import { ThumbUpTwoTone } from '@material-ui/icons';

// import {DropzoneArea} from 'material-ui-dropzone';


import './index.css';

class UpdateVideo extends Component {
    constructor(props){
        super(props);

        this.state = {
            type: "",
            contentProduction: {label: "", value: ""},
            audienceLabel: "Search person", 
            audienceShow: {required: false, display: "none"},
            // people: [{id: 3, name: "CC VV"}],
            people: [],
        };
    }

    componentDidMount(){
        console.log(this.props.dynamicProps);

        const handleTypeChange = (e) => {
            var audienceShow = {required: "false", display: "none"};
            var theValue = e.target.value;
            if ((theValue === "sendToOne")||(theValue === "sendToAll")) {
                if (theValue === "sendToOne") {
                    audienceShow = {required: "true", display: "inline-block"} 

                } else {
                    audienceShow = {required: false, display: "none"}                    
                };
            } else {
                audienceShow = {required: true, display: "inline-block"} 
            };
            this.setState({...this.state, type: theValue, audienceShow: audienceShow});
        };

        this.setState({...this.state,
            handleTypeChange: handleTypeChange,
        });

    }

    searchPeople = (e)=>{
        var theValue = e.target.value;
        var fecthedPeople =[];
        var headers = {Authorization: "Bearer "+this.props.dynamicProps.userToken};
        axios.get("../../api/users?keyWords="+theValue, {headers}).then(response=>{
            console.log(response);
            if (response.data) {
                var respInfo = response.data;
                if (respInfo.success) {
                    if (respInfo.data) {
                        this.setState({...this.state, people: respInfo.data});
                    } else {
                        this.setState({...this.state, people: fecthedPeople});
                    }
                } else {
                    this.setState({...this.state, people: fecthedPeople});
                }
            } else {
                this.setState({...this.state, people: fecthedPeople});
            };
        }).catch(err=>{
            console.log(err.message);
            this.setState({...this.state, people: fecthedPeople});
        });
    }
    captureSeries = (event, values) => {
        if (event) {
            if (values) {
                this.setState({...this.state, contentProduction: {label: values.label, value: values.id}});
            } else {
                // this.setState({...this.state, contentProduction: this.props.dynamicProps.onMovie});
            }
        }
    }
    
    handleUploadProgress = (elem, e)=>{
        var perecentUploadedObj  = {label: "Preparing upload", value: 0};
        if (e.lengthComputable) {
            var perecentUploaded = Math.floor((e.loaded/e.total)*100);
            perecentUploadedObj.value = perecentUploaded;
            if (perecentUploaded < 100) {
                perecentUploadedObj.label = perecentUploaded+"% Uploaded...";
            } else {
                perecentUploadedObj.label = perecentUploaded+"% Complete, awaiting result.";
            };
        };
        document.getElementById("updateProgressText").innerHTML = perecentUploadedObj.label;
        elem.value = perecentUploadedObj.value;
    }
    makeUpload = (e)=>{
        e.preventDefault();
        e.stopPropagation();
        const thisForm = e.target;

        var theHTML = '<div class="uploadProgressContainer"><span id="updateProgressText"></span><progress id="updateProgressBar" class="updateProgressBar" value="0" max="100"> 0% </progress></div>';
        Swal.fire({
            icon: "info",
            title: "Submitting...",
            html: theHTML,
            allowOutsideClick: false,
            showConfirmButton: false,
        });
        const uploadProgressBar = document.getElementById("updateProgressBar");
        var formData = new FormData(thisForm);
        // console.log(Object.fromEntries(formData.entries()));
        formData.append("userID", this.state.contentProduction.value);
        // console.log(Object.fromEntries(formData.entries()));
        var headers = { Authorization: "Bearer "+this.props.dynamicProps.userToken, "Content-Type": "multipart/form-data"};
        const config = {
            onUploadProgress: (progressEvent) => { 
               this.handleUploadProgress(uploadProgressBar, progressEvent);
            },
            headers: headers
        };

        axios.post("../../api/dashboard/communications?action=sms", formData, config).then(response=>{
            console.log(response)
            if (response.data) {
               var respInfo = response.data;
                if (respInfo.success) {
                    Swal.fire({
                        icon: "success",
                        title: respInfo.msg,
                        text: respInfo.msg2,
                        allowOutsideClick: false,
                        confirmButtonColor: "var(--main-gold-color)"
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: respInfo.msg,
                        text: respInfo.msg2,
                        allowOutsideClick: false,
                        confirmButtonColor: "var(--main-dark-color)"
                    });
                };
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Request failed",
                    text: "Reuest failed",
                    allowOutsideClick: false,
                    confirmButtonColor: "var(--main-dark-color)"
                });
            };
        }).catch(error=>{
            Swal.fire({
                icon: "error",
                title: "Update failed",
                text: error.message,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonText: "Retry",
                confirmButtonColor: "var(--main-dark-color)"
            }).then(res=>{
                if (res.isConfirmed) {
                    // this.makeUpload(e);
                }
            });
        });
    };
    
    render () {
        var {
            type, 
            audienceLabel, audienceShow, handleTypeChange, people, 
            // contentProduction,
        } = this.state;

        const types = [
            { label: "Choose audience", value: "" },
            { label: "One person", value: "sendToOne" },
            { label: "Everyone", value: "sendToAll" }
        ];

        return (
            <>
                <Backdrop close={this.props.close} className="updateFormPopupBackground" />
                <div className="updateFormPopup" >
                    <h1>Send Communications</h1>
                    <form onSubmit={this.makeUpload}>
                        <div className="uploadInput">
                            <TextField className="materialField" name="action" 
                            label="Choose Audience" 
                            variant="outlined" 
                            onChange={handleTypeChange}
                            defaultValue={type}
                            select required>
                                {types.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <br/>
                        <div className="uploadInput" style={{display: audienceShow.display}}>
                            <Autocomplete
                            options={people}
                            getOptionLabel={(option) =>  option.name}
                            onChange={this.captureSeries}
                            // defaultValue={contentProduction}
                            renderInput={(params) => <TextField {...params} name="userID" label={audienceLabel} 
                            onChange={this.searchPeople} variant="outlined" required={audienceShow.required}/>}
                            />
                        </div>
                        <div className="uploadInput uploadDescription">
                            <TextField className="materialField" name="message" label="Message..."
                            variant="outlined" multiline maxRows="5"
                            defaultValue={this.props.dynamicProps.description}
                            required/>
                        </div>
                        <div className="uploadInput uploadSubmit">
                            <Button className="materialButton" variant="contained" type="submit" 
                            startIcon={<CloudUploadIcon />}
                            >Send message</Button>
                        </div>
                    </form>
                </div>
            </>
        );
    }
}
    

  export default UpdateVideo;