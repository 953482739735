import React, {Component} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

import Backdrop from '../../../../Backdrop';
import Voucher from './Voucher';

import './index.css';


class CurrrentVouchers extends Component {

    constructor(props){
        super(props);

        this.state = {
            pulledVouchers: []
        };
    }

    componentDidMount(){
        var headers = { Authorization: "Bearer "+this.props.dynamicProps.userToken }
        axios.get("../api/dashboard/vouchers?action=getAll", {headers}).then(response=>{
            console.log(response)
            if (response.data) {
                var respInfo = response.data;
                if (respInfo.success) {
                    if (respInfo.data) {
                        this.setState({pulledVouchers: respInfo.data}); 
                    } else {

                    }
                } else {
                    console.log(respInfo.msg, respInfo.msg2);
                };
            } else {
                console.log("Eish")
            };
        }).catch(error=>{
            console.log(error);
        })
    }

    render () {
        var pulledVouchersArray = [];
        var { pulledVouchers} = this.state;
        pulledVouchers.forEach(elem=>{
            pulledVouchersArray.push(elem);
        });

        const actOnVoucher = (opts)=>{
            console.log(opts);
            Swal.fire({
                icon: "question",
                title: "Choose your action",
                text: "For voucher: "+opts.voucherNumber,
                allowOutsideClick: false,
                allowEscapeKey: true,
            }).then(res=>{
                if (res.isConfirmed) {

                } else {

                };
            })
        };

        return (
            <>
                <Backdrop close={this.props.close} className="backdropVouchers" />
                <div className="popupCurrent">
                    <h1>Current Vouchers</h1>
                    <div className="vouchersList">
                        { pulledVouchersArray.length ? pulledVouchersArray.map(elem => {
                                return <Voucher state={elem.state} voucherNumber={elem.voucherNumber}
                                amount={elem.amount} duration={elem.duration} expiryDate={elem.expiryDate}
                                startingDate={elem.startingDate} actOnVoucher={actOnVoucher}
                                />
                            }): <p className="noVouchers">No Generated vouchers currently</p>
                        }
                    </div>
                </div>
            </>
        );
    }
}
  
export default CurrrentVouchers;