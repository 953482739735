import React, {Component} from 'react';
import axios from 'axios';

import TvShowListing from '../../../../components/TvShows/TvShowHolder/TvShowListing';

import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';


class TvShows extends Component {

    constructor(props){
        super(props);

        this.state = {
            pulledContent: []
        };
    }

    componentDidMount(){
        var headers = { Authorization: "Bearer "+this.props.userToken };
        axios.get("../../api/dashboard/content?section=tvshows", {headers}).then(response=>{
            console.log(response)
            if (response.data) {
                var respInfo = response.data;
                if (respInfo.success) {
                    if (respInfo.data) {
                        this.setState({...this.state, cover: respInfo.data.cover, pulledContent: respInfo.data.content}); 
                    }
                } else {
                    console.log(respInfo.msg, respInfo.msg2);
                };
            } else {
                console.log("Eish")
            };
        }).catch(error=>{
            console.log(error.message);
        })
    }

    render () {
        var recommended = [];
        var pulledContetntOthers = [];
        var { cover, pulledContent} = this.state;

        pulledContent.forEach(elem=>{
            if (elem.section === 'cover') { recommended.push(elem);
            } else { pulledContetntOthers.push(elem); };
        });
        recommended.push({key: 1, id: 1, thumbnail: cover, title:  "TV Shows Cover"});
        
        return (
            <>
            <div className="dashMoviesContainer" >
                <div className="dashContentCover">
                    <h3 id="newReleases">TV Shows Cover</h3>
                    <label htmlFor="icon-button-file" className="icon-button-file">
                        <IconButton className="myIconBtn" color="primary" aria-label="upload picture" component="span">
                            <PhotoCamera />
                        </IconButton>
                    </label>
                    <input accept="image/*"  id="icon-button-file" className="icon-button-file" type="file" name="tvshows"
                        onChange={this.props.handleBackgroundUpload}
                    />
                    {
                        recommended.length ?
                        recommended.map(elem => {
                            return <div key={elem.key}>
                                <img src={"../../"+elem.thumbnail} alt={elem.title} />
                            </div>;
                        }) : <p className="noContentP">No set cover yet.</p>
                    }
                </div>
                <h3 id="moreSeries">Current Shows</h3>
                <div className="moreSeries">
                    {
                        pulledContetntOthers.length ?
                        pulledContetntOthers.map(elem => {
                            return <TvShowListing type="tvshow" state={elem.state} partNumber={elem.season} episode={elem.episode}
                            key={elem.id} vid={elem.id} title={elem.title} description={elem.description} onMovie={elem.onMovie}
                            thumbnail={"../../"+elem.thumbnail} video={elem.video}
                            category={elem.category.label} categoryObj={elem.category}
                            isAuthed={this.props.isAuthed} showPreview={this.props.chooseActions} />;
                        }) : <p className="noContentP">No current shows yet.</p>
                    }
                </div>
                <br></br>
                <br></br>
                <br></br>
            </div>
        </>
        )
    }
}

export default TvShows;