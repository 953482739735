import React, {Component} from 'react';
import axios from 'axios';
import Swal from "sweetalert2";  

import Backdrop from '../../../../Backdrop';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PhotoCamera from '@material-ui/icons/PhotoCamera';

import './index.css';

class UpdateProfile extends Component {
    constructor(props){
        super(props);

        this.state = {
            pulledProfile: {}
        };
    }

    componentDidMount(){
        var pulledProfile = {
            firstname: this.props.dynamicProps.firstname,
            lastname: this.props.dynamicProps.lastname,
        };

        var profilePicPreview = document.getElementById("uploadPreviewSpace");
        var profilePicPreviewImg = document.getElementById("uploadPreviewSpaceImg");

        const captureProfilePic = (e)=>{
            var elementUpload = e.target;
            console.log(elementUpload);
    
            if (elementUpload.files) {
                console.log(elementUpload.files);
                if (elementUpload.files[0]) {
                    var fileURL = URL.createObjectURL(elementUpload.files[0]);
                    profilePicPreviewImg.src = fileURL;
                    setTimeout(() => {
                        URL.revokeObjectURL(fileURL);
                    }, 2000);
                    profilePicPreview.style.display = "block";
                } else {
                    profilePicPreview.style.display = "none";
                }
            } else {
                profilePicPreview.style.display = "none";
            }
        }

        const pullOnlineProfile = ()=>{
            var headers = {Authorization: "Bearer "+this.props.dynamicProps.userToken};
            axios.get("../../api/user?profile=true&purpose=update", {headers}).then(response=>{
                console.log(response);
                if (response.data) {
                    var respInfo = response.data;
                    if (respInfo.success) {
                        this.setState({...this.state, pulledProfile: respInfo.data});
                    }
                }
            }).catch(err=>{
                Swal.fire({
                    icon: "error",
                    title: "Couldn't fetch old profile",
                    html: "Couldn't fetch the old profile online<br/><br/>"+err.message,
                    showCancelButton: true,
                    confirmButtonText: "Retry",
                }).then(res=>{
                    if (res.isConfirmed) {
                        pullOnlineProfile();
                    }
                })
            });
        };


        this.setState({
            pulledProfile: pulledProfile, uploadedPic: this.props.dynamicProps.profilePic, captureProfilePic: captureProfilePic});
        pullOnlineProfile();
    }
    
    render () {
        var {pulledProfile, uploadedPic, captureProfilePic} = this.state;

        return (
            <>
                <Backdrop close={this.props.close} className="profileUpdatePopupBackground" />
                <div className="profileUpdateFormPopup" >
                    <h1>Updating profile</h1>
                    <form onSubmit={this.props.dynamicProps.updateProfile}>
                        <div className="profileUploadInput thePic">
                            
                           <Button className="materialField" variant="text" component="label"
                           startIcon={<PhotoCamera />}>
                               Profile Picture
                                <input name="profilePic" className="profilePicInput" type="file" accept="image/*" onChange={captureProfilePic}  />
                            </Button>
                            <div id="uploadPreviewSpace" className="uploadPreviewSpace">
                                <img id="uploadPreviewSpaceImg" src={uploadedPic} alt="Profile Pic"/>
                            </div>
                        </div>
                        <br/>
                        <div className="profileUploadInput">
                            <TextField className="materialField" name="firstname" label="Firstname"
                            type="number" variant="outlined"
                            defaultValue={pulledProfile.firstname}
                            />
                        </div>
                        <div className="uploadInput">
                            <TextField className="materialField" name="lastname" label="Lastname"
                            type="number" variant="outlined"
                            defaultValue={pulledProfile.lastname}
                            />
                        </div>
                        <div className="uploadInput">
                            <TextField className="materialField" name="phone" label="Phone number."
                            variant="outlined"
                            defaultValue={pulledProfile.phone}
                            required/>
                        </div>
                        <div className="uploadInput">
                            <TextField className="materialField" name="email" label="Email address"
                            variant="outlined"
                            defaultValue={pulledProfile.email}
                            required/>
                        </div>
                        <div className="uploadInput uploadSubmit">
                            <Button className="materialButton" variant="contained" type="submit" 
                            startIcon={<CloudUploadIcon />}
                            >Submit changes</Button>
                        </div>
                    </form>
                </div>
            </>
        );
    }
}
    

  export default UpdateProfile;