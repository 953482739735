import React, {useState} from 'react';

// import TextField from '@material-ui/core/TextField';
// import Button from '@material-ui/core/Button';

import './index.css';

function Checkout(props) {
    // console.log(props);


    const dailySubscription = {value: 5, title: "Full day"};
    const monthlySubsciption = {value: 50, title: "30 days"};
    const [choice, setChoice] = useState(dailySubscription); 
    
    const chooseSubscriptionOption = (e)=>{
        var thisBtn = e.target;
        var choiceOption = JSON.parse(thisBtn.getAttribute("data-choice"));
        console.log(props.stateObj);
        props.keepHistory(props.stateObj);

        var fiveRandFeeBtn = document.getElementById("fiveRandFeeBtn");
        var fiftyRandFeeBtn = document.getElementById("fiftyRandFeeBtn");
        if (choiceOption.value === 5) {
            fiveRandFeeBtn.classList.add("active");
            fiftyRandFeeBtn.classList.remove("active");
        } else {
            fiveRandFeeBtn.classList.remove("active");
            fiftyRandFeeBtn.classList.add("active");
        };
        setChoice(choiceOption);
    }
    
    return (
        <div className="paymentSteps">
            <br/>
            <div className="packageOptions">
                <div id="fiveRandFeeBtn" data-choice={JSON.stringify(dailySubscription)} className="active" onClick={ chooseSubscriptionOption } >   
                    <p>{dailySubscription.title}  at <span>R{dailySubscription.value}</span></p>
                </div>
                <div id="fiftyRandFeeBtn" data-choice={JSON.stringify(monthlySubsciption)}  onClick={ chooseSubscriptionOption }>
                    <p>{monthlySubsciption.title} at <span>R{monthlySubsciption.value}</span></p>
                </div>
            </div>
            <br></br><br/>
            <br/>
            <div className="applyVoucher">
                <input id="voucherField" type="text" placeholder="Enter your voucher" ref={props.voucherRef} />
                {/* <TextField id="voucherField" name="quantity" label="Enter your voucher" variant="outlined" ref={props.voucherRef}
                type="text" /> */}
                <button id="payVoucher" className="voucherState" data-choice={JSON.stringify(choice)} onClick={props.onsubmit} >Redeem Voucher</button>
                {/* <Button id="payVoucher" className="voucherState" data-choice={JSON.stringify(choice)} onClick={props.onsubmit} */}
                {/* variant="contained" type="submit">Redeem Voucher</Button> */}
            </div>
            <div className="otherMethods">
                <button id="payOther" data-choice={JSON.stringify(choice)}  onClick={props.onsubmit}>Pay with Other Methods</button>
                <p>E.g., Credit/Debit Card, EFT and More</p>
            </div>
        </div>
    );
  }
  
  export default Checkout;