import React from 'react';

import "./index.css";

function Notification(props) {
    return (
        <div className="notification" >
            <div className="profilePic">
                <img src={props.profilePic} alt="Profile" />
            </div>
            <div className="details">
                <div className="namesAndDescr">
                    <p>{props.names}</p><br/>
                    <p>{props.description}</p>
                </div>
                <div className="notifyTime">
                    <p>{props.time}</p>
                </div>
            </div>
        </div>
    );
  }
  
  export default Notification;