import React, {Component} from 'react';

import './index.css';
import Button from '@material-ui/core/Button';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ShareIcon from '@material-ui/icons/Share';
import FullscreenRoundedIcon from '@material-ui/icons/FullscreenRounded';
import FullscreenExitRoundedIcon from '@material-ui/icons/FullscreenExitRounded';



class VideoPlayer extends Component {

    constructor(props){
        super(props);

        this.state = {
            videoPlayer: {},
            handleSeek: {},
            fullScreen: false,
        }
    }

    componentDidMount(){
        var showDescription, showControls, flashPlayPuaseIcon, showLoader;
        var jumpBackTime = -10,jumpForwardTime = 10;
        const videoPlayerLoader = document.getElementById("videoPlayerLoader");
        const videoPlayer = document.getElementById("videoPlayer");
        const playerVideo = document.getElementById("playerVideo");
        const videoCover = document.getElementById("videoCover");
        const jumpBackwards = document.getElementById("jumpBackwards");
        const jumpForwards = document.getElementById("jumpForwards");
        const playPauseBtn = document.getElementById("playPauseBtn");
        var seekSlider = document.getElementById("seekSlider");
        var currentTimeText = document.getElementById("currentTimeText");
        var durationTimeText = document.getElementById("durationTimeText");
        const playerSubtitles = document.getElementById("playerSubtitles");
        const playerSettings = document.getElementById("playerSettings");
        const playerFullscreen = document.getElementById("playerFullscreen");
        const playerDescription = document.getElementById("playerDescription");
        const playerControls = document.getElementById("playerControls");

        const handlePlayPause = ()=>{
            clearTimeout(showDescription);
            clearTimeout(showControls);
            clearTimeout(flashPlayPuaseIcon);
            playerControls.style.display = "block";
            if (playerVideo.paused) {
                playPauseBtn.classList.remove("pause");
                playPauseBtn.classList.add("play");
                playerVideo.play();
                playerDescription.style.display = "none";
                showControls = setTimeout(()=>{
                    playerControls.style.display = "none";
                }, 5000);
                // playerDescription.style.display = "none";
                flashPlayPuaseIcon = setTimeout(() => {
                    // playerDescription.style.display = "none";
                }, 500);
            } else {
                playPauseBtn.classList.remove("play");
                playPauseBtn.classList.add("pause");
                playerVideo.pause();
                showDescription = setTimeout(()=>{
                    playerDescription.style.display = "block";
                }, 15000);
                flashPlayPuaseIcon = setTimeout(() => {
                    
                }, 500);
            };
        }

        const controlsShowHide = ()=>{
            clearTimeout(showControls);
            playerControls.style.display = "block";
            if (!playerVideo.paused) {
                showControls = setTimeout(()=>{
                    playerControls.style.display = "none";
                }, 5000);
            };
        }

        const handleSeek = (theTime)=>{
            var seekTo = 0;
            if (seekSlider.value) {
                console.log("Seek bar has some value");
                if (parseFloat(seekSlider.value)) {
                    console.log("Seek value is parseable into a float/interger");
                    if (parseFloat(seekSlider.value) > 0) {
                        console.log("Seek value grater than 0");
                        seekTo = playerVideo.duration*(parseFloat(seekSlider.value)/100);
                    }
                }
            };
            console.log(theTime);
            console.log(seekSlider.value);
            console.log(seekTo);
            if (theTime) {
                if (Math.abs(theTime) === 10) {
                    playerVideo.currentTime = playerVideo.currentTime+theTime;
                } else {
                    playerVideo.currentTime = seekTo;
                }
            } else {
                playerVideo.currentTime = seekTo;
            }
        }
        const seekTimeupdate = ()=>{
            var newTime = playerVideo.currentTime*(100/playerVideo.duration);
            seekSlider.value = newTime;
            clearTimeout(showLoader);
            if (videoPlayerLoader.style.display === "none") {
                videoPlayerLoader.style.display = "none";
                showLoader = setTimeout(() => {
                    if (!playerVideo.paused) {
                        videoPlayerLoader.style.display = "block";
                    }
                }, 1300);
            } else {
                videoPlayerLoader.style.display = "none";
            }

            var currHrs = Math.floor(playerVideo.currentTime/(60*60));
            var currMins = Math.floor(playerVideo.currentTime/60 - currHrs*60);
            var currSecs = Math.floor(playerVideo.currentTime - currMins*60);
                var durHrs = Math.floor(playerVideo.currentTime/(60*60));
                var durMins = Math.floor(playerVideo.duration/60 - durHrs*60);
                var durSecs = Math.floor(playerVideo.duration - durMins*60);
            if (currMins < 10) {currMins = "0"+currMins}
            if (currSecs < 10) {currSecs = "0"+currSecs}
            if (durMins < 10) {durMins = "0"+durMins}
            if (durSecs < 10) {durSecs = "0"+durSecs}
            if (currHrs > 0) {
                currentTimeText.innerHTML = currHrs+":"+currMins+":"+currSecs;
            } else {
                currentTimeText.innerHTML = currMins+":"+currSecs;
            }
            if (durHrs > 0) {
                durationTimeText.innerHTML = durHrs+":"+durMins+":"+durSecs;
            } else {
                durationTimeText.innerHTML = durMins+":"+durSecs;   
            };

            if (playerVideo.ended) {
                playPauseBtn.classList.remove("play");
                playPauseBtn.classList.add("pause");
            }
        };
        const goOutFullscreen = ()=>{
            if(document.exitFullscreen)
                return document.exitFullscreen();
            else if(document.mozCancelFullScreen)
                return document.mozCancelFullScreen();
            else if(document.webkitExitFullscreen)
                return document.webkitExitFullscreen();
            else if(document.msExitFullscreen)
               return document.msExitFullscreen();
        };
        function goInFullscreen(element) {
            if(element.requestFullscreen)
                return element.requestFullscreen();
            else if(element.mozRequestFullScreen)
                return element.mozRequestFullScreen();
            else if(element.webkitRequestFullscreen)
                return element.webkitRequestFullscreen();
            else if(element.msRequestFullscreen)
                return element.msRequestFullscreen();
        }
        const handleFullscreen = ()=>{
            playerFullscreen.disabled = true;
            if (videoPlayer.classList.contains("fullscreen")) {
                goOutFullscreen().then(res=>{
                    setTimeout(() => {
                        videoPlayer.classList.remove("fullscreen");
                    }, 200);
                    playerFullscreen.disabled = false;
                    this.setState({fullScreen: false});
                });
            } else {
                goInFullscreen(videoPlayer).then(res=>{
                    setTimeout(() => {
                        videoPlayer.classList.add("fullscreen");
                    }, 200);
                    playerFullscreen.disabled = false;
                    this.setState({fullScreen: true});

                });
            }
        }
        const handleFullscreenChange = (e)=>{
            if (videoPlayer.classList.contains("fullscreen")) {
                videoPlayer.classList.remove("fullscreen");
                this.setState({fullScreen: false});
            }
        }
        const handleSettings = ()=>{

        }
        const handleSubTitles = ()=>{
            if (playerSubtitles.classList.contains("subtitlesOn")) {
                playerSubtitles.classList.remove("subtitlesOn");
            } else {
                playerSubtitles.classList.add("subtitlesOn");
            };
        };
        const midPlayDescription = ()=>{
            playPauseBtn.click();
        };
        document.addEventListener('fullscreenchange', handleFullscreenChange, false);
        document.addEventListener('webkitfullscreenchange', handleFullscreenChange, false);
        document.addEventListener('mozfullscreenchange', handleFullscreenChange, false);
        document.addEventListener('MSFullscreenChange', handleFullscreenChange, false);
        document.addEventListener('keyup', e=>{
            if (e.keyCode) {
                // alert(e.keyCode)
                if(e.keyCode === 32){//Space bar
                    e.preventDefault();
                    playPauseBtn.click();
                } else if (e.keyCode === 37) {//Left arrow
                    jumpBackwards.click();
                } else if (e.keyCode === 38) {//Up arrow (Volume)
                    // playPauseBtn.click();
                } else if (e.keyCode === 39) {//Right arrow
                    jumpForwards.click();
                } else if (e.keyCode === 40) {//Down arrows (Volume)
                    // playPauseBtn.click();
                } else if (e) {
    
                };
            } else {
                console.log(e.key);
            };
        }, false);
        playerSubtitles.addEventListener('click', handleSubTitles, false);
        playerSettings.addEventListener('click', handleSettings, false);
        playerFullscreen.addEventListener('click', handleFullscreen, false);
        playPauseBtn.addEventListener('click', handlePlayPause, false);

        // playerVideo.addEventListener('click', handlePlayPause, false);
        // playerVideo.addEventListener('dblclick', handleFullscreen, false);
        videoCover.addEventListener('click', handlePlayPause, false);
        videoCover.addEventListener('dblclick', handleFullscreen, false);

        jumpBackwards.addEventListener('click', ()=>{handleSeek(jumpBackTime)}, false);
        jumpForwards.addEventListener('click', ()=>{handleSeek(jumpForwardTime)}, false);
        seekSlider.addEventListener('change', ()=>{handleSeek()}, false);
        playerVideo.addEventListener('timeupdate', seekTimeupdate, false);
        playerDescription.addEventListener('click', midPlayDescription, false);    
        videoPlayer.addEventListener('mousemove', controlsShowHide, false);  
    }
    

    render () {
        var thePart;  
        var theEpisode;
        if (this.props.partNumber) {
            if (this.props.type === "movie") {
                thePart = <span>P{this.props.partNumber}</span>;
            } else {
                thePart = <span>S{this.props.partNumber} : E{this.props.episode}</span>;
            }
        };
        if (this.props.episodeTitle) {
            theEpisode = <span>{this.props.episodeTitle}</span>;
        };
        var saveBtnIcon = <CloudDownloadIcon />;
        var saveBtnText= "Save offline";
        if (this.props.hasOffline) {
            saveBtnIcon = <DeleteIcon />;
            saveBtnText= "Delete offline";
        }


        // console.log(this.props.videoSRC);
        // alert(this.props.videoSRC)
        return (
            <div id="videoPlayer" className="videoPlayer">
                <video id="playerVideo" className="playerVideo" src={this.props.videoSRC} type="video/mp4" controlsList="nodownload" autoPlay></video>
                <span id="videoCover"></span>
                <div id="videoPlayerLoader">
                    <div>
                        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    </div>
                </div>
                <div id="playerDescription" className="playerDescription" >
                    <p>Click anywhere to resume</p>
                    <div className="pausedOverlay">
                        <p>{this.props.title}</p>
                        <br/>
                        <p>{this.props.description}</p>
                    </div>
                </div>
                <div id="playerControls" className="playerControls">
                    <div className="playerProgress">
                        <div id="vidProgress" className="vidProgress"  max="100" >
                            <div className="progressBar" ></div>
                            <input id="seekSlider" className="seekSlider" value="0" type="range" step="0.1" min="0" max="100" onChange={()=>{}}/>
                        </div>
                        <div className="playerTime">
                            <span id="currentTimeText" >00:00</span> / <span id="durationTimeText">00:00:00</span>
                        </div>
                    </div>
                    <div id="playerBtns" className="playerBtns">
                        <div id="playPauseBtn" className="playPauseBtn play" ></div>
                        <div id="jumpBackwards" className="jumpBackwards" ></div>
                        <div id="jumpForwards" className="jumpForwards" ></div>
                        <div className="playerVideoTitle">
                            <span >{this.props.title}</span>
                            { (this.props.partNumber) ? (<span className="midDot">&bull;</span>):""}
                            {thePart}
                            { (this.props.episodeTitle) ? (<span className="midDot">&bull;</span>):""}
                            {theEpisode}
                        </div>

                        <div id="playerFullscreen" className="playerFullscreen" >
                            {(this.state.fullScreen)?(<FullscreenExitRoundedIcon className="theIcon" />):(<FullscreenRoundedIcon className="theIcon" />)}
                        </div>
                        <div id="playerSettings" className="playerSettings" ></div>
                        <div id="playerSubtitles" className="playerSubtitles"></div>
                    </div>
                </div>
                <br/><br/>
                <div className="playerOptions">
                    <Button className="saveVideo" variant="contained" onClick={this.props.saveOption} 
                        startIcon={saveBtnIcon}
                        >{saveBtnText}</Button>
                    <Button className="watchLater" variant="contained" onClick={this.props.watchLaterOption}
                        startIcon={<AccessTimeIcon />}
                        >Watch Later</Button>
                    <Button className="materialButton" variant="contained" onClick={this.props.shareOption}
                        startIcon={<ShareIcon />}
                        >Share</Button>
                </div>
            </div>
        )
    }
}

export default VideoPlayer;