import React, {Component} from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';


import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';


class MainContent extends Component {

    constructor(props){
        super(props);

        this.state = {
            cover: "", 
        };
    }

    componentDidMount(){
        var headers = { Authorization: "Bearer "+this.props.userToken };
        axios.get("../../api/dashboard/content?section=home", {headers}).then(response=>{
            console.log(response)
            if (response.data) {
                var respInfo = response.data;
                if (respInfo.success) {
                    if (respInfo.data) {
                        this.setState({...this.state, cover: respInfo.data.cover}); 
                    }
                } else {
                    console.log(respInfo.msg, respInfo.msg2);
                };
            };
        }).catch(error=>{
            console.log(error.message);
        });
    }

    render () {
        var background = [];
        var { cover } = this.state;
        background.push({key: 1, id: 1, thumbnail: cover, title: "Home cover"});



        return (
            <>
                <br/>
                <Button className="addCategory" variant="contained" type="submit" onClick={()=>{ this.props.addCategory() }} >Add Category </Button>
                <br/>
                <br/>
                <Button className="createMovie" variant="contained" type="submit" onClick={()=>{ this.props.createMovie() }} >Create a Movie/Series/TvShow </Button>
                
                <div className="dashContentCover homeCover">
                    <h3 id="newReleases">Home Cover </h3>
                    {
                        background.length ?
                        background.map(elem => {
                            return <div key={elem.key} className="theHomeCover">
                                <img src={"../../"+elem.thumbnail} alt={elem.title} />
                            </div>;
                        }) : <p className="noContentP">No set cover yet.</p>
                    }
                    <label htmlFor="icon-button-file" className="icon-button-file">
                        <IconButton className="myIconBtn" color="primary" aria-label="upload picture" component="span">
                            <PhotoCamera />
                        </IconButton>
                    </label>
                    <input accept="image/*"  id="icon-button-file" className="icon-button-file" type="file" name="home"
                        onChange={this.props.handleBackgroundUpload}
                    />
                </div>
            </>
        )
    }
}

export default MainContent;