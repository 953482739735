import React from 'react';
import { Link } from 'react-router-dom';


import userIcon from '../../../icons/montywood1.png';


function Forgot(props) {
    return (
        <>
            <h3>Forgot password</h3>
            <form id="register" className="loginform" method="POST" onSubmit={props.onsubmit}>
                <input type="hidden" name="type" value="forgotPassword" />
                <div className="inputCont">
                    <div>
                        <img src={userIcon} alt="User icon" />
                    </div>
                    <input type="text" name="username" placeholder="email" required />
                </div>
                <button>Submit</button>
                <p><Link to={"/login"} >Sign in.</Link></p>
            </form>
        </>
    );
}
  
export default Forgot;