import React from 'react';

import passwordIcon from '../../../icons/montywood1.png';



function ResetPassword(props) {
    return (
        <>
            <h3>Reset Password</h3>
            <form id="login" className="loginform" method="POST" onSubmit={props.onsubmit}>
                <input type="hidden" name="type" value="passReset" />
                <input type="hidden" name="username" value={props.username} />             
                <input type="hidden" name="ranKey" value={props.ranKey} />             
                <div className="inputCont">
                    <div>
                        <img src={passwordIcon} alt="Key icon" />
                    </div>
                    <input type="password" name="password" placeholder="Password" required />
                </div>
                <div className="inputCont">
                    <div>
                        <img src={passwordIcon} alt="Key icon" />
                    </div>
                    <input type="password" name="passwordRepeat" placeholder="Repeat password" required />
                </div>
                <br></br>
                <button>Submit</button>
                <br></br>
                <br></br>
            </form>
        </>
    );
}
  
export default ResetPassword;