import React, {useEffect, useState, useRef} from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import MovieListing from '../Movies/MovieHolder/MovieListing';
import SeriesListing from '../Series/SeriesHolder/SeriesListing';
import TvShowListing from '../TvShows/TvShowHolder/TvShowListing';
import MovieLoader from '../Movies/MovieHolder/MovieLoader';
import SeriesLoader from '../Series/SeriesHolder/SeriesLoader';
import TvShowLoader from '../TvShows/TvShowHolder/TvShowLoader';

import "./index.css";

import whiteIcon from '../../icons/montywood1.png';
import searchIcon from '../../icons/magnifying-glass(s).svg';
import notificationIcon from '../../icons/notification.svg';
import userIcon from '../../icons/user.svg';
import WhatsAppIcon from '../../icons/whatsapp-logo-variant.svg';

import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import FavoriteRoundedIcon from '@material-ui/icons/FavoriteRounded';
import VideoLibraryRoundedIcon from '@material-ui/icons/VideoLibraryRounded';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import { useCallback } from 'react';
// import { WhatsApp } from '@material-ui/icons';

function Header(props) {
    const location = useLocation();
    var searchTextRef = useRef();
    var currentPageInit = location.pathname.slice(1, location.pathname.length);
    const [searchResults, setSearchResults] = useState([]);
    const [currentPage, setCurrentPage] = useState(currentPageInit);

    
  
    var searchFunct = useCallback((myMode)=>{
        var searchTrigger;
        var myHeader = document.getElementById('header');
        clearTimeout(searchTrigger);
        var keyWords = "";
        
        const fowardRequest = (keyWords)=>{
            // console.log(keyWords);
            if ( (keyWords !== "")&&((keyWords.replace(/\s/g, '')).length > 0) ) {	
                var currentPage = "";
                console.log(location.pathname);
                if (location.pathname) {
                    currentPage = location.pathname.slice(1, location.pathname.length);
                };
                axios.get("api/content?search="+currentPage+"&q="+keyWords, {headers: {Authorization: "Bearer "+props.userToken}}).then(response=>{
                    console.log(response);
                    if (response.data) {
                        var respInfo = response.data;
                        if (respInfo.success) {
                            var searchRes = respInfo.data;
                            setSearchResults(searchRes);
                        } else {
                            console.log(respInfo.msg, respInfo.msg2);
                            myHeader.classList.remove("headerWhenSearching");
                            setSearchResults([]);
                        };
                    } else {
                        console.log("Eish");
                        myHeader.classList.remove("headerWhenSearching");
                        setSearchResults([]);
                    };
                }).catch(error=>{
                    console.log(error);
                    myHeader.classList.remove("headerWhenSearching");
                    setSearchResults([]);
                });
            } else {
                myHeader.classList.remove("headerWhenSearching");
                setSearchResults([]);
            };
        };
        // console.log(keyWords);
        if (keyWords !== searchTextRef.current.value) {
            keyWords  = searchTextRef.current.value;
            if (myMode) {
                if ( (keyWords !== "")&&((keyWords.replace(/\s/g, '')).length > 0) ) {	
                    myHeader.classList.add("headerWhenSearching");
                    setSearchResults([{type: "series", ranID: uuidv4()}, {type: "tvshow", ranID: uuidv4()}, {type: "series"}, {type: "tvshow", ranID: uuidv4()}]);
                };
                searchTrigger = setTimeout(() => {
                    fowardRequest(keyWords);
                }, 500);
            } else {
                fowardRequest(keyWords);
            };
        }
    }, [location.pathname, props.userToken])
    

    useEffect(() => {
        searchTextRef.current.onblur = (e)=>{
            if ( (searchTextRef.current.value !== "")&&((searchTextRef.current.value.replace(/\s/g, '')).length > 0) ) {	
                e.target.classList.add("withInput");
            } else {
                e.target.classList.remove("withInput");
            };
        };
        searchTextRef.current.oninput = (e)=>{
            e.target.focus();
            if ( (searchTextRef.current.value !== "")&&((searchTextRef.current.value.replace(/\s/g, '')).length > 0) ) {	
                e.target.classList.add("withInput");
            } else {
                e.target.classList.remove("withInput");
            };
            searchFunct(true);
        };
        return () => {
            // cleanup
            setSearchResults([]);
        }
    }, [searchFunct]);
    useEffect(() => {
        // alert(currentPage);
        if (currentPage !== location.pathname.slice(1, location.pathname.length)) {
            document.getElementById('header').classList.remove("headerWhenSearching");
            setSearchResults([]);
        };
        
        return () => {
            // cleanup
            // document.getElementById('header').classList.remove("headerWhenSearching");
            setCurrentPage(location.pathname.slice(1, location.pathname.length));
            // alert(currentPage);
        }
    }, [currentPage, location]);

    const triggerSearch = (e)=>{
        if (searchTextRef.current.classList.contains("withInput")) {
            if (e.target.id === "searchIcon") {
                searchFunct(false);
            }
        } else {
            searchTextRef.current.classList.add("withInput");
            searchTextRef.current.focus();
        };
    }
    
    function refreshPage() {
        window.location.reload();
    };

    return (
        <>
            <header id="header">
                <div className="logoPart">
                    <div id="logoImg" className="logoImg" onClick={refreshPage }><img id="appLogo" src={ whiteIcon } alt="Logo" /></div>
                    <div className="slogan">
                        <p>The home <br/><span>of African cinema</span></p>
                    </div>
                </div>
                <div className="searchPart">
                    <div id="searchIcon" className="searchIcon" onClick={triggerSearch}><img src={ searchIcon } alt="Search" /></div>
                    <input  id="search" list="searchList" className="search" type="search" name="search" ref={searchTextRef} autoComplete="off"/>
                </div>
                <div className="userPart">
                    <NavLink to={"/notifications"} activeClassName={"active"}>
                        <div className="userIcon">
                            <img src={ notificationIcon } alt="Profile" />
                        </div>
                    </NavLink>
                    <NavLink to={"/user"} activeClassName={"active"}>
                        <div className="userIcon">
                            <img src={ userIcon } alt="Profile"/>
                        </div>
                    </NavLink>
                </div>
                <div className="menuPart">
                    <ul>
                        <li><NavLink to={"/"} exact activeClassName={"active"}>home</NavLink></li>
                        <li><NavLink to={"/movies"} activeClassName={"active"}>movies</NavLink></li>
                        <li><NavLink to={"/series"} activeClassName={"active"}>series</NavLink></li>
                        <li><NavLink to={"/tv-shows"} activeClassName={"active"}>tv shows</NavLink></li>
                        <li><NavLink to={"/watch-later"} activeClassName={"active"}>watch later</NavLink></li>                  
                    </ul>
                </div>
            </header>
            {
                (searchResults) ? (
                    (searchResults.length > 0) ? (
                        <div id="searchList" className="searchList">
                            <br/>
                            <br/>
                            <br/>
                            {
                                searchResults.map(elem=>{
                                    if (elem.id) {
                                        if ((elem.type === "movie")||(elem.type === "movies")) {
                                            return <MovieListing key={elem.id}
                                            vid={elem.id} thumbnail={elem.thumbnail} video={elem.video} 
                                            title={elem.title} description={elem.description} category={elem.category}
                                            isAuthed={props.isAuthed} showPreview={props.showPreview}
                                            />
                                        } else if (elem.type === "series") {
                                            return <SeriesListing key={elem.id}
                                            vid={elem.id} thumbnail={elem.thumbnail} video={elem.video} 
                                            title={elem.title} description={elem.description} category={elem.category}
                                            isAuthed={props.isAuthed} showPreview={props.showPreview}
                                            />
                                        } else if ((elem.type === "tvshow")||(elem.type === "tvshows")) {
                                            return <TvShowListing key={elem.id}
                                            vid={elem.id} thumbnail={elem.thumbnail} video={elem.video} 
                                            title={elem.title} description={elem.description} category={elem.category}
                                            isAuthed={props.isAuthed} showPreview={props.showPreview}
                                            />
                                        } else {
                                            return <SeriesListing key={elem.id}
                                            vid={elem.id} thumbnail={elem.thumbnail} video={elem.video} 
                                            title={elem.title} description={elem.description} category={elem.category}
                                            isAuthed={props.isAuthed} showPreview={props.showPreview}
                                            />                                    
                                        };
                                    } else {
                                        if (elem.type==="movie") {
                                            return <MovieLoader key={elem.ranID}  />
                                        } else if (elem.type==="series") {
                                            return <SeriesLoader key={elem.ranID}  />
                                        } else if (elem.type==="tvshow") {
                                            return <TvShowLoader key={elem.ranID}  />
                                        } else {
                                            return <TvShowLoader key={elem.ranID} />
                                        }
                                    };
                                })
                            }
                        </div>
                    ) : ""
                ) : ""
            }
            

            <a href="https://wa.me/+27719542318?text=I need help with: " id="whatsAppButton" target="_blank" rel="noreferrer"><img src={WhatsAppIcon} alt="WhatsApp" /></a>
            <div className="bottomNavigation">
                <NavLink to={"/"} exact activeClassName={"active"}>
                    <button>
                        {/* <img src={homeIcon} alt="home"/> */}
                        <HomeRoundedIcon className="materialIcons" />
                        <p>home</p>
                    </button>
                </NavLink>
                <NavLink to={"/home"} exact activeClassName={"active"}>
                    <button>
                        <FavoriteRoundedIcon className="materialIcons"/>
                        {/* <img src={userIcon} alt="Suggested"/> */}
                        <p>Suggested</p>
                    </button>
                </NavLink>
                <NavLink to={"/watch-later"} exact activeClassName={"active"}>
                    <button>
                        <VideoLibraryRoundedIcon className="materialIcons"/>
                        {/* <img src={userIcon} alt="Library"/> */}
                        <p>Library</p>
                    </button>
                </NavLink>
                <button id="bottomSearch" onClick={ triggerSearch }>
                    {/* <img  src={ searchIcon } alt="Search" /> */}
                    <SearchRoundedIcon className="materialIcons" />
                    <p>Search</p>
                </button>
            </div>
        </>
    )
}

export default Header;