import React from 'react';
import { Link } from 'react-router-dom';


import userIcon from '../../../icons/montywood1.png';
import emailIcon from '../../../icons/montywood1.png';
import phoneIcon from '../../../icons/montywood1.png';
import passwordIcon from '../../../icons/montywood1.png';
import socialGoogle from '../../../icons/montywood1.png';
import socialFacebook from '../../../icons/montywood1.png';
import socialApple from '../../../icons/montywood1.png';


function SignUp(props) {

    return (
        <>
            <h3>Sign up</h3>
            <form id="register" className="loginform" method="POST" onSubmit={props.onsubmit}>
                <input type="hidden" name="type" value="register" />
                <div className="inputCont">
                    <div>
                        <img src={userIcon} alt="User icon" />
                    </div>
                    <input type="text" name="firstname" placeholder="First name" required />
                </div>
                <div className="inputCont">
                    <div>
                        <img src={userIcon} alt="User icon" />
                    </div>
                    <input type="text" name="lastname" placeholder="Last name" required />
                </div>
                <div className="inputCont">
                    <div>
                        <img src={emailIcon} alt="Mail icon" />
                    </div>
                    <input type="email" name="email" placeholder="email" required />
                </div>
                <div className="inputCont">
                    <div>
                        <img src={phoneIcon} alt="Phone icon" />
                    </div>
                    <input type="text" name="phone" placeholder="Phone" />
                </div>
                <div className="inputCont">
                    <div>
                        <img src={passwordIcon} alt="Key icon" />
                    </div>
                    <input type="password" name="password" placeholder="Password" required />
                </div>
                <div className="inputCont">
                    <div>
                        <img src={passwordIcon} alt="Key icon" />
                    </div>
                    <input type="password" name="passwordRepeat" placeholder="Repeat password" required />
                </div>
                <div className="logwithContainer">
                    <p>Register with: </p>
                    <div className="logWithCenter">
                        <div className="socialIconLogin"><img src={socialGoogle} alt="Google" /></div>
                        <div className="socialIconLogin"><img src={socialFacebook} alt="Facebook" /></div>
                        <div className="socialIconLogin"><img src={socialApple} alt="Facebook"  /></div>
                    </div>
                </div>
                <button>Sign Up</button>
                <p><Link to={"/login"} >Already have account?  <span> Sign in.</span></Link></p>
            </form>
        </>
    );
}
  
export default SignUp;