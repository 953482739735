import React, {Component} from 'react';
import axios from 'axios';

import CustomCharts from '../../../components/Charts';


import './index.css';


class Main extends Component {
    constructor(props){
        super(props);

        this.state = {
            kpis: [],
            plotsData: {},
            firstPieData: {},
            secondPieData: {},
        }
    }

    componentDidMount(){
        var headers = { Authorization: "Bearer "+this.props.userToken }
        axios.get("api/dashboard/main", {headers}).then(response=>{
            console.log(response)
            if (response.data) {
                var respInfo = response.data;
                if (respInfo.success) {
                    var pullDashboardInfo = respInfo.data;
                    var pullDashboardInfoKPIs = pullDashboardInfo.kpis;
                    var pullDashboardInfoCharts = {};
                    if (pullDashboardInfo.charts) {
                        var fullChartsData = pullDashboardInfo.charts;
                        if (fullChartsData.timelinePlots.success) {
                            pullDashboardInfoCharts.plotsData = fullChartsData.timelinePlots.data;
                        };
                        if (fullChartsData.firstPieChart.success) {
                            pullDashboardInfoCharts.firstPieData = fullChartsData.firstPieChart.data;
                        };
                        if (fullChartsData.secondPieChart.success) {
                            pullDashboardInfoCharts.secondPieData = fullChartsData.secondPieChart.data;
                        };
                    }
                    // console.log(pullDashboardInfoCharts)

                    this.setState({
                        ...this.state, 
                        kpis: pullDashboardInfoKPIs, 
                        plotsData: pullDashboardInfoCharts.plotsData, 
                        firstPieData: pullDashboardInfoCharts.firstPieData, 
                        secondPieData: pullDashboardInfoCharts.secondPieData
                    });
                } else {
                    console.log(respInfo.msg, respInfo.msg2);
                };
            } else {
                console.log("Eish")
            };
        }).catch(error=>{
            console.log(error);
        });
    }

    render () {
        document.title = 'Montywood | Dashboard';

        const {kpis, plotsData, firstPieData, secondPieData} = this.state;

        var plotsDataToPlot = {
            title: "Views over time",
            labels: [],
            datasets: [
                {
                    label: [],
                    data: [],
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.99)',
                        'rgba(54, 162, 235, 0.99)',
                        'rgba(255, 206, 86, 0.99)',
                    ]
                }
            ],
        };
        var firstPieDataToPlot = {
            title: "Popular by category",
            labels: ["TV Shows", "Movies", "Series"],
            datasets: [
                {
                    data: [firstPieData.tvshowViews, firstPieData.moviesViews, firstPieData.seriesViews],
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.99)',
                        'rgba(54, 162, 235, 0.99)',
                        'rgba(255, 206, 86, 0.99)',
                    ]
                }
            ],
        };
        var secondPieDataToPlot = {
            title: "Payment Methods",
            labels: ["Vouchers", "Bank"],
            datasets: [
                {
                    data: [secondPieData.vouchers, secondPieData.bank],
                    backgroundColor: [
                        'rgba(255, 206, 86, 0.99)',
                        'rgba(255, 99, 132, 0.99)',
                    ]
                }
            ],
        };
        console.log(plotsData);
        console.log(plotsDataToPlot);
        
        return (
            <div className="landing" >
                <div className="lowerCard">
                    <div className="userActivity">
                        <h1>Platform Activity</h1>
                        <div className="plots">
                            <div className="graph">
                                {
                                    // (
                                    //     // plotsDataToPlot 
                                    //     // && Object.keys(plotsDataToPlot).length === 0
                                    //     Object.keys(plotsDataToPlot).length !== 0
                                    //     // && Object.getPrototypeOf(plotsDataToPlot) === Object.prototype
                                    // )?(
                                    //     <CustomCharts data={plotsDataToPlot} type='line'/>
                                    // ):""
                                }
                            </div>  
                            <div className="pieMembership">
                                {
                                    (
                                        // firstPieData 
                                        // && Object.keys(firstPieData).length === 0
                                        Object.keys(firstPieData).length !== 0
                                        // && Object.getPrototypeOf(firstPieData) === Object.prototype
                                    )?(
                                        <CustomCharts data={firstPieDataToPlot} type='doughut'/>
                                    ):""
                                }
                            </div>  
                            <div className="pieContentType">
                                {
                                    (
                                        // secondPieData 
                                        // && Object.keys(secondPieData).length === 0
                                        Object.keys(secondPieData).length !== 0
                                        // && Object.getPrototypeOf(secondPieData) === Object.prototype
                                    )?(
                                        <CustomCharts data={secondPieDataToPlot} type='doughut'/>
                                    ):""
                                }
                            </div>                    
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    <div className="performanceMetrics">
                        <h1>KPI's</h1>
                        <div className="kpis">
                            <div>
                                <p>MM Rate</p>
                                <p id="mmrVal">{kpis?kpis.mmRate:0}</p>
                            </div>
                            <div>
                                <p>AR Rate</p>
                                <p id="arrVal">{kpis?kpis.arRate:0}</p>
                            </div>
                            <div>
                                <p>Customer Churn Rate</p>
                                <p id="ccrVal">{kpis?kpis.ccRate:0}</p>
                            </div>
                            <div>
                                <p>ELV</p>
                                <p>{kpis?kpis.elv:0}</p>
                            </div>
                            <div>
                                <p>Cost of Aquisition</p>
                                <p id="coaVal">{kpis?kpis.costOfAcquisition:0}</p>
                            </div>
                            <div>
                                <p>Avg. Revenue per Account</p>
                                <p id="arpaVal">{kpis?(kpis.avrgRev): 0}</p>
                            </div>
                            <div>
                                <p>Life Time Value</p>
                                <p id="tvlVal">{kpis?kpis.lifetimeValue: 0}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
                <br></br>
                <br></br>
            </div>
        );
    }
}
  
export default Main;