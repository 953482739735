import React, { useState } from 'react';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom';
import axios from 'axios';
import Swal from "sweetalert2";  

import "./index.css";

import SignIn from './SignIn';
import Forgot from './ForgotPassword';
import SignUp from './SignUp';
import ResetPassword from './ResetPassword';
import OTP from './OTP';

import whiteIcon from '../../icons/logo.png';

const Login = ({setToken, setIsAuthorized, setIsSubscribed})=>{
    const location = useLocation();
    const history = useHistory();
    const [stateObj, setStateObj] = useState(location); 
    const [defaultAction, setDefaultAction] = useState(false); 
    const [username, setUsername] = useState(""); 
    const [ranKey, setRanKey] = useState(""); 

    const onSignIn = (accessToken) => { //setnd this 'onSignIn' functoin to the loginForm for update upon sign in
        var subscription = localStorage.getItem("isSubscribed") === 'true';
        var link = "/";
        if (stateObj.state) {
            link = (stateObj && stateObj.state.from) || "/";
        };
        if (link.state) {            
            if (link.state.from) {               
                link = link.state.from;
            };
        };

        setToken(accessToken);
        setIsAuthorized(true);
        setIsSubscribed(subscription);

        history.replace(link);
    };

    var authFunction = (e)=>{
        e.preventDefault();
        e.stopPropagation();

        const thisForm = e.target;
        var formData = new FormData(thisForm);
        var requestObject = Object.fromEntries(formData.entries());
        
        Swal.fire({
            icon: "info",
            title: 'Please Wait !',
            text: 'submitting...',// add html attribute if you want or remove
            allowOutsideClick: false,
        });
        axios.post("api/authentication", requestObject).then(response=>{
            console.log(response);
            if (response.data) {
                var respInfo = response.data;
                if (respInfo.success) {
                    
                    var fullData = respInfo.data;
                    Swal.fire({
                        icon: "success",
                        title: respInfo.msg,
                        text: respInfo.msg2,
                        allowOutsideClick: false,
                    });
                    if (requestObject.type === "login") {                    
                        localStorage.setItem("firstname", fullData.firstname);
                        localStorage.setItem("lastname", fullData.lastname);
                        localStorage.setItem("roleName", fullData.roleName);
                        localStorage.setItem("role", fullData.role);
                        localStorage.setItem("userToken", fullData.userToken);
                        localStorage.setItem("refreshToken", fullData.refreshToken);
                        localStorage.setItem("isSubscribed", fullData.isSubscribed);

                        setTimeout(()=>{
                            Swal.close();

                            onSignIn(fullData.userToken);
                        }, 1000);
                    } else if (requestObject.type === "register") {
                        if (fullData.confirmation) {
                            history.push("/otp");
                            setUsername(fullData.username);
                        } else {
                            localStorage.setItem("firstname", fullData.firstname);
                            localStorage.setItem("lastname", fullData.lastname);
                            localStorage.setItem("roleName", fullData.roleName);
                            localStorage.setItem("role", fullData.role);
                            localStorage.setItem("userToken", fullData.userToken);
                            localStorage.setItem("refreshToken", fullData.refreshToken);
                            localStorage.setItem("isSubscribed", fullData.isSubscribed);

                            setTimeout(()=>{
                                Swal.close();
                                
                                onSignIn(fullData.userToken);
                            }, 1000);
                        }
                    } else if (requestObject.type === "passReset") {
                        localStorage.setItem("firstname", fullData.firstname);
                        localStorage.setItem("lastname", fullData.lastname);
                        localStorage.setItem("roleName", fullData.roleName);
                        localStorage.setItem("role", fullData.role);
                        localStorage.setItem("userToken", fullData.userToken);
                        localStorage.setItem("refreshToken", fullData.refreshToken);
                        localStorage.setItem("isSubscribed", fullData.isSubscribed);

                        setTimeout(()=>{
                            Swal.close();
                            
                            onSignIn(fullData.userToken);
                        }, 1000);
                    } else if (requestObject.type === "forgotPassword") {
                        history.push("/otp");
                        setUsername(requestObject.username);
                    } else if (requestObject.type === "otp") {
                        if (fullData.continue) {
                            history.push("/resetPassword");
                            setUsername(requestObject.username);
                            setRanKey(requestObject.ranKey);
                        } else {
                            localStorage.setItem("firstname", fullData.firstname);
                            localStorage.setItem("lastname", fullData.lastname);
                            localStorage.setItem("roleName", fullData.roleName);
                            localStorage.setItem("role", fullData.role);
                            localStorage.setItem("userToken", fullData.userToken);
                            localStorage.setItem("refreshToken", fullData.refreshToken);
                            localStorage.setItem("isSubscribed", fullData.isSubscribed);

                            setTimeout(()=>{
                                Swal.close();
                                
                                onSignIn(fullData.userToken);
                            }, 1000);
                        }
                    };
                } else {
                    Swal.fire({
                        icon: "error",
                        title: respInfo.msg,
                        text: respInfo.msg2,
                        allowOutsideClick: false,
                    });
                };
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Couldn't send request",
                    text: "data error",
                    allowOutsideClick: false,
                    showCancelButton: true,
                    confirmButtonText: "Retry",
                    cancelButtonText: "Close",
                }).then(res=>{
                    if (res.isConfirmed) {
                        authFunction(e);
                    };
                });
            };
        }).catch(error=>{
            console.log(error);
            Swal.fire({
                icon: "error",
                title: "Couldn't send request",
                text: error.message,
                allowOutsideClick: false,
                showCancelButton: true,
                confirmButtonText: "Retry",
                cancelButtonText: "Close",
            }).then(res=>{
                if (res.isConfirmed) {
                    authFunction(e);
                };
            });
            
            // // alert("xxx")
            // var demoToken = "qqqqqqqqqqqqqqqqq.eeeeeeeeeeeeeeee.rrrrrrrrrrrrrrr";
            // localStorage.setItem("userToken", demoToken);
            // localStorage.setItem("isSubscribed", true);
            // onSignIn(demoToken);
        });
    };

    const veryifyFunc = ()=>{
        if (!defaultAction) {
            if ((location.pathname ).substring(1, location.pathname.length) === "otp") {
                var paramsString = location.search;
                if (paramsString !== "") { 
                    const queryParams = new URLSearchParams(paramsString);
                    if ( queryParams.has("username") && queryParams.has("ranKey") ) {
                        var sendObj = {type: "verifyLink"};
                        sendObj.ranKey = queryParams.get("ranKey");
                        sendObj.username = queryParams.get("username");
                        Swal.fire({
                            icon: "info",
                            title: 'Verifying link',
                            text: 'Please Wait...',// add html attribute if you want or remove
                            allowOutsideClick: false,
                            onBeforeOpen: () => {
                                Swal.showLoading()
                            },
                        });
                    
                        axios.post("api/authentication", sendObj).then(response=>{
                            console.log(response);
                            if (response.data) {
                                var respInfo = response.data;
                                if (respInfo.success) {
                                    history.push("/resetPassword");
                                    setUsername(sendObj.username);
                                    setRanKey(sendObj.ranKey);
                                    setDefaultAction(true);
                                } else {
                                    Swal.fire({
                                        icon: "error",
                                        title: respInfo.msg,
                                        text: respInfo.msg2,
                                        allowOutsideClick: false,
                                        showCancelButton: true,
                                        confirmButtonText: "Retry",
                                        cancelButtonText: "Close",
                                    }).then(res=>{
                                        if (res.isConfirmed) {
                                            veryifyFunc();
                                        } else {
                                            window.history.back();
                                        };
                                    });
                                };
                            } else {
                                Swal.fire({
                                    icon: "error",
                                    title: "Couldn't send request",
                                    text: "data error",
                                    allowOutsideClick: false,
                                    showCancelButton: true,
                                    confirmButtonText: "Retry",
                                    cancelButtonText: "Close",
                                }).then(res=>{
                                    if (res.isConfirmed) {
                                        veryifyFunc();
                                    } else {
                                        window.history.back();
                                    };
                                });
                            };
                        }).catch(error=>{
                            console.log(error);
                            Swal.fire({
                                icon: "error",
                                title: "Couldn't verify link",
                                text: "Network error.",
                                allowOutsideClick: false,
                                showCancelButton: true,
                                confirmButtonText: "Retry",
                                cancelButtonText: "Close",
                            }).then(res=>{
                                if (res.isConfirmed) {
                                    veryifyFunc();
                                } else {
                                    window.history.back();
                                };
                            });
                        });
                    } else {
                        setDefaultAction(true);
                    }
                } else {
                    setDefaultAction(true);
                };
            } else {
                setDefaultAction(true);
            }
        } else {
            setDefaultAction(true);
        }
    }

    const keepHistory = (hist)=> setStateObj(hist);

    return (
            <div className="logContainer" id="loginContainer" >
                <button id="closeLogin" onClick={()=>{ history.goBack() }}>&#10140;</button>
                <div className="elementContainer">
                    <div className="montLogo">
                        <center className="myCenter">
                            <div className="logo"><img src={ whiteIcon } alt="User icon" /></div>
                            <p>Home of african cinema</p>
                        </center>
                    </div>
                    <div className="landing">
                        { (defaultAction) ? (
                            <Switch>
                                <Route path="/login" component={() => <SignIn stateObj={stateObj} keepHistory={keepHistory} onsubmit={authFunction} />} exact /> 
                                <Route path="/signup" component={() => <SignUp stateObj={stateObj} keepHistory={keepHistory} onsubmit={authFunction} />} exact />
                                <Route path="/forgotPassword" component={() => <Forgot stateObj={stateObj} keepHistory={keepHistory} onsubmit={authFunction} />} exact />
                                <Route path="/resetPassword" component={() => <ResetPassword username={username} ranKey={ranKey} stateObj={stateObj} keepHistory={keepHistory} onsubmit={authFunction} />} exact /> 
                                <Route path="/otp" component={() => <OTP username={username} stateObj={stateObj} keepHistory={keepHistory} onsubmit={authFunction} />} exact /> 
                            </Switch>
                        ) : veryifyFunc() }
                    </div>
                </div>
            </div>
            
    );
}
  
export default Login;