import React, {Component} from 'react';
import axios from 'axios';

import './index.css';


class Vouchers extends Component {
    constructor(props){
        super(props);

        this.state = {
            pulledVoucherCounts: []
        }
    }

    componentDidMount(){
        var headers = { Authorization: "Bearer "+this.props.userToken };
        axios.get("../api/dashboard/vouchers", {headers}).then(response=>{
            console.log(response)
            if (response.data) {
                var respInfo = response.data;
                if (respInfo.success) {
                    this.setState({pulledVoucherCounts: respInfo.data});
                } else {
                    console.log(respInfo.msg, respInfo.msg2);
                };
            } else {
                console.log("Eish")
            };
        }).catch(error=>{
            console.log(error);
        });
    }

    render () {
        document.title = 'Montywood | Vouchers - Dashboard';

        const {pulledVoucherCounts} = this.state;
        var voucherCount = pulledVoucherCounts.voucherCounts;
        console.log(this.props);

    
        return (
            <div className="landing" >
                <div className="lowerCard">
                    <div className="userActivity">
                        <h1>Commmunication</h1>
                        <div className="vocuherActions">
                            <div className="generate" onClick={()=> this.props.sendMessage()}>
                                <p>+</p>
                            </div>
                            {/* <div className="verify" onClick={()=> this.props.verifyVoucher()}>
                                <p>Verify Voucher</p>
                            </div> */}
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    <div className="performanceMetrics">
                        <h1>Communication count</h1>
                        <div className="kpis">
                            <div>
                                <p>Total Messages</p>
                                <p id="mmrVal">{voucherCount?voucherCount.generated:0}</p>
                            </div>
                            <div>
                                <p>Sent Individual Vouchers</p>
                                <p id="arrVal">{voucherCount?voucherCount.released:0}</p>
                            </div>
                            <div>
                                <p>Sent to Groups</p>
                                <p id="ccrVal">{voucherCount?voucherCount.partiallyComplete:0}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
                <br></br>
                <br></br>
            </div>
        );
    }
}
  
export default Vouchers;