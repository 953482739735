import React from 'react';

import "./index.css";

function EpisodeLoaded(props) {
    return (
        <div key={props.id} className={props.className} onClick={props.onClick}>
            <div className="thumbnail">
                <div>
                    <img src={props.thumbnail} alt={props.episodeTitle}/>
                </div>
            </div>
            <div className="moreInfo">
                <div>
                    <p className="title">{props.episodeTitle}</p><br/>
                    <p className="description">{props.description}</p>
                </div>
                <div>
                    <p className="duration">{props.duration}</p>
                    <p className="size">{props.size}</p>
                </div>
            </div>
        </div>
    )
}

export default EpisodeLoaded;