import React from 'react';


function Category(props) {
    var className = "category "+props.name+"Categories";


    
    return (
        <div key={props.key} className={className} onClick={() => {props.contentByCategory(props.name)}}>
            <p>{props.name}</p>
        </div>
    );
}
  
export default Category;