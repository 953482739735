import React from 'react';


function SeriesLoader(props) {

    return (
        <div key={props} className="loaderMovie loaderSeries" >
            <i className="shineElemLoader"></i>
            <p>.</p>
            <p>.</p>
        </div>
    );
}
  
export default SeriesLoader;