import React from 'react'

import "./index.css";

function User(props) {
    var classString = "userCard";
    // var stateString = "Unreleased";
    if (props.state === "cancelled") {
        classString += " voucherCardCancelled";
        // stateString = "Voucher cancelled";
    } else if (props.state === "expired") {
        classString += " voucherCardExpired";
        // stateString = "Voucher expired";
    } else if (props.state === "inProgress") {
        classString += " voucherCardInProgress";
        // stateString = "Currently in use";
    } else if (props.state === "complete") {
        classString += " voucherCardDone";
        // stateString = "Voucher finished";
    } else if (props.state === "released") {
        classString += " voucherCardReleased";
        // stateString = "Voucher released";
    };
    return (
        <div className={classString} onClick={()=>{props.actOnUser(props)}}>
            <div className="profilePicHolder"><img src={props.details.profilePic} alt="DP"/></div>
            <p className="amount">{props.firstname} {props.lastname}</p>
            <p className="description">Email: {props.email}</p>
            <p className="description">Phone: {props.phone}</p>
            <p className="description">Role: {props.details.roleName}</p>
            <div className="theDates">
                <p className="expiryDate">Last amount: {props.subscription.amount}</p>
                <p className="startingDate">End date: {props.subscription.expiry}</p>
            </div>
        </div>
    )
}
export default User;