import React from 'react';
import axios from 'axios';
import Swal from "sweetalert2";  


import Backdrop from '../../../../Backdrop';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
// import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";


import './index.css';


function Generate(props) {
    var dynamicProps = props.dynamicProps;

    var requestObject = {};
    const sendRequest = (e)=>{
        e.preventDefault();
        e.stopPropagation();

        const thisForm = e.target;
        var formData = new FormData(thisForm);
        requestObject = Object.fromEntries(formData.entries());

        makeRequest(requestObject);
    }
    const makeRequest = (requestObject)=> {
        Swal.fire({
            icon: "info",
            title: "Submitting...",
            text: "Please wait a couple of seconds...",
            allowOutsideClick: false,
            showConfirmButton: false,
        });
        var headers = {
            Authorization: "Bearer "+dynamicProps.userToken
        };
        axios.post("../api/dashboard/vouchers?action=generate", requestObject, {headers}).then(response=>{
            console.log(response)
            if (response.data) {
                var respInfo = response.data;
                if (respInfo.success) {
                    var respData = respInfo.data;
                    if (respData.complete === requestObject.quantity) {
                        Swal.fire({
                            icon: "success",
                            title: respInfo.msg,
                            text: respInfo.msg2,
                            allowOutsideClick: false,
                            showCancelButton: true,
                            confirmButtonText: "Retry left overs",
                            cancelButtonText: "It's okay"
                        }).then(res=>{
                            if (res.isConfirmed) {
                                requestObject.quantity = requestObject.quantity - respData.complete;
                                makeRequest(requestObject)
                            };
                        });
                    } else {
                        Swal.fire({
                            icon: "success",
                            title: respInfo.msg,
                            text: respInfo.msg2,
                            allowOutsideClick: false,
                            confirmButtonText: "Done",
                        });
                    };
                } else {
                    Swal.fire({
                        icon: "error",
                        title: respInfo.msg,
                        text: respInfo.msg2,
                        allowOutsideClick: false,
                        showCancelButton: true,
                        confirmButtonText: "Retry",
                    }).then(res=>{
                        if (res.isConfirmed) {
                            makeRequest(requestObject)
                        };
                    })
                };
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Request failed",
                    text: "Request failed",
                    allowOutsideClick: false,
                    showCancelButton: true,
                    confirmButtonText: "Retry",
                    cancelButtonText: "Cancel"
                }).then(res=>{
                    if (res.isConfirmed) {
                        makeRequest(requestObject)
                    };
                });
            };
            
        }).catch(error=>{
            console.log(error);
            Swal.fire({
                icon: "error",
                title: "Request failed",
                text: "Catch: Something went wrong",
                allowOutsideClick: false,
                showCancelButton: true,
                confirmButtonText: "Retry",
                cancelButtonText: "Cancel"
            }).then(res=>{
                if (res.isConfirmed) {
                    makeRequest(requestObject)
                };
            });
        });
    }

    const voucherValues = [
        {
            label: "R5", value: 5
        },{
            label: "R50", value: 50
        }
    ]

    return (
        <>
            {/* <div className="backdrop" onClick={props.close}></div> */}
            <Backdrop close={props.close} className="backdrop" />
            <div className="popupGenerate">
                <h1>Generate Voucher</h1>
                <form onSubmit={sendRequest}>
                    <div>
                        <TextField name="amount" label="Select Amount" variant="outlined" select required >
                            {voucherValues.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div>
                        <TextField name="expiryDate" 
                        label="Expiry date" variant="outlined" type="date" 
                        required
                         />
                    </div>
                    <div>
                        <TextField className="materialField" name="quantity" label="Number of voucher" variant="outlined"
                        type="number"
                        required />
                    </div>
                    <br/><br/>
                    <br/><br/>
                    <Button className="submitBtn" variant="contained" type="submit">Generate</Button>
                </form>
            </div>
        </>
    );
  }
  
  export default Generate;