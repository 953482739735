import React, {useState, useEffect} from 'react';
import whiteIcon from '../../icons/logo_n_slogan.PNG';

import './index.css';

function SplashScreen(props) {
  const [timedLauncher, setTimedLauncher] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setTimedLauncher(false);
    }, 2000);
  }, []);

  
  return (timedLauncher) ? (
    <div id="splashScreen">
        <div className="splashScreenIcon">
          <img src={ whiteIcon } alt="Logo" />
        </div>
    </div>
  ) : "";
}
  
export default SplashScreen;