import React, {Component} from 'react';
import axios from 'axios';

import './index.css';


class Subscriptions extends Component {
    constructor(props){
        super(props);

        this.state = {
            pulledSubscriptions: []
        }
    }

    componentDidMount(){
        var headers = { Authorization: "Bearer "+this.props.userToken };
        axios.get("../api/dashboard/subscriptions", {headers}).then(response=>{
            console.log(response)
            if (response.data) {
                var respInfo = response.data;
                if (respInfo.success) {
                    this.setState({pulledSubscriptions: respInfo.data});
                } else {
                    console.log(respInfo.msg, respInfo.msg2);
                };
            } else {
                console.log("Eish")
            };
        }).catch(error=>{
            console.log(error);
        });
    }

    render () {
        document.title = 'Montywood | Subscriptions - Dashboard';

        const {pulledSubscriptions} = this.state;
        var subscriptions = pulledSubscriptions.subscriptions;
        

        return (
            <div className="landing" >
                <div className="lowerCard">
                <div className="userActivity">
                        <h1>Subscriptions/Memberships</h1>
                        <div className="vocuherActions">
                            <div className="generate">
                            </div>  
                            <div className="currentVouchers">
                            </div>  
                            <div className="verify">
                            </div>                    
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    <div className="performanceMetrics">
                        <h1>Subscription rate</h1>
                        <div className="kpis">
                            <div>
                                <p>Total Subscriptions</p>
                                <p id="mmrVal">{subscriptions?subscriptions.all:0}</p>
                            </div>
                            <div>
                                <p>In-House Voucher</p>
                                <p id="arrVal">{subscriptions?subscriptions.inHouse:0}</p>
                            </div>
                            <div>
                                <p>BlueLabel Voucher</p>
                                <p id="ccrVal">{subscriptions?subscriptions.blueLabel:0}</p>
                            </div>
                            <div>
                                <p>Bank Payment Voucher</p>
                                <p>{subscriptions?subscriptions.bankPayments:0}</p>
                            </div>
                            <div>
                                <p>Active Subscriptions</p>
                                <p id="arpaVal">{subscriptions?subscriptions.active:0}</p>
                            </div>
                            <div>
                                <p>Finished Subscriptions</p>
                                <p id="tvlVal">{subscriptions?subscriptions.finished:0}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
                <br></br>
                <br></br>
            </div>
        );
    }
}
  
export default Subscriptions;