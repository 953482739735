import React, {Component} from 'react';
import axios from 'axios';
import $ from 'jquery';
import { v4 as uuidv4 } from 'uuid';


import HomeBackground from './Home/HomeBackground';
import MovieListing from './Movies/MovieHolder/MovieListing';
import SeriesListing from './Series/SeriesHolder/SeriesListing';
import TvShowListing from './TvShows/TvShowHolder/TvShowListing';
import MovieLoader from './Movies/MovieHolder/MovieLoader';
import SeriesLoader from './Series/SeriesHolder/SeriesLoader';
import TvShowLoader from './TvShows/TvShowHolder/TvShowLoader';

class Home extends Component {

    constructor(props){
        super(props);

        this.state = {
            cover: "",
            pulledContent: [
                // {type: "movie", isMultiple: "Part", id: uuidv4(), title: "XXXX", description: "the story of a fewn jefkwbhj bejbjfew jkwef kfewk kfew jkfe jk ewfjb efjkw bkfe jkwebfjk efkj fekwj kjefwnjkfnwkhefh lfw qhihfehwqufoifoi fwqij fwiqohiofwh o wfqoi fiowq ofwoifoiq of iwiu fwio foiwq hifouifwq wqfio hwfqioiowfh wiq fwql ofiwqwfh iwf hifw hguy boy from rural areas to urban life lkl klkklklk klkk klklklkf ewj beh jefwe bhj efjhwej fje bhjewfb hjewf hj ef fej efwj efjwgefjwh gjef hjfenjk fwj fewbj ewbh hjewf hjfewhj efh ewhf gyuwg fuweg fe gefug jfe hj efwhj fhewbfjkelkf hkefh ioelqjio hfeqkh fqwiwfq ijowqfhi", category:"drama", thumbnail: "images/logo195_square.png"}, 
                // {type: "series", id: uuidv4(), title: "XXXX", description: "the story of a guy boy from rural areas to urban life", category:"drama", thumbnail: "images/logo195_square.png"}, 
                // {type: "tvshows", id: uuidv4(), title: "XXXX", description: "the story of a guy boy from rural areas to urban life", category:"drama", thumbnail: "images/logo195_square.png"}, 
                // {type: "movie", id: uuidv4(), title: "XXXX", description: "the story of a guy boy from rural areas to urban life", category:"drama", thumbnail: "images/logo195_square.png"}, 

                {type: "movie", ranID: uuidv4()}, {type: "movie", ranID: uuidv4()}, {type: "movie", ranID: uuidv4()}, {type: "movie", ranID: uuidv4()}, {type: "movie", ranID: uuidv4()}, {type: "movie", ranID: uuidv4()}, {type: "movie", ranID: uuidv4()}, 
                {type: "series", ranID: uuidv4()}, {type: "series", ranID: uuidv4()}, {type: "series", ranID: uuidv4()}, {type: "series", ranID: uuidv4()}, {type: "series", ranID: uuidv4()}, {type: "series", ranID: uuidv4()}, {type: "series", ranID: uuidv4()}, 
                {type: "tvshows", ranID: uuidv4()}, {type: "tvshows", ranID: uuidv4()}, {type: "tvshows", ranID: uuidv4()}, {type: "tvshows", ranID: uuidv4()}, {type: "tvshows", ranID: uuidv4()}, {type: "tvshows", ranID: uuidv4()}, {type: "tvshows", ranID: uuidv4()}, 
            ]
        }
    }

    componentDidMount(){
        var pastContent = sessionStorage.getItem('homeData') ? JSON.parse(sessionStorage.getItem('homeData')) : [];
        var doRequest = ()=>{
            var headers = {
                Authorization: "Bearer "+this.props.userToken
            };
            axios.get("api/content?section=home&category=all", { headers }).then(response=>{
                console.log(response)
                if (response.data) {
                    var respInfo = response.data;
                    if (respInfo.success) {
                        if (respInfo.data) {
                            setTimeout(()=>{
                                // sessionStorage.setItem('homeData', JSON.stringify(respInfo.data.cover));
                                this.setState({...this.state, cover: respInfo.data.cover,  pulledContent: respInfo.data.content}); 
                            }, 1000)
                        }
                    } else {
                        console.log(respInfo.msg, respInfo.msg2);
                        setTimeout(()=>{
                            this.setState({...this.state, pulledContent: []}); 
                        }, 1000)
                    };
                } else {
                    setTimeout(()=>{
                        this.setState({...this.state, pulledContent: []}); 
                    }, 1000);
                };
            }).catch(error=>{
                console.log(error);
                setTimeout(()=>{
                    this.setState({...this.state, pulledContent: []}); 
                    // this.setState({...this.state}); 
                }, 1000);
            });
        };
        
        if ( !(pastContent.length > 0) ) {
            var paramsString = window.location.search;
            if (paramsString === "") {
                doRequest(); 
            };
        } else {
            this.setState({
                ...this.state,
                cover: "",
                pulledContent: pastContent,
            }); 
        }
    }

    componentWillUnmount() {
        // alert("xxx");
        console.log("Component unmounting")
    }

    shouldComponentUpdate(nextProps, nextState){
        console.log(nextProps);
        console.log(nextState);
        // if (this.props.location.location.search === nextProps.location.location.search) {
        if (this.props.location.search === nextProps.location.search) {
            console.log(this.state);
            if (this.state === nextState) {
                return false;
            };
            return true;
        };
        return false;
    }

    render () {
        document.title = 'Montywood – The Home of African Cinema';
        $('meta[name=description]').attr('content', 'The home of African Content. Watch and discover new Movies, Series, TV Shows and short films created to creatively tell local stories.');

        var recommended = {};
        var pulledHotMovies = [];
        var pulledHotSeries = [];
        var pulledHotTvShows = [];
        const {cover , pulledContent} = this.state;

        pulledContent.forEach(elem=>{
            if (elem.section === 'cover') { 
                recommended = elem;
            } else if ((elem.type === 'movie')||(elem.type === 'movies')) {
                pulledHotMovies.push(elem);
            } else if (elem.type === 'series') {
                pulledHotSeries.push(elem);
            } else if ((elem.type === 'tvshow')||(elem.type === 'tvshows')) {
                pulledHotTvShows.push(elem);
            };
        });
        recommended.cover = cover;
        
        return (
            <>
            <HomeBackground
            vid={recommended.id}
            cover={recommended.cover} title={recommended.title} episodeTitle={recommended.episodeTitle}
            description={recommended.description}
            thumbnail={recommended.thumbnail} video={recommended.video} 
            isAuthed={this.props.isAuthed} 
            isSubscribed={this.props.isSubscribed}
            justPlay={this.props.justPlay}
            promptLogin={this.props.promptLogin}
            />
            <div id="moviesContainer" className="moviesContainer" >
                <h3 id="newReleases">New Releases</h3>
                <div className="centerNewMovies">
                    {
                        pulledHotMovies.length ?
                        pulledHotMovies.map(elem => {
                            if (elem.id) {
                                return <MovieListing key={elem.id}
                                vid={elem.id} thumbnail={elem.thumbnail} video={elem.video} 
                                title={elem.title} episodeTitle={elem.episodeTitle}
                                description={elem.description} category={elem.category}
                                isAuthed={this.props.isAuthed} showPreview={this.props.showPreview}
                                isMultiple={elem.isMultiple}
                                />;
                            } else {
                                return <MovieLoader key={elem.ranID}/>;
                            }
                        }) : <p className="noContentP">No new realeases yet.</p>
                    }
                </div>
                <h3 id="newReleases">Popular Series</h3>
                <div className="centerNewMovies">
                    {
                        pulledHotSeries.length ?
                        pulledHotSeries.map(elem => {
                            if (elem.id) {
                                return <SeriesListing key={elem.id} 
                                vid={elem.id} thumbnail={elem.thumbnail} video={elem.video} 
                                title={elem.title} episodeTitle={elem.episodeTitle}
                                description={elem.description} category={elem.category}
                                isAuthed={this.props.isAuthed} showPreview={this.props.showPreview}
                                isMultiple={elem.isMultiple}
                                />;
                            } else {
                                return <SeriesLoader key={elem.ranID}/>;
                            }
                        }) : <p className="noContentP">No popular series yet.</p>
                    }
                </div>
                <h3 id="newReleases">Current Shows</h3>
                <div className="centerNewMovies">
                    {
                        pulledHotTvShows.length ?
                        pulledHotTvShows.map(elem => {
                            if (elem.id) {
                                return <TvShowListing key={elem.id}
                                vid={elem.id} thumbnail={elem.thumbnail} video={elem.video} 
                                title={elem.title} episodeTitle={elem.episodeTitle}
                                description={elem.description} category={elem.category}
                                isAuthed={this.props.isAuthed} showPreview={this.props.showPreview}
                                isMultiple={elem.isMultiple}
                                />;
                            } else {
                                return <TvShowLoader key={elem.ranID}/>;
                            }
                        }) : <p className="noContentP">No current shows yet.</p>
                    }
                </div>
                <br></br>
                <br></br>
                <br></br>
            </div>
        </>
        )
    }
}

export default Home;