import React from 'react';
import axios from 'axios';
import Swal from "sweetalert2";  

import Backdrop from '../../../../Backdrop';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import './index.css';


function Verify(props) {
    var dynamicProps = props.dynamicProps;

    var requestObject = {};
    const sendRequest = (e)=>{
        e.preventDefault();
        e.stopPropagation();

        const thisForm = e.target;
        var formData = new FormData(thisForm);
        requestObject = Object.fromEntries(formData.entries());

        verify();
    };

    const verify = ()=>{
        Swal.fire({
            icon: "info",
            title: "Submitting...",
            // text: respInfo.msg2,
            allowOutsideClick: false,
            showConfirmButton: false,
        });
        var headers = {
            Authorization: "Bearer "+dynamicProps.userToken
        };
        axios.post("../api/dashboard/vouchers?action=verify", requestObject, {headers}).then(response=>{
            console.log(response)
            var respInfo = {};
            var icon  = "success";
            if (response.data) {
                respInfo = response.data;
                if (respInfo.success) {
                    icon = "success";
                } else {
                    icon = "error";
                };
            } else {
                icon = "error";
                console.log("Eish yah");
                respInfo.msg = "Reuest failed";
                respInfo.msg2 = "Reuest failed";
            };
            Swal.fire({
                icon: icon,
                title: respInfo.msg,
                text: respInfo.msg2,
                allowOutsideClick: false,
                confirmButtonText: "Double-check voucher",
            });
        }).catch(error=>{
            Swal.close();
            console.log(error);
            console.log("Catch: Something went wrong");
        });
    }

    return (
        <>
            {/* <div className="backdrop" onClick={props.close}></div> */}
            <Backdrop close={props.close} className="backdrop" />
            <div className="popupVerify">
                <h1>Verify Voucher</h1>
                <form onSubmit={sendRequest}>
                    <div>
                        <TextField className="materialField" name="voucher" 
                        label="Enter the voucher" variant="outlined" 
                        required
                         />
                    </div>
                    <Button className="submitBtn" variant="contained" type="submit">Submit</Button>
                </form>
            </div>
        </>
    );
  }
  
  export default Verify;