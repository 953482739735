import React from 'react'
import { useHistory } from 'react-router-dom';

import "./index.css";

function Backdrop(props) {
    var history = useHistory();

    var className = "generalBackdrop "+props.className;
    return (
        <div className={className} onClick={props.close}>
            <div className="backgroundCloseBtn">
                <button onClick={()=>{ history.goBack() }}>&#10140;</button>
                <p>Go Back</p>
            </div>
        </div>
    )
}
export default Backdrop;