import React, {Component} from 'react';
import axios from 'axios';

import './index.css';


class Users extends Component {
    constructor(props){
        super(props);

        this.state = {
            pullDashboardInfo: []
        }
    }

    componentDidMount(){
        var headers = { Authorization: "Bearer "+this.props.userToken };
        axios.get("../api/dashboard/users", {headers}).then(response=>{
            console.log(response)
            if (response.data) {
                var respInfo = response.data;
                if (respInfo.success) {
                    this.setState({pullDashboardInfo: respInfo.data});
                } else {
                    console.log(respInfo.msg, respInfo.msg2);
                };
            } else {
                console.log("Eish")
            };
        }).catch(error=>{
            console.log(error);
        });

        var userCounts = document.querySelectorAll(".userCounts");
        userCounts.forEach(elem=>{
            elem.onclick = ()=>{
                var myID = elem.id;
                this.props.showUsers({dateRange: myID});
            }
        })
    }

    render () {
        document.title = 'Montywood | Dashboard - Users';

        const {pullDashboardInfo} = this.state;
        var userCounts = pullDashboardInfo.userCounts;

        return (
            <div className="landing" >
                <div className="lowerCard">
                    <div className="allUsers">
                        <h1>User Signups</h1>
                        <div className="regGroups">
                            <div className="userCounts" id="0">
                                <div>
                                    <p>{userCounts?userCounts.all:0}</p>
                                </div>
                                <p>All users</p>
                            </div>  
                            <div className="userCounts" id="1">
                                <div>
                                    <p>{userCounts?userCounts.today:0}</p>
                                </div>
                                <p>Signed up Today</p>
                            </div>
                            <div className="userCounts" id="7">
                                <div>
                                <p>{userCounts?userCounts.thisWeek:0}</p>
                                </div>
                                <p>Signed up this week</p>
                            </div> 
                            <div className="userCounts" id="30">
                                <div>
                                    <p>{userCounts?userCounts.thisMonth:0}</p>
                                </div>
                                <p>Signed up this Month</p>
                            </div>
                            <div className="userCounts" id="365">
                                <div>
                                    <p>{userCounts?userCounts.lastYear:0}</p>
                                </div>
                                <p>Last 365 days</p>
                            </div>              
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    <div className="performanceMetrics">
                        
                    </div>
                </div>
                <br></br>
                <br></br>
                <br></br>
            </div>
        );
    }
}
  
export default Users;