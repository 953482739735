import React from 'react';

import facebookIcon from '../icons/facebook.svg';
import twitterIcon from '../icons/twitter.svg';
import linkedInIcon from '../icons/linkedin.svg';
import instagramIcon from '../icons/instagram.svg';
import youTubeIcon from '../icons/youtube.svg';

function Footer() {
    var copyrightDate = new Date().getFullYear();
    return (
        <footer >
            <div className="first">
                <div className="ourSocials">
                    <a href="https://youtube.com/Montywood" rel="noreferrer" target="_blank" style={{display: "none"}}><div className="socials"><img src={youTubeIcon} alt="YouTube" /></div></a>
                    <a href="https://facebook.com/MontywoodApp" rel="noreferrer" target="_blank"><div className="socials"><img src={facebookIcon} alt="Facebook" /></div></a>
                    <a href="https://twitter.com/MontywoodApp" rel="noreferrer" target="_blank"><div className="socials"><img src={twitterIcon} alt="Twitter" /></div></a>
                    <a href="https://instagram.com/MontywoodApp" rel="noreferrer" target="_blank"><div className="socials"><img src={instagramIcon} alt="Instagram" /></div></a>
                    <a href="https://linkedin.com/in/montywood-app-757622218" rel="noreferrer" target="_blank"><div className="socials"><img src={linkedInIcon} alt="LinkedIn" /></div></a>
                </div>
                <div className="ourAddress">
                    <p>39988 Shakespeare Street</p>
                    {/* <p>Phase 2</p> */}
                    <p>Scenery Park</p>
                    <p>East London</p>
                    <p>5247</p>
                </div>
                <div className="ourContacts">
                    <p>Cell:	079 344 1736</p>
                    <p>Tel:		087 350 4313</p>
                    <p>Email:	chuckd@kumkanifm.co.za</p>
                </div>
            </div>
            <div className="second">
                Copyright © {copyrightDate} Montywood | All Rights Reserved.
            </div>
      </footer>
    );
  }
  
  export default Footer;