export const GET_PARAMS = {
    popup: "popup",
    cid: "cid",
    notificationId: "notification-id",
};
  
export const GET_ENUMS = {
    popup: {
        notifications: "notifications",
        notificationDetails: "notification-details",
        watchPreview: "preview",
        generateVoucher: "generate",
        verifyVoucher: "verify",
        currentVoucher: "current",
        categories: "categories",
        createMovie: "createMovie",
        addCategory: "addCategory",
        printVouchers: "printVouchers",
        updateVideo: "updateVideo",
        updateProfile: "updateProfile",
        showUsers: "showUsers",
        sendMessage: "sendMessage",
        
    }
};