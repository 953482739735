import React from 'react';
import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
import axios from 'axios';
import Swal from "sweetalert2";  

import './index.css';

import MainContent from './MainContent';
import Upload from './Upload';
import Movies from './Movies';
import Series from './Series';
import TvShows from './TvShows';

function Content (props) {


    const onlineAction = (action, vid)=>{
        Swal.fire({
            icon: "info",
            title: "Submiting change...",
            text: ""
        })
        var headers = { Authorization: "Bearer "+props.userToken };
        return axios.get("../../api/dashboard/content?action="+action+"&vid="+vid, { headers }).then(response=>{
            console.log(response)
            if (response.data) {
                var respInfo = response.data;
                if (respInfo.success) {
                    Swal.fire({
                        icon: "success",
                        title: respInfo.msg,
                        text: respInfo.msg2,
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: respInfo.msg,
                        text: respInfo.msg2,
                    });
                };
            } else {
                Swal.fire({
                    icon: "error",
                    title: respInfo.msg,
                    text: respInfo.msg2,
                });
            };
        }).catch(err=>{
            console.log(err.message);
            Swal.fire({
                icon: "error",
                title: "Something went wrong",
                text: err.message,
                showCancelButton: true,
                confirmButtonText: "Retry"
            }).then(res=>{
                if (res.isConfirmed) {
                    onlineAction(action, vid);
                }
            })
        })
    }

    const handleUploadProgress = (elem, e)=>{
        console.log(e);
        var perecentUploadedObj  = {label: "Preparing upload", value: 0};
        if (e.lengthComputable) {
            var perecentUploaded = Math.floor((e.loaded/e.total)*100);
            perecentUploadedObj.value = perecentUploaded;
            if (perecentUploaded < 100) {
                perecentUploadedObj.label = perecentUploaded+"% Uploaded...";
            } else {
                perecentUploadedObj.label = perecentUploaded+"% Complete, awaiting result.";
            };
        };
        document.getElementById("uploadProgressText").innerHTML = perecentUploadedObj.label;
        elem.value = perecentUploadedObj.value;
    }

    const handleBackgroundUpload = (e)=>{
        var fileInput = e.target;
        if (fileInput.files) {
            if (fileInput.files.length > 0){
                var theHTML = '<div class="uploadProgressContainer"><span id="uploadProgressText"></span><progress id="uploadProgressBar" class="uploadProgressBar" value="0" max="100"> 0% </progress></div>';
                Swal.fire({
                    icon: "info",
                    title: "Updating Background...",
                    html: theHTML,
                    allowOutsideClick: false,
                    showConfirmButton: false,
                });
                const uploadProgressBar = document.getElementById("uploadProgressBar");

                var formData = new FormData();
                formData.append("sectionBackground", fileInput.name);
                formData.append("image", fileInput.files[0]);
                var headers = { Authorization: "Bearer "+props.userToken, "Content-Type": "multipart/form-data"};
                const config = {
                    onUploadProgress: (progressEvent) => { 
                        handleUploadProgress(uploadProgressBar, progressEvent);
                    },
                    headers: headers
                };
                axios.post("../../api/dashboard/content?action=updateBackground", formData, config).then(response=>{
                    console.log(response)
                    if (response.data) {
                        var respInfo = response.data;
                        if (respInfo.success) {
                            Swal.fire({
                                icon: "success",
                                title: respInfo.msg,
                                text: respInfo.msg2,
                                allowOutsideClick: false,
                                confirmButtonColor: "var(--main-gold-color)"
                            });
                        } else { 
                            Swal.fire({
                                icon: "error",
                                title: respInfo.msg,
                                text: respInfo.msg2,
                                showCancelButton: true,
                                confirmButtonText: "Retry",
                                cancelButtonText: "Back"
                            }).then(res=>{
                                if (res.isConfirmed) {
                                    handleBackgroundUpload(e);
                                }
                            });
                        }
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Something went wrong",
                            showCancelButton: true,
                            confirmButtonText: "Retry",
                            cancelButtonText: "Back"
                        }).then(res=>{
                            if (res.isConfirmed) {
                                handleBackgroundUpload(e);
                            }
                        });
                    }
                }).catch(error=>{
                    Swal.fire({
                        icon: "error",
                        title: "Something went wrong",
                        text: error.message,
                        showCancelButton: true,
                        confirmButtonText: "Retry",
                        cancelButtonText: "Back"
                    }).then(res=>{
                        if (res.isConfirmed) {
                            handleBackgroundUpload(e);
                        }
                    });
                });
            }
        }
    }

    const chooseActions = (eventData)=>{
        console.log(eventData);
        Swal.fire({
            icon: "question",
            title: "Choose action",
            showCloseButton: true,
            showCancelButton: true,
            showDenyButton: true,
            confirmButtonText: "Edit",
            denyButtonText: "Delete",
            cancelButtonText: "More",
        }).then(res=>{
            console.log(res)
            if (res.isConfirmed) {
                var vidToUpdate = {vid: eventData.vid, title: eventData.title, description: eventData.description,
                    onMovie: eventData.onMovie, category: eventData.categoryObj, type: {label: eventData.type, value: eventData.type},
                    partOf: eventData.isPartOf, 
                    partNumber: eventData.partNumber, episode: eventData.episode
                };
                vidToUpdate.userToken = props.userToken;
                props.updateVideo(vidToUpdate);
            } else {
                if (res.isDenied) {
                    onlineAction("delete", eventData.vid);
                } else if (res.isDismissed) {
                    if ((res.dismiss !== "backdrop")&&(res.dismiss !== "close")) {
                        var stateBasedAction = "Unlist";
                        var currentState = eventData.state;
                        if (currentState === "down") {
                            stateBasedAction = "Go Live";
                        }
                        Swal.fire({
                            icon: "question",
                            title: "Choose further action",
                            showCancelButton: true,
                            // showDenyButton: true,
                            confirmButtonText: stateBasedAction,
                            // denyButtonText: stateBasedAction,
                            cancelButtonText: "Back",
                        }).then(res2=>{
                            if (res2.isConfirmed) {
                                if (currentState === "live") {
                                    onlineAction("takeDown", eventData.vid);
                                } else if (currentState === "down") {
                                    onlineAction("goLive", eventData.vid);
                                }
                            } else {
                                if (res2.isDismissed) {
                                    chooseActions(eventData);
                                }
                            }
                        })
                    }
                }
            }
        })
    }

    document.title = 'Montywood | Content - Dashboard';

    return (
        <Router>
            <div className="contentManager" >
                <div className="lowerCard">
                    <div className="contentActions">
                        <h1>Content</h1>
                        <div className="actionContainer">
                            <NavLink to={"/dashboard/content"} className="organiser" activeClassName={"active"}>
                                <p>Organiser</p>
                            </NavLink>
                            <NavLink to={"/dashboard/content/upload"} className="uploader" activeClassName={"active"}>
                                <p>+</p>
                            </NavLink>
                            <NavLink to={"/dashboard/content/movies"} activeClassName={"active"}>
                                <p>Movies</p>
                            </NavLink>
                            <NavLink to={"/dashboard/content/series"} activeClassName={"active"}>
                                <p>Series</p>
                            </NavLink>
                            <NavLink to={"/dashboard/content/tv-shows"} activeClassName={"active"}>
                                <p>TV-Shows</p>
                            </NavLink>
                            <div className="tabsContainer">
                                <Switch>
                                    <Route exact path="/dashboard/content" component={() => <MainContent userToken={props.userToken} addCategory={props.addCategory} createMovie={props.createMovie} handleBackgroundUpload={handleBackgroundUpload} />}  />
                                    <Route exact path="/dashboard/content/upload" component={() => <Upload userToken={props.userToken} getMovies={props.uploadContent} />}  />
                                    <Route exact path="/dashboard/content/movies" component={() => <Movies userToken={props.userToken} chooseActions={chooseActions} handleBackgroundUpload={handleBackgroundUpload} />}  />
                                    <Route exact path="/dashboard/content/series" component={() => <Series userToken={props.userToken} chooseActions={chooseActions} handleBackgroundUpload={handleBackgroundUpload} />}  />
                                    <Route exact path="/dashboard/content/tv-shows" component={() => <TvShows userToken={props.userToken} chooseActions={chooseActions} handleBackgroundUpload={handleBackgroundUpload} />}  /> 
                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Router>
    );
}
  
export default Content;