import React from 'react';

import "./index.css";

function EpisodeLoader(props) {
    return (
        <div key={props} className="theEpisodeLoader" >
            <div className="thumbnail">
                <i className="shineElemLoader"></i>
                <div></div>
            </div>
            <div className="moreInfo">
                <div>
                    <p className="title">
                        <i className="shineElemLoader"></i>
                    </p><br/>
                    <p className="description">
                        <i className="shineElemLoader"></i>
                    </p>
                </div>
                <div>
                    <p className="duration">
                        <i className="shineElemLoader"></i>
                    </p>
                    <p className="size">
                        <i className="shineElemLoader"></i>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default EpisodeLoader;