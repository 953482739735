import React, {Component} from 'react';
import axios from 'axios';
import Swal from "sweetalert2";  

import Backdrop from '../../../../Backdrop';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
// import { ThumbUpTwoTone } from '@material-ui/icons';

// import {DropzoneArea} from 'material-ui-dropzone';


import './index.css';

class UpdateVideo extends Component {
    constructor(props){
        super(props);

        this.state = {
            type: props.dynamicProps.type.value,
            category: props.dynamicProps.category,
            categories: [{abel: "No categories loaded", value: ""}],
            seriesNames: [],
            partLabel: {label: "Part/Season Number", label2: "Part/Season"},
            partNumber: props.dynamicProps.partNumber,
            episode: props.dynamicProps.episode,
            isPartOfVal: "",
            contentProduction: props.dynamicProps.onMovie
        };
    }

    componentDidMount(){
        console.log(this.props.dynamicProps);
        console.log(this.props.dynamicProps.category);
        var seriesLabel = "Corresponding Production Title";
        var partLabel = this.state.partLabel;
        var checkIsPartOf = "";

        var partNumberUpdate = document.getElementById("partNumberUpdate");
        var episodeNumberUpdate = document.getElementById("episodeNumberUpdate");
        var isPartOf = document.getElementById("isPartOf");
        var isPartOfField = document.getElementById("isPartOfField");
        var partNumberFieldUpdate = document.getElementById("partNumberFieldUpdate");
        var episodeNumberFieldUpdate = document.getElementById("episodeNumberFieldUpdate");

        if (this.props.dynamicProps.type) {
            if (this.props.dynamicProps.type.value === "movie") {
                seriesLabel = "Corresponding Movie Title";
                isPartOf.style.display = "inline-block";
                // partNumberUpdate.style.display = "inline-block";
            } else {
                partNumberUpdate.style.display = "inline-block";
                episodeNumberUpdate.style.display = "inline-block";
                if (this.props.dynamicProps.type.value === "series") {
                    seriesLabel = "Corresponding Series";
                } else {
                    seriesLabel = "Corresponding TV Show";
                }
            };
        };
        if (this.props.dynamicProps.partOf) {
            if (this.props.dynamicProps.partOf === "yes") {
                checkIsPartOf = "yes";
            } else {
                checkIsPartOf = "no";
            }
        };

        const handleTypeChange = (e) => {
            var theValue = e.target.value;
            if ((theValue === "series")||(theValue === "tvshow")) {
                partNumberUpdate.style.display = "inline-block";
                isPartOf.style.display = "none";
                if (theValue === "series") {
                    episodeNumberUpdate.style.display = "inline-block";
                    episodeNumberFieldUpdate.setAttribute("required", true);
                    seriesLabel = "Corresponding Series";
                } else {
                    episodeNumberUpdate.style.display = "none";
                    seriesLabel = "Corresponding TV Show";
                };
                partLabel = {label: "Season Number", label2: "Season"};
    
                partNumberFieldUpdate.setAttribute("required", true);
                isPartOfField.setAttribute("required", true);
            } else {
                isPartOf.style.display = "inline-block";
                partNumberUpdate.style.display = "none";
                episodeNumberUpdate.style.display = "none";
            
                partNumberFieldUpdate.removeAttribute("required");
                episodeNumberFieldUpdate.removeAttribute("required");
                isPartOfField.removeAttribute("required");
            };
            this.setState({...this.state, type: theValue, partLabel: partLabel, seriesLabel: seriesLabel});
        };

        const handleHasParts = (e) => {
            var theValue = e.target.value;
            if (theValue === "yes") {
                seriesLabel = "Corresponding Movie Title";
                partLabel = {label: "Part Number", label2: "Part"};
                partNumberUpdate.style.display = "inline-block";
                
                partNumberFieldUpdate.setAttribute("required", true);
            } else {
                partNumberFieldUpdate.removeAttribute("required");

                partNumberUpdate.style.display = "none";
            };
            this.setState({...this.state, isPartOfVal: theValue, partLabel: partLabel, seriesLabel: seriesLabel});
        };

        console.log(this.props.dynamicProps.category);
        this.setState({...this.state, type: this.props.dynamicProps.type,
            seriesLabel: seriesLabel, category: this.props.dynamicProps.category, categories: [this.props.dynamicProps.category],
            isPartOfVal: checkIsPartOf, handleTypeChange: handleTypeChange, handleHasParts: handleHasParts
        });

        this.getCategories();

    }

    getCategories = ()=>{
        var headers = {Authorization: "Bearer "+this.props.dynamicProps.userToken};
        axios.get("../../api/dashboard/content?action=getCategories", {headers}).then(response=>{
            console.log(response);
            if (response.data) {
                var respInfo = response.data;
                if (respInfo.success) {
                    if (respInfo.data) {
                        var newCategories = [{value: "", label: "Choose category"}];
                        respInfo.data.forEach(element => {
                            newCategories.push(element);
                        });
                        this.setState({...this.state, categories: newCategories});
                    }
                }
            }
        }).catch(err=>{
            console.log(err);
        });
    }

    searchSeries = (e)=>{
        var theValue = e.target.value;
        var fecthedTitles =[];
        var headers = {Authorization: "Bearer "+this.props.dynamicProps.userToken};
        axios.get("../../api/dashboard/content?action=getTitles&q="+theValue, {headers}).then(response=>{
            console.log(response);
            if (response.data) {
                var respInfo = response.data;
                if (respInfo.success) {
                    if (respInfo.data) {
                        this.setState({...this.state, seriesNames: respInfo.data});
                    } else {
                        this.setState({...this.state, seriesNames: fecthedTitles});
                    }
                } else {
                    this.setState({...this.state, seriesNames: fecthedTitles});
                }
            } else {
                this.setState({...this.state, seriesNames: fecthedTitles});
            };
        }).catch(err=>{
            console.log(err.message);
            this.setState({...this.state, seriesNames: fecthedTitles});
        });
    }
    captureSeries = (event, values) => {
        console.log(values);
        if (values) {
            this.setState({...this.state, contentProduction: {label: values.label, value: values.value}});
        } else {
            this.setState({...this.state, contentProduction: this.props.dynamicProps.onMovie});
        }
    }
    handleCategoryChange = (e)=>{
        var theValue = e.target.value;
        this.setState({...this.state, category: theValue});
    }
    handlePartNumberChange = (e)=>{
        var theValue = e.target.value;
        this.setState({...this.state, partNumber: theValue});
    }
    handleUploadProgress = (elem, e)=>{
        var perecentUploadedObj  = {label: "Preparing upload", value: 0};
        if (e.lengthComputable) {
            var perecentUploaded = Math.floor((e.loaded/e.total)*100);
            perecentUploadedObj.value = perecentUploaded;
            if (perecentUploaded < 100) {
                perecentUploadedObj.label = perecentUploaded+"% Uploaded...";
            } else {
                perecentUploadedObj.label = perecentUploaded+"% Complete, awaiting result.";
            };
        };
        document.getElementById("updateProgressText").innerHTML = perecentUploadedObj.label;
        elem.value = perecentUploadedObj.value;
    }
    makeUpload = (e)=>{
        e.preventDefault();
        e.stopPropagation();
        const thisForm = e.target;

        var theHTML = '<div class="uploadProgressContainer"><span id="updateProgressText"></span><progress id="updateProgressBar" class="updateProgressBar" value="0" max="100"> 0% </progress></div>';
        Swal.fire({
            icon: "info",
            title: "Submitting...",
            html: theHTML,
            allowOutsideClick: false,
            showConfirmButton: false,
        });
        const uploadProgressBar = document.getElementById("updateProgressBar");
        var formData = new FormData(thisForm);
        formData.append("name", this.state.contentProduction.value);
        var headers = { Authorization: "Bearer "+this.props.dynamicProps.userToken, "Content-Type": "multipart/form-data"};
        const config = {
            onUploadProgress: (progressEvent) => { 
               this.handleUploadProgress(uploadProgressBar, progressEvent);
            },
            headers: headers
        };

        axios.post("../../api/dashboard/content?action=update&vid="+this.props.dynamicProps.vid, formData, config).then(response=>{
            console.log(response)
            if (response.data) {
               var respInfo = response.data;
                if (respInfo.success) {
                    Swal.fire({
                        icon: "success",
                        title: respInfo.msg,
                        text: respInfo.msg2,
                        allowOutsideClick: false,
                        confirmButtonColor: "var(--main-gold-color)"
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: respInfo.msg,
                        text: respInfo.msg2,
                        allowOutsideClick: false,
                        confirmButtonColor: "var(--main-dark-color)"
                    });
                };
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Request failed",
                    text: "Reuest failed",
                    allowOutsideClick: false,
                    confirmButtonColor: "var(--main-dark-color)"
                });
            };
        }).catch(error=>{
            Swal.fire({
                icon: "error",
                title: "Update failed",
                text: error.message,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "var(--main-dark-color)"
            }).then(res=>{
                if (res.isConfirmed) {
                    this.makeUpload(e);
                }
            });
        });
    };
    
    render () {
        var {
            type, seriesLabel, handleTypeChange, handleHasParts, seriesNames, partLabel, category, categories,
            contentProduction, partNumber, episode, isPartOfVal
        } = this.state;

        const types = [
            { label: "Choose type", value: "" },
            { label: "Movie", value: "movie" },
            { label: "Series", value: "series" },
            { label: "Tv Show", value: "tvshow" }
        ];
        var isPartOfOps = [{label: "Is is part of movie series", value: ""}, {label: "No", value: "noways"}, {label: "Yes", value: "yes"}];
        var partNumbers = [{label: "Choose below", value: ""},
            {label: "1", value: 1}, {label: "2", value: 2}, {label: "3", value: 3},{label: "4", value: 4},{label: "5", value: 5},
            {label: "6", value: 6},{label: "7", value: 7},{label: "8", value: 8},{label: "9", value: 9},{label: "10", value: 10}
        ];

        if (!partNumber){
            partNumber = "";
        }



        return (
            <>
                {/* <div className="updateFormPopupBackground" onClick={this.props.close}></div> */}
                <Backdrop close={this.props.close} className="updateFormPopupBackground" />
                <div className="updateFormPopup" >
                    <h1>Updating Video</h1>
                    <form onSubmit={this.makeUpload}>
                        <div className="uploadInput theThumbnail">
                           <Button className="materialField" variant="text" component="label">Thumbnail 
                                <input name="thumbnail" type="file" accept="image/*"  />
                            </Button>
                            <div className="uploadPreviewSpace">
    
                            </div>
                        </div>
                        <div className="uploadInput theVideo">
                            <Button className="materialField" variant="text" component="label">Video 
                                <input name="video" type="file" accept="video/*"  />
                            </Button>
                            <div className="uploadPreviewSpace">
    
                            </div>
                        </div>
                        <div className="uploadInput">
                            <TextField className="materialField" name="type" 
                            label="Type" 
                            variant="outlined" 
                            onChange={handleTypeChange}
                            defaultValue={type}
                            select required>
                                {types.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <div className="uploadInput">
                            <TextField className="materialField" name="category" label="Category" 
                            onChange={this.handleCategoryChange}
                            defaultValue={category.value}
                            variant="outlined" select required>
                                {categories.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <div id="isPartOf" className="uploadInput">
                            <TextField id="isPartOfField" className="materialField" name="isPartOf" 
                            label="Is this movies part of many?" variant="outlined" 
                            onChange={handleHasParts}
                            defaultValue={isPartOfVal}
                            select>
                                {isPartOfOps.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <br/>
                        <div id="chooseSeriesUpdate" className="uploadInput">
                            <Autocomplete
                            options={seriesNames}
                            getOptionLabel={(option) => option.label}
                            onChange={this.captureSeries}
                            defaultValue={contentProduction}
                            renderInput={(params) => <TextField {...params} name="name" label={seriesLabel} 
                            onChange={this.searchSeries}
                            variant="outlined" required/>}
                            />
                        </div>
                        <div id="partNumberUpdate" className="uploadInput">
                            <TextField id="partNumberFieldUpdate" className="materialField" name="season" 
                            onChange={this.handlePartNumberChange}
                            defaultValue={partNumber}
                            label={partLabel.label} variant="outlined" select >
                                {partNumbers.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                    {(option.value)?(partLabel.label2+": "+option.value): option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <div id="episodeNumberUpdate" className="uploadInput">
                            <TextField id="episodeNumberFieldUpdate" className="materialField" name="episode" label="Episode number"
                            type="number" variant="outlined"
                            defaultValue={episode}
                            />
                        </div>
                        <div className="uploadInput">
                            <TextField className="materialField" name="title" label="Title for this upload."
                            variant="outlined"
                            defaultValue={this.props.dynamicProps.title}
                            required/>
                        </div>
                        <div className="uploadInput uploadDescription">
                            <TextField className="materialField" name="description" label="Description"
                            variant="outlined" multiline maxRows="5"
                            defaultValue={this.props.dynamicProps.description}
                            required/>
                        </div>
                        <div className="uploadInput uploadSubmit">
                            <Button className="materialButton" variant="contained" type="submit" 
                            startIcon={<CloudUploadIcon />}
                            >Upload</Button>
                        </div>
                    </form>
                </div>
            </>
        );
    }
}
    

  export default UpdateVideo;