import React from 'react';

import Category from "../Category";

function MovieBackground(props) {
    var categories = props.categories;
    var videoInfo = {vid: props.vid, video: props.video, title: props.title, episodeTitle: props.episodeTitle, description: props.description, startTime: props.startTime};

    return (
        <>
            <div className="homeBackground movieBackground" >
                <img className="backgroundImage" src={props.cover} alt={props.title} />
                <div className="categories moviesCategories">
                    {
                        categories.length ?
                        categories.map(elem => {
                            return <Category key={elem.id} name={elem.name} categortyID={elem.id} contentByCategory={props.contentByCategory}
                            />;
                        }) : ""
                    }
                </div>
                <div className="recentMovie">
                    <div className="movieThumb" onClick={()=>{props.justPlay(videoInfo)}}>
                        <div className="thumbnail"><img src={props.thumbnail} alt={props.title} /></div>
                        <h3>{props.title}</h3>
                        <p>{props.category}</p>
                    </div>
                    <div className="movieInfo">
                        <h3>{props.title}</h3>
                        <div className="recentMovieViews">
                            <p>{props.views} <span>Views</span></p>
                        </div>
                        <p>
                            {props.description}
                        </p>
                        <button onClick={()=>{props.justPlay(videoInfo)}}>Play</button>
                    </div>
                </div>
                {props.promptLogin}
            </div>
        </>
    );
  }
  
  export default MovieBackground;