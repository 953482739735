import React from 'react'

import "./index.css";

function Voucher(props) {
    var classString = "voucherCard";
    var stateString = "Unreleased";
    if (props.state === "cancelled") {
        classString += " voucherCardCancelled";
        stateString = "Voucher cancelled";
    } else if (props.state === "expired") {
        classString += " voucherCardExpired";
        stateString = "Voucher expired";
    } else if (props.state === "inProgress") {
        classString += " voucherCardInProgress";
        stateString = "Currently in use";
    } else if (props.state === "complete") {
        classString += " voucherCardDone";
        stateString = "Voucher finished";
    } else if (props.state === "released") {
        classString += " voucherCardReleased";
        stateString = "Voucher released";
    };
    return (
        <div className={classString} onClick={()=>{props.actOnVoucher(props)}}>
            <p className="amount">R{props.amount}</p>
            <p className="description">Voucher: {props.voucherNumber}</p>
            <div className="theDates">
                <p className="expiryDate">Expiry: {props.expiryDate}</p>
                <p className="startingDate">State: {stateString}</p>
            </div>
        </div>
    )
}
export default Voucher;