import React from 'react';
import { Link } from 'react-router-dom';

import userIcon from '../../../icons/montywood1.png';
import passwordIcon from '../../../icons/montywood1.png';
import socialGoogle from '../../../icons/montywood1.png';
import socialFacebook from '../../../icons/montywood1.png';
import socialApple from '../../../icons/montywood1.png';


function SignIn(props) {
    props.keepHistory(props.stateObj);
    
    return (
        <>
            <h3>Sign in</h3>
            <form id="login" className="loginform" method="POST" onSubmit={props.onsubmit}>
                <input type="hidden" name="type" defaultValue="login" />
                <div className="inputCont">
                    <div>
                        <img src={userIcon} alt="User icon" />
                    </div>
                    <input type="text" name="myUsername" id="username" placeholder="email/ Phone" required />
                </div>
                <div className="inputCont">
                    <div>
                    <img src={passwordIcon} alt="Key icon" />
                    </div>
                    <input type="password" name="myPassword" id="password" placeholder="Password" required />
                </div>
                
                <div className="logwithContainer">
                    <p>Login with: </p>
                    <div className="logWithCenter">
                        <div className="socialIconLogin"><img src={socialGoogle} alt="Google" /></div>
                        <div className="socialIconLogin"><img src={socialFacebook} alt="Facebook" /></div>
                        <div className="socialIconLogin"><img src={socialApple} alt="Facebook"  /></div>
                    </div>
                </div>
                <p><Link to={"/forgotPassword"} >Forgot password?</Link></p>
                <button>Sign in</button>
                <p><Link to={"/signup"} >New to Montywood?  <span> Sign up now.</span></Link></p>
            </form>
        </>
    );
  }
  
  export default SignIn;