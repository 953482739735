import React from 'react';


function SeriesListing(props) {
    const onClickFunc = ()=>{
        if (props.showPreview) {
            return props.showPreview(props);
        } else if (props.justPlay) {
            var videoInfo = {vid: props.vid, thumbnail: props.thumbnail, video: props.video, title: props.title, episodeTitle: props.episodeTitle, description: props.description,
                startTime: props.startTime, isMultiple: props.isMultiple,
                type: props.type, partNumber: props.partNumber, episode: props.episode,
                offline: props.offline, currentOfflineID: props.currentOfflineID,
            };
            return props.justPlay(videoInfo);
        } else {
            return;
        };
    };

    return (
        <div  key={props} className="series" onClick={onClickFunc}>
            <div className="infoDiv">
                <h1>{props.title}</h1>
                <p>{props.description}</p>
            </div>
            <div className="movieThumbnal"><img src={props.thumbnail} alt={props.title} /></div>
            <h4>{props.title}</h4>
            <p>{props.category}</p>
        </div>
    );
}
  
export default SeriesListing;