import idb from "./idb";

var appDatabase = async (theDB, dbTable, version = 1, options={})=>{
    var databaseObject = {
        db: null,
        theDB: theDB,
        dbTable: dbTable,

        init: ()=>{
            if (databaseObject.db) { return Promise.resolve(databaseObject.db); }
            return idb.open(databaseObject.theDB, version, (upgradeDb)=>{
                var myObjectStore;
                if (!upgradeDb.objectStoreNames.contains(databaseObject,dbTable)) {
                    myObjectStore = upgradeDb.createObjectStore(databaseObject.dbTable, { autoIncrement : true, keyPath: 'id' });
                    console.log(options);
                    if (options.indices) {
                        var theIndices = options.indices;
                        if (typeof theIndices === 'object') {
                            for (var key in theIndices) { //{indexName: keyPath}
                                myObjectStore.createIndex(key, theIndices[key][0], theIndices[key][1]);
                            };
                        };
                    }
                } else {
                    myObjectStore = upgradeDb._db;
                };
            }).then((db)=>{
                return databaseObject.db = db;
            });
        },

        appDbMethod: (mode)=>{
            return databaseObject.init().then((db)=>{					
                return db.transaction(databaseObject.dbTable, mode).objectStore(databaseObject.dbTable);
            })
        }
    };
    return databaseObject;
};

export default appDatabase;