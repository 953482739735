
function genWebShare(shTitle, shText, shUrl) { 
    if (!("share" in navigator)) {
        alert('Web Share API not supported by your browser.');
        console.log('Web Share API not supported.'); return;
    } else {
        return navigator.share({title: shTitle, text: shText, url: shUrl }).then((result)=>{ return result;
        }).catch(error => {console.log('Error sharing: ', error); return null;});
    };
}

export default genWebShare;