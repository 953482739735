import React from 'react';
import axios from 'axios';
import Swal from "sweetalert2";  

import Backdrop from '../../../../Backdrop';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';


import './index.css';


function AddCategory(props) {
    var dynamicProps = props.dynamicProps;

    const sendRequest = (e)=>{
        e.preventDefault();
        e.stopPropagation();

        const thisForm = e.target;
        var formData = new FormData(thisForm);
        var requestObject = Object.fromEntries(formData.entries());

        Swal.fire({
            icon: "info",
            title: "Adding category...",
            allowOutsideClick: false,
            showConfirmButton: false,
        });
        var headers = {
            Authorization: "Bearer "+dynamicProps.userToken
        };
        axios.post("../api/dashboard/content?action=createCategory", requestObject, {headers}).then(response=>{
            console.log(response)
            var icon  = "success";
            if (response.data) {
                var respInfo = response.data;
                if (respInfo.success) {
                    Swal.fire({
                        icon: "success",
                        title: respInfo.msg,
                        text: respInfo.msg2,
                        allowOutsideClick: false,
                    });
                } else {
                    console.log(respInfo.msg, respInfo.msg2);
                    Swal.fire({
                        icon: "error",
                        title: respInfo.msg,
                        text: respInfo.msg2,
                        allowOutsideClick: false,
                    });
                };
            } else {
                Swal.fire({
                    icon: icon,
                    title: "Request failed",
                    text: "Request failed",
                    allowOutsideClick: false,
                });
            };
        }).catch(error=>{
            Swal.close();
            console.log(error.message);
        });
    }

    return (
        <>
            {/* <div className="addCategoryBackdrop" onClick={props.close}></div> */}
            <Backdrop close={props.close} className="addCategoryBackdrop" />
            <div className="addCategoryPopup">
                <h1>Create a Content Category </h1>
                <form onSubmit={sendRequest}>
                    <div>
                        <TextField className="materialField" name="name" label="Type the Category" variant="outlined"
                        required />
                    </div>
                    <br/>
                    <br/>
                    <div className="descriptionSection">
                        <TextField className="materialField" style={{width: "100%"}} name="description" label="Description" variant="outlined" multiline maxRows="5" required/>
                    </div>
                    <br/><br/>
                    <br/><br/>
                    <Button className="submitBtn" variant="contained" type="submit">Create</Button>
                </form>
            </div>
        </>
    );
  }
  
  export default AddCategory;