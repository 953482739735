import React, {useState, useEffect, useCallback} from 'react';
import { Link, Route, Switch, Redirect, useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';

import usePrepareLink from "./hooks/router/usePrepareLink";
import GetParameterPopups from "./components/PopupRoutes/GetParameterPopups";
import { GET_PARAMS, GET_ENUMS } from "./components/const/router";

import SplashScreen from './components/SplashScreen';
import Header from './components/Header';
import Footer from './components/Footer';

import AuthenticationRoute from './components/AuthRoute/Authentication';
import SubscriptionRoute from './components/AuthRoute/Subscriptions';

import Home from './components/Home';
import Movies from './components/Movies';
import Series from './components/Series';
import TvShows from './components/TvShows';
import WatchLater from './components/WatchLater';
import Notifications from './components/Notifications';
import User from './components/User';
import Dashboard from './pages/Dashboard';
import Watch from './components/Watch';
import Login from './components/Login';
import Subscription from './components/Subscription';


function App() {
    const history = useHistory();
    const location = useLocation();

    let promptLogin = null;
    var accessToken = null;
    var initialAuthedCheck = false;
    var initialSubscriptionCheck = false;
    if (localStorage.getItem("userToken")) {
        accessToken = localStorage.getItem("userToken");
        if (accessToken !== "") {
            initialAuthedCheck = true;
        }
    };
    if (localStorage.getItem("isSubscribed")) {
        if (localStorage.getItem("isSubscribed") === 'true') {
            initialSubscriptionCheck = true;
        }
    };

    var [isAuthorized, setIsAuthorized] = useState(initialAuthedCheck);
    var [userToken, setToken] = useState(accessToken);
    var [isSubscribed, setIsSubscribed] = useState(initialSubscriptionCheck);
    var [videoData, setVideoData] = useState({});
    var [pageFrom, setPageFrom] = useState("");
    var [paramPopupData, setParamPopupData] = useState({userToken: accessToken});

    useEffect(() => {
        var eligiblePages  = ["", "movies", "series", "tv-shows", "watch-later"];
        var lastScrollBodyPos = 0;
        var elemCss = 0, elemCssStarted = null;

        var pageFromPath = history.location.pathname;
        pageFromPath = pageFromPath.slice(1, pageFromPath.length);
        if (eligiblePages.includes(pageFromPath)) {
            elemCss = $('.moviesContainer').css("margin-top");
            elemCss = elemCss.substring(0, elemCss.length-2);
            elemCssStarted = true;
        };

        function headerBackground(theElem, lastScrollBodyPos) {
            if (theElem.scrollTop > 100) {
                $("#header").addClass("headerWhenScrolled");
            } else if (theElem.scrollTop < 100) {
                $("#header").removeClass("headerWhenScrolled");
            };
            lastScrollBodyPos = theElem.scrollTop;
        }
        function parralaxHome(theElem, elemCssStarted, elemCss) {
            if (!elemCssStarted) {
                if (eligiblePages.includes(pageFromPath)) {
            //         elemCss = $('.moviesContainer').css("margin-top");
            //         elemCss = elemCss.substring(0, elemCss.length-2);
            //         elemCssStarted = true;
                };
            };
            var scrolled = theElem.scrollTop;
            if (scrolled < 180) {
            //     $('.moviesContainer').css('margin-top', (elemCss-(scrolled*1.05))+'px');
            //     $('.homeBackground').css('margin-top', (0-(scrolled*0.01))+'%');
            }
        };

        $("#root").scroll(function(e) {
            headerBackground(e.target, lastScrollBodyPos);
            parralaxHome(e.target, elemCssStarted, elemCss);
        });
    }, [history.location.pathname]);
    useEffect(()=>{
        const checkSubscription = ()=>{
            var headers = {
                Authorization: "Bearer "+userToken
            };
            return axios.get("api/subscription?action=check", {headers}).then(response=>{
                console.log(response);
                if (response.data) {
                    var respInfo = response.data;
                    if (respInfo.success) {
                        localStorage.setItem("isSubscribed", true);
                        setIsSubscribed(true);
                    } else {
                        localStorage.setItem("isSubscribed", false);
                        setIsSubscribed(false);
                    };
                }
            }).catch(error=>{
                setIsSubscribed(false);
                console.log(error.message);
            });
        }

        if (initialAuthedCheck) {
            checkSubscription();
        };
    }, [ userToken, initialAuthedCheck]);
    

    const updateProfileLink = usePrepareLink({ query: { [GET_PARAMS.popup]: GET_ENUMS.popup.updateProfile}});
    const showUpdateProfileLink = useCallback(() => { history.push(updateProfileLink);}, [history, updateProfileLink]);
    const showUpdateProfile = (currentProfile)=>{ 
        setParamPopupData(currentProfile);
        showUpdateProfileLink();
    }


    const previewTrigger = (videoProps)=>{
        var videoInfo = {
            vid: videoProps.vid, thumbnail: videoProps.thumbnail, video: videoProps.video, title: videoProps.title, startTime: videoProps.startTime, description: videoProps.description,
            isMultiple: videoProps.isMultiple
        };
        
        if (videoProps.type === "movie") {
            justPlay(videoInfo);
        } else {
            videoInfo.justPlay = justPlay;
            videoInfo.userToken = userToken;
            setParamPopupData(videoInfo);

            var splitTime = 1000;
            $('#root').css('animation', 'beforePreview '+splitTime+'ms');
            setTimeout(() => {
                var previewLink = {hash: undefined, pathname: "/", search: "?popup=preview&cid="+videoInfo.vid, state: {}};
                history.push(previewLink);
            }, splitTime-200);
        }
    }

    const closeVideo = ()=>{ history.goBack() };
    const justPlay = (videoInfo) => {
        var pageFromPath = history.location.pathname;
        pageFromPath = pageFromPath.slice(1, pageFromPath.length);
        setPageFrom(pageFromPath);
        setVideoData(videoInfo);

        var splitTime = 1000;
        // $('#root').css('animation', 'beforePreview '+splitTime+'ms');
        setTimeout(() => {
            history.push("/watch?vid="+videoInfo.vid);
            // $('#root').css('animation', 'afterPreview '+splitTime+'ms');
        }, splitTime);
        
    };

    if (!isAuthorized) {
        promptLogin = <Link className="nudgeLogin" to={"/login"}><p>Get started &#10140;</p></Link>;
    };

    return (
        <>
            <SplashScreen />
            <Header isAuthed={isAuthorized} userToken={userToken} showPreview={previewTrigger} justPlay={justPlay} />
            <GetParameterPopups isAuthed={isAuthorized} isSubscribed={isSubscribed} dynamicProps={paramPopupData} />
            <Switch>
                <Route exact path="/" component={() => <Home isAuthed={isAuthorized} userToken={userToken} promptLogin={promptLogin} showPreview={previewTrigger} justPlay={justPlay} location={location} />} />
                <Route exact path="/movies" component={() => <Movies isAuthed={isAuthorized} userToken={userToken} promptLogin={promptLogin} showPreview={previewTrigger} justPlay={justPlay} location={location} />}  />
                <Route exact path="/series" component={() => <Series isAuthed={isAuthorized} userToken={userToken} promptLogin={promptLogin} showPreview={previewTrigger} justPlay={justPlay} location={location} />} />
                <Route exact path="/tv-shows" component={() => <TvShows isAuthed={isAuthorized} userToken={userToken} promptLogin={promptLogin} showPreview={previewTrigger} justPlay={justPlay} location={location} />} />
                <AuthenticationRoute exact path="/watch-later" isAuthorized={isAuthorized}>
                    <WatchLater userToken={userToken} isAuthed={isAuthorized} showPreview={previewTrigger} justPlay={justPlay} promptLogin={promptLogin} location={location}  />
                </AuthenticationRoute>
                <AuthenticationRoute  exact path="/notifications" isAuthorized={isAuthorized}>
                    <Notifications userToken={userToken} isAuthed={isAuthorized}  />
                </AuthenticationRoute>
                <AuthenticationRoute exact path="/user" isAuthorized={isAuthorized} >
                    <User userToken={userToken} setToken={setToken} setIsAuthorized={setIsAuthorized} setIsSubscribed={setIsSubscribed} showUpdateProfile={showUpdateProfile} />
                </AuthenticationRoute>
                <AuthenticationRoute exact path="/membership" isAuthorized={isAuthorized}>
                    <Subscription userToken={userToken} isAuthorized={isAuthorized} setIsSubscribed={setIsSubscribed} />
                </AuthenticationRoute>
                <AuthenticationRoute path="/dashboard" isAuthorized={isAuthorized}>
                    <Dashboard userToken={userToken} isAuthorized={isAuthorized} setParamPopupData={setParamPopupData} showPreview={previewTrigger} justPlay={justPlay} />
                </AuthenticationRoute>
                <SubscriptionRoute path="/watch" isSubscribed={isSubscribed} >
                    <Watch userToken={userToken} close={closeVideo} video={videoData} pageFrom={pageFrom} />
                </SubscriptionRoute>
                
                <Route exact path="/login">
                    <Login
                        setToken={setToken}
                        isAuthorized={isAuthorized}
                        setIsAuthorized={setIsAuthorized}
                        setIsSubscribed={setIsSubscribed}
                    />
                </Route>
                <Route exact path="/signup">
                    <Login
                        setToken={setToken}
                        isAuthorized={isAuthorized}
                        setIsAuthorized={setIsAuthorized}
                        setIsSubscribed={setIsSubscribed}
                    />
                </Route>
                <Route exact path="/forgotPassword">
                    <Login
                        setToken={setToken}
                        isAuthorized={isAuthorized}
                        setIsAuthorized={setIsAuthorized}
                        setIsSubscribed={setIsSubscribed}
                    />
                </Route>
                <Route exact path="/resetPassword">
                    <Login
                        setToken={setToken}
                        isAuthorized={isAuthorized}
                        setIsAuthorized={setIsAuthorized}
                        setIsSubscribed={setIsSubscribed}
                    />
                </Route>
                <Route exact path="/otp">
                    <Login
                        setToken={setToken}
                        isAuthorized={isAuthorized}
                        setIsAuthorized={setIsAuthorized}
                        setIsSubscribed={setIsSubscribed}
                    />
                </Route>
                <Route exact path="/dashboard/users">
                    <Dashboard userToken={userToken}  />
                </Route>
                <Route exact path="/dashboard/content">
                    <Dashboard userToken={userToken}  />
                </Route>
                <Route exact path="/dashboard/vouchers">
                    <Dashboard userToken={userToken}  />
                </Route>
                <Route exact path="/dashboard/subscriptions">
                    <Dashboard userToken={userToken}  />
                </Route>
                <Redirect to="/" />
            </Switch>
            <Footer />
        </>
    );
}
export default App;